import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import MediasService from '../../../../services/MediasService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * CreateMediaGroupModal Component
 *
 * This component displays a modal for creating a new media group.
 * It takes `open`, `onClose`, and `onCreate` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - onCreate: Function to trigger a refresh or update after creation.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const CreateMediaGroupModal = ({ open, onClose, onCreate }) => {
    // State to handle loading state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // State to handle the group name
    const [groupName, setGroupName] = useState('');

    /**
     * Check if the group can be created
     * 
     * @returns {void}
     */
    const checkIfGroupDoesNotExist = async () => {
        if (!groupName || groupName.trim() === '') {
            // Return false immediately if groupName is empty or just spaces
            setLoading(false);
            return false;
        }

        try {
            // Get the list of existing groups
            const existingGroups = await MediasService.getMediaGroups();

            // Check if the group name already exists in the list of existing groups
            const isGroupNameUnique = existingGroups.every(group => group.name !== groupName.trim());

            // Return the result based on uniqueness
            return isGroupNameUnique;
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
            return false; // Return false in case of error
        }
    };

    /**
     * Handles the creation confirmation
     * 
     * @returns {void}
     */
    const handleConfirmCreate = async () => {
        if (!groupName.trim()) {
            setError('Le nom du groupe est requis.');
            console.error('Group name is required');
            return;
        }

        // Check if the group name is unique
        const isGroupNameUnique = await checkIfGroupDoesNotExist();

        if (!isGroupNameUnique) {
            setError('Le nom du groupe existe déjà.');
            console.error('Group name already exists');
            return;
        }

        try {
            // Create the group
            await MediasService.createMediaGroup({ name: groupName });

            // Trigger the onCreate callback to refresh data
            onCreate();

            // Close the modal after the operation
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // Fetch media groups and categories on component mount
    useEffect(() => {
        setLoading(false); // Set loading to false as no additional data is required
    }, []);

    // If the modal is not open, return null
    if (!open) return null;

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Créer un nouveau groupe'}
            handleSave={handleConfirmCreate}
            handleSaveLabel={'Créer le groupe'}
        >
            <div className='create-media-group-modal'>
                {error && (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                )}
                <div className="media-group--input">
                    <label>Nom du groupe</label>
                    <input
                        type="text"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        placeholder="Entrez un nom pour le groupe"
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for CreateMediaGroupModal component
CreateMediaGroupModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default CreateMediaGroupModal;
