import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types'; // Import PropTypes for type validation

import { useLocation } from 'react-router-dom';

/**
 * NavigationBar component responsible for rendering a navigation menu
 * with selectable items. It tracks the current route to highlight
 * the correct menu item based on the browser's location.
 * 
 * @param {Array} items - Array of navigation items. Each item contains:
 *   - name: The name of the navigation item.
 *   - icon: An icon component to display.
 *   - action: A function that navigates to a specific route.
 */
const NavigationBar = ({ items }) => {
    // State to keep track of which item is currently selected
    const [selectedIndex, setSelectedIndex] = useState(null);

    // Hook to access the current browser location (URL path)
    const location = useLocation();

    useEffect(() => {
        // Determine the index of the currently active navigation item based on the URL path
        const currentIndex = items.findIndex(item => {
            const navigatePath = getNavigatePath(item.action);

            // Check if the navigation path is the root path
            if (navigatePath === '/') {
                return location.pathname === '/';
            }
            // For other paths, check if the current URL starts with the navigation path
            return navigatePath && location.pathname.startsWith(navigatePath);
        });

        // Update the selected index based on the current route
        setSelectedIndex(currentIndex);
    }, [location.pathname, items]); // Dependency array: runs effect when pathname or items change

    /**
     * Helper function to extract the navigation path from the action function.
     * It attempts to match the path within a `navigate` function call.
     * 
     * @param {Function} action - The action function containing the navigate path.
     * @returns {string|null} - The extracted path or null if not found.
     */
    const getNavigatePath = (action) => {
        const actionStr = action.toString(); // Convert action function to string
        const match = actionStr.match(/navigate\(['"`](.*?)['"`]\)/); // Regex to extract path
        return match ? match[1] : null; // Return extracted path or null
    };

    // Return null if no items are provided to avoid rendering an empty navigation bar
    if (!items || items.length === 0) {
        return null;
    }

    /**
     * Event handler for when a navigation item is clicked.
     * It updates the selected index and triggers the corresponding action.
     * 
     * @param {number} index - The index of the clicked item.
     * @param {Function} action - The function to navigate to the route.
     */
    const handleItemClick = (index, action) => {
        setSelectedIndex(index); // Update the selected item index
        action(); // Trigger the navigation action
    };

    // Render the navigation bar with clickable items
    return (
        <div className="navigation-bar">
            {items.map((item, index) => (
                <div 
                    key={index} 
                    className={`nav-item ${selectedIndex === index ? 'selected' : ''}`} // Apply 'selected' class if item is active
                    onClick={() => handleItemClick(index, item.action)} // Handle item click
                    onTouchStart={() => handleItemClick(index, item.action)} // Add touch event for mobile
                    role="button" // Indicate that this div acts as a button
                    tabIndex={0} // Make the div focusable with keyboard
                    onKeyDown={(e) => {
                        // Trigger action on Enter or Space key press
                        if (e.key === 'Enter' || e.key === ' ') {
                            handleItemClick(index, item.action);
                        }
                    }}
                >
                    {/* Display the item's icon and label */}
                    <div className="nav-icon">{item.icon}</div> {/* Render icon */}
                    <div className="nav-label">{item.name}</div> {/* Render label */}
                </div>
            ))}
        </div>
    );
};

// Define PropTypes for the NavigationBar component to validate its props
NavigationBar.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired, // Name is a required string
            icon: PropTypes.element.isRequired, // Icon is a required React element
            action: PropTypes.func.isRequired, // Action is a required function
        })
    ).isRequired, // Validate that items is a required array of objects
};

export default NavigationBar;