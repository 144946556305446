// videoModule/hooks/useVideoRecorder.jsx

import { VideoRecorder } from '@capacitor-community/video-recorder';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Toast } from '@capacitor/toast';
import { useIsApp } from './useIsApp';
import CONFIG from '../config';

/**
 * Custom hook for managing video recording functionalities such as starting,
 * stopping the recording, and saving the video file.
 */
export const useVideoRecorder = () => {
    // Determine if the device is mobile using the custom hook
    const { checkIfApp } = useIsApp();

    /**
     * Check if the app is running natively.
     * @returns {Promise<boolean>} - Returns true if the app is native.
     */
    const isAppNative = async () => await checkIfApp();

    /**
     * Function to initialize the video recorder.
     */
    const initializeVideoRecorder = async () => {
        try {
            const config = {
                camera: 0, // FRONT camera
                previewFrames: [{
                    id: 'video-record',
                    stackPosition: 'back', // Position the camera feed behind the app UI
                    width: 'fill',
                    height: 'fill',
                    x: 0,
                    y: 0,
                    borderRadius: 0,
                }]
            };

            // Initialize the video recorder with the specified config
            await VideoRecorder.initialize(config);
            console.log('Video recorder initialized.');
        } catch (error) {
            console.error('Error initializing video recorder:', error);
            await Toast.show({ text: 'Error initializing video recorder', duration: 'short' });
        }
    };

    /**
     * Function to start recording video.
     * @returns {Promise<void>} - Resolves when recording starts.
     */
    const startRecording = async () => {
        try {
            await VideoRecorder.startRecording();
            console.log('Video recording started.');
        } catch (error) {
            console.error('Error starting video recording:', error);
            await Toast.show({ text: 'Error starting video recording', duration: 'short' });
        }
    };

    /**
     * Function to stop the recording and get the video file URL.
     * @returns {Promise<string>} - Resolves with the video URL (file path).
     */
    const stopRecording = async () => {
        try {
            const result = await VideoRecorder.stopRecording();
            console.log('Video recording stopped.');
            return result.videoUrl; // Returns the file path of the video
        } catch (error) {
            console.error('Error stopping video recording:', error);
            await Toast.show({ text: 'Error stopping video recording', duration: 'short' });
            return null;
        }
    };

    /**
     * Function to save a video file to the device's filesystem.
     * @param {string} videoUrl - The file path URL of the recorded video.
     */
    const saveVideo = async (videoUrl) => {
        if (!await isAppNative()) {
            await Toast.show({ text: 'Saving function is not available in the browser', duration: 'short' });
            return;
        }

        try {
            const videoName = `${CONFIG.VIDEO_NAME_PREFIX}${Date.now()}.mp4`; // Generate a unique video name
            const path = `${CONFIG.FOLDER_NAME}/${videoName}`;

            // Check if the directory exists, if not, create it
            await Filesystem.mkdir({
                path: CONFIG.FOLDER_NAME,
                directory: Directory.External,
                recursive: true,
            }).catch((error) => {
                if (error.message !== 'Directory already exists') {
                    console.error('Error creating directory:', error);
                }
            });

            // Move the recorded video to the specified directory
            await Filesystem.writeFile({
                path: path,
                data: videoUrl,
                directory: Directory.External,
                encoding: Encoding.UTF8, // Assuming the video URL is a string representing the file path
            });

            await Toast.show({ text: 'Video saved successfully!', duration: 'long' });
        } catch (error) {
            console.error('Error saving video:', error);
            await Toast.show({ text: 'Error saving video', duration: 'short' });
        }
    };

    /**
     * Function to destroy the video recorder and release resources.
     */
    const destroyRecorder = async () => {
        try {
            await VideoRecorder.destroy();
            console.log('Video recorder destroyed.');
        } catch (error) {
            console.error('Error destroying video recorder:', error);
            await Toast.show({ text: 'Error destroying video recorder', duration: 'short' });
        }
    };

    // Return the functions for use in components
    return { initializeVideoRecorder, startRecording, stopRecording, saveVideo, destroyRecorder };
};
