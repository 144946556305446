import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MediasService from '../../../../services/MediasService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * DeleteMediaCategoryModal Component
 *
 * This component displays a modal for confirming the deletion of a media category.
 * It takes `open`, `onClose`, `categoryId`, and `onDelete` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose (function): Function to close the modal.
 * - categoryId (number): The ID of the category to delete.
 * - onDelete (function): Function to delete the category.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const DeleteMediaCategoryModal = ({ open, onClose, categoryId, onDelete }) => {
    // State to handle error messages
    const [error, setError] = useState(null);

    /**
     * Handles the deletion confirmation
     *
     * @returns {void}
     */
    const handleConfirmDelete = async () => {
        if (categoryId) {
            try {
                // Delete the category
                await MediasService.deleteMediaCategory(categoryId);

                // Trigger the onDelete callback
                onDelete();

                // Close the modal after the operation
                onClose();
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        }
    };

    // Return null if the modal is not open or if there is no category ID
    if (!open || !categoryId) return null;

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Confirmer la suppression'}
            handleSave={handleConfirmDelete}
            handleSaveLabel="Confirmer la suppression"
            onCloseLabel={'Annuler'}
        >
            {error ? (
                <InfoBox type="error" text={error} disabledCloseBtn={true} />
            ) : (
                <InfoBox
                    type="warning"
                    text={
                        <p>Êtes-vous sûr de vouloir supprimer cette catégorie ? Cette action est irréversible.</p>
                    }
                    disabledCloseBtn={true}
                />
            )}
        </CustomModal>
    );
};

// Prop types for the DeleteMediaCategoryModal component
DeleteMediaCategoryModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    categoryId: PropTypes.oneOfType([ 
        PropTypes.string,
        PropTypes.number
    ]),
    onDelete: PropTypes.func.isRequired,
};

export default DeleteMediaCategoryModal;