import ApiService from './ApiService';
import CONFIG from '../config/config';

class UpdateService extends ApiService {

    /**
     * Check the application version against the server.
     * @returns {Promise<string>} A promise that resolves to 'Ok' if the version is up-to-date,
     * or a link to download the update if a new version is available.
     * @throws {Error} Throws an error if the request fails.
     */
    async checkRequiredUpdate() {
        // Get the current version of the application
        const currentVersion = CONFIG.APP_VERSION;

        // Validate the current version before proceeding
        if (!currentVersion) {
            throw new Error('Impossible de récupérer la version actuelle de l\'application');
        }

        try {
            // Make a request to the server to check the application version
            const response = await this.request('GET', `/apk-version/latest`);

            // Validate the response before proceeding
            if (!response) {
                throw new Error('Invalid response from server');
            }

            // Validate the response before proceeding
            if (response.latest === currentVersion) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            throw new Error (error?.message);
        }
    }

    /**
     * Get the latest APK version from the server.
     * @returns {Promise<string>} A promise that resolves to the latest APK version number.
     * @throws {Error} Throws an error if the request fails.
     */
    async getLatestApkVersion() {
        try {
            // Make a request to the server to check the application version
            const response = await this.request('GET', `/apk-version/latest`);

            // Validate the response before proceeding
            if (!response || !response.latest) {
                throw new Error('Une erreur est survenue lors de la récupération de la version de l\'application');
            }

            // Return the latest version
            return response.latest;
        } catch (error) {
            throw new Error (error?.message);
        }
    }

    /**
     * Download the latest APK from the server.
     * @returns {Promise<Blob>} A promise that resolves to the downloaded APK file.
     * @throws {Error} Throws an error if the request fails.
     */
    async downloadLatestApk() {
        try {
            // Get the latest APK version from the server
            const $version = await this.getLatestApkVersion();
            
            // Validate the version before proceeding
            if (!$version) {
                throw new Error('Une erreur est survenue lors de la récupération de la version de l\'application');
            }

            return `${CONFIG.BASE_URL}/apk-version/download?version=${$version}`;
        } catch (error) {
            throw new Error (error?.message);
        }
    }

}

export default new UpdateService();