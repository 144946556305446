// src/context/folder-context.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type validation
import { Preferences } from '@capacitor/preferences'; // Importing Preferences from Capacitor

// Create the context for the folder settings
const FolderContext = createContext();

/**
 * FolderProvider component: Provides the folder state to its children.
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * 
 * This provider manages user preferences related to folder settings,
 * including a title and a color for the folder. It uses Capacitor Preferences 
 * for persistent storage and local storage as a fallback.
 */
export const FolderProvider = ({ children }) => {
    // State variables for title and color
    const [title, setTitle] = useState(() => {
        // Retrieve stored title from localStorage, default to ""
        return localStorage.getItem('folderTitle') || '';
    });

    const [color, setColor] = useState(() => {
        // Retrieve stored color from localStorage, default to "transparent"
        return localStorage.getItem('folderColor') || 'transparent';
    });

    /**
     * Function to apply stored preferences to the state on mount.
     * This function retrieves the stored preferences from Capacitor and updates the state.
     * @returns {void}
     */
    const applyPreferences = async () => {
        try {
            // Fetch the preferences from Capacitor Preferences
            const storedTitle = await Preferences.get({ key: 'folderTitle' });
            const storedColor = await Preferences.get({ key: 'folderColor' });

            // Set the state based on the fetched values, with fallbacks
            setTitle(storedTitle.value || '');
            setColor(storedColor.value || 'transparent');
        } catch (error) {
            console.error('Error applying preferences:', error);
        }
    };

    /**
     * Function to save the current preferences to Capacitor and localStorage.
     * This function saves the current state of the preferences to Capacitor and localStorage.
     * @returns {void}
     */
    const savePreferences = async () => {
        try {
            // Save the preferences to Capacitor Preferences
            await Preferences.set({ key: 'folderTitle', value: title });
            await Preferences.set({ key: 'folderColor', value: color });

            // Save to localStorage as a fallback
            localStorage.setItem('folderTitle', title);
            localStorage.setItem('folderColor', color);
        } catch (error) {
            console.error('Error saving preferences:', error);
        }
    };

    // useEffect to apply preferences from Capacitor on mount
    useEffect(() => {
        applyPreferences(); // Call the async function to apply preferences
    }, []); // Run once when the component mounts

    // useEffect to save preferences when title or color changes
    useEffect(() => {
        savePreferences(); // Call the async function to save preferences
    }, [title, color]); // Runs whenever title or color changes

    /**
     * Function to update the folder title.
     * @param {string} newTitle - The new title to set.
     */
    const updateTitle = (newTitle) => setTitle(newTitle);

    /**
     * Function to update the folder color.
     * @param {string} newColor - The new color to set.
     */
    const updateColor = (newColor) => setColor(newColor);

    // Provide the current state and update functions to the context consumers
    return (
        <FolderContext.Provider value={{
            title, // Current folder title
            color, // Current folder color
            updateTitle, // Function to update the title
            updateColor, // Function to update the color
        }}>
            {children} {/* Render child components */}
        </FolderContext.Provider>
    );
};

/**
 * Custom hook to use the FolderContext in other components.
 * This hook provides easy access to the folder state and management functions.
 * 
 * @returns {Object} - Returns the current folder state and functions to manage it.
 * 
 * Usage:
 * const { title, color, updateTitle, updateColor } = useFolder();
 */
export const useFolder = () => useContext(FolderContext);

// Define PropTypes for better validation and debugging during development
FolderProvider.propTypes = {
    children: PropTypes.node.isRequired, // children are required to wrap the provider
};