import React, { useState } from 'react';

import MediaGroupsTable from '../../components/admin-components/medias/admin--medias--groups-manager';
import MediaCategoriesTable from '../../components/admin-components/medias/admin--medias--categories-manager';

/**
 * AdminMediasManager Component
 * This component renders the media groups and media categories tables.
 */
const AdminMediasManager = () => {
    // State to trigger re-fetch in both child components
    const [refreshKey, setRefreshKey] = useState(0);

    /**
     * refreshData function to refresh the data in the child components.
     * This function is passed as a prop to the child components.
     */
    const refreshData = () => {
        setRefreshKey(prevKey => prevKey + 1);  // Change the refreshKey to force re-render
    };

    // Render the media groups and media categories tables
    return (
        <React.Fragment>
            <MediaGroupsTable refreshKey={refreshKey} refreshData={refreshData} />
            <MediaCategoriesTable refreshKey={refreshKey} refreshData={refreshData} />
        </React.Fragment>
    );
};

export default AdminMediasManager;
