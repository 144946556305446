import React, { useEffect, useState } from 'react';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the localization file
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({ file }) => {
    // Load Worker
    const workerUrl = 'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';

    // Initialize the plugins
    const defaultLayout = defaultLayoutPlugin({
        sidebarTabs: () => {
            return [];
        },
    });

    // Convert the base64 string to a Blob
    const base64toBlob = (data) => {
        const prefix = 'data:application/pdf;base64,';
        let base64WithoutPrefix = data;

        if (base64WithoutPrefix.startsWith(prefix)) {
            base64WithoutPrefix = base64WithoutPrefix.slice(prefix.length);
        }

        // Decode the base64 string
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        // Create the Blob object
        return new Blob([out], { type: 'application/pdf' });
    };

    // State to hold the Blob URL
    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        // Only update the URL when the file changes
        if (file) {
            const blob = base64toBlob(file);
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);

            // Clean up the object URL when the component unmounts or file changes
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [file]); // Run effect only when 'file' changes

    // Render the PDF viewer
    return (
        <Worker workerUrl={workerUrl}>
            <Viewer
                fileUrl={pdfUrl}
                localization={fr_FR}
                plugins={[defaultLayout]}
            />
        </Worker>
    );
};

export default PdfViewer;
