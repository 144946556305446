import React from 'react'; // Importer React

import PropTypes from 'prop-types'; // Importer PropTypes pour la validation des propriétés

/**
 * Container component: A wrapper component that provides a styled container
 * for its children.
 * 
 * @param {Object} props - The properties for the component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the container.
 * @param {string} [props.className] - Additional CSS classes to apply to the container (optional).
 * @param {function} [props.onClick] - Optional click event handler for the container.
 * @param {string} [props.title] - Optional title to display above the container content.
 * @param {string} [props.titleBackground] - Background color for the title (optional).
 */
const Container = ({ children, className = '', onClick, title, titleBackground }) => {

    // Render the container component
    return (
        <div
            className={`container ${className}`}
            onClick={onClick} // Apply the onClick handler if defined
        > 
            {/* Render the title if provided */}
            {title && (
                <h2
                    className="container-title"
                    style={{ backgroundColor: titleBackground || 'transparent' }} // Apply background color
                >
                    {title}
                </h2>
            )}
            
            {/* Main container content */}
            <div className="container-content">
                {children} {/* Render the children elements */}
            </div>
        </div>
    );
};

// Validation of the props using PropTypes
Container.propTypes = {
    children: PropTypes.node.isRequired, // Required prop for children nodes
    className: PropTypes.string, // Optional prop for additional class names
    onClick: PropTypes.func, // Optional prop for the click handler
    title: PropTypes.string, // Optional prop for the title
    titleBackground: PropTypes.string, // Optional prop for title background color
};

// Export the Container component as the default export
export default Container;