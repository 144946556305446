import React from 'react';

import UsersTable from '../../components/admin-components/users/admin--users-manager';

/**
 * AdminUsersManager Component
 * 
 * This component displays the user management table in the admin panel.
 */
const AdminUsersManager = () => {

    // Render the user management table
    return (
        <UsersTable />
    );
};

export default AdminUsersManager;
