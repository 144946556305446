import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Hook to access the current location

import PropTypes from 'prop-types';

import {
    Construction
} from '@mui/icons-material';

import TechnicalDetailsModal from './modals/modal--folder--show-technical-bulletin';
import CommonButton from '../../buttons/common-button';
import InfoBox from '../../blocks/messages/info-box';

import FoldersService from '../../../services/FoldersService';

/**
 * ApplianceInfo Component
 * 
 * This component displays detailed information about a appliance.
 * 
 * Props:
 * - showTechnicalFiles (boolean): If true, will display a button to show technical files.
 */
const ApplianceInfo = ({ showTechnicalFiles = false }) => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    // State to hold appliance data and technical data
    const [applianceData, setApplianceData] = useState(null);
    const [technicalData, setTechnicalData] = useState([]);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(false);

    /**
     * Fetches the appliance data by folder number.
     * Sets loading state and handles errors.
     * If showTechnicalFiles is true, fetches technical files as well.
     * 
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                console.error("Folder not found");
                setError("Dossier introuvable");
                return;
            }

            // Fetch appliance data using folderId
            const data = await FoldersService.getApplianceByFolderId(folder.id);

            // If the appliance is not found, throw an error
            setApplianceData(data);

            // Fetch technical files if requested
            // if (showTechnicalFiles) {
            //     const technicalFile = await MediasService.getTechnicalBulletinsByApplianceModel(data.model);
            //     setTechnicalData(technicalFile);
            // }
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };            

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // If loading, display loading message
    if (loading) {
        return (
            <div className="folder-section appliance-info">
                <h2>Appareil</h2>
                Chargement des informations de l'appareil...
            </div>
        );
    }

    // If there is an error, display error message
    if (error) {
        return (
            <div className="folder-section appliance-info">
                <h2>Appareil</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // If there is no data, return null
    return (
        <React.Fragment>
            <div className="folder-section appliance-info">
                <h2>Appareil</h2>

                {/* Display appliance details */}
                <div className="appliance-details">
                    <React.Fragment>
                        <div><strong>Désignation:</strong> {applianceData?.designation || 'N/A'}</div>
                        <div><strong>Marque:</strong> {applianceData?.brand || 'N/A'}</div>
                        <div><strong>Type:</strong> {applianceData?.type || 'N/A'}</div>
                        <div><strong>Modèle:</strong> {applianceData?.model || 'N/A'}</div>
                        <div><strong>Numéro de série:</strong> {applianceData?.serial_number || 'N/A'}</div>
                        {(technicalData && technicalData.length > 0 && showTechnicalFiles) && (
                            <CommonButton
                                label="Voir les bulletins techniques associés"
                                icon={<Construction />}
                                onClick={() => setModal(true)}
                                className="no-side-margin technical-details-button"
                                square={true}
                            />
                        )}
                    </React.Fragment>
                </div>
            </div>

            { /* Display technical details modal if requested */ }
            {(modal && showTechnicalFiles) && (
                <TechnicalDetailsModal
                    open={modal}
                    onClose={() => setModal(false)}
                    technicalData={technicalData}
                />
            )}
        </React.Fragment>
    );
};

ApplianceInfo.propTypes = {
    showTechnicalFiles: PropTypes.bool, // showTechnicalFiles is now optional
};

export default ApplianceInfo;
