import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

import {
    Camera,
    Group,
    CheckCircleOutline,
} from '@mui/icons-material';

import { useApp } from '../../modules/appModule';

/**
 * AdminHome Component
 * This component renders a button that navigates the user to the intervention page when clicked.
 */
const AdminHome = () => {
    const navigate = useNavigate(); // Initialize the navigate hook
    const { version } = useApp();

    /**
     * handleMediasManagerAccess
     * Function to handle button click and navigate to the medias manager page.
     */
    const handleMediasManagerAccess = () => {
        navigate('/admin/medias');
    }

    /**
     * handleAppointmentActionsAccess
     * Function to handle button click and navigate to the appointment actions page.
     */
    const handleAppointmentActionsAccess = () => {
        navigate('/admin/appointment-actions');
    }

    /**
     * handleUsersManagerAccess
     * Function to handle button click and navigate to the users manager page.
     */
    const handleUsersManagerAccess = () => {
        navigate('/admin/users');
    }

    // Render the home page
    return (
        <div className="admin-home-page">

            {/* Admin buttons */}
            <div className="admin-buttons">
                <button
                    className="medias-manager-btn"
                    onClick={handleMediasManagerAccess}
                    aria-label="Gestion des médias"
                >
                    <Camera />
                    Gestion des médias
                </button>
                <button
                    className="appointment-actions-btn"
                    onClick={handleAppointmentActionsAccess}
                    aria-label="Gestion des actions d'intervention"
                >
                    <CheckCircleOutline />
                    Gestion des actions d'intervention
                </button>
                <button
                    className="users-manager-btn"
                    onClick={handleUsersManagerAccess}
                    aria-label="Gestion des utilisateurs"
                >
                    <Group />
                    Gestion des utilisateurs
                </button>
            </div>

            {/* Welcome message */}
            <div className="welcome-message">
                <div className="welcome-message-content">
                    <h1>Bienvenue</h1>
                    <p>Sur l'<strong>interface d'administration</strong> de l'application de gestion des <strong>interventions</strong>.</p>
                    <p><strong>Utilisez les boutons ci-contre</strong> pour accéder aux différentes interfaces d'administration.</p>
                </div>
            </div>

            {/* Application information */}
            <div className="app-info">
                <strong>Informations sur l'application</strong>
                <p>{`Version de l'application: ${version}`}</p>
            </div>
        </div>
    );
};

export default AdminHome;
