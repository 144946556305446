import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Hook to access the current location

import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';

import AppointmentsService from '../../../../services/AppointmentsService';

import CustomModal from '../../../blocks/custom-modal';

import InfoBox from '../../../blocks/messages/info-box';

/**
 * ConfirmationSignaturesModal renders a modal for preview and confirmation.
 * After confirmation, display success message and buttons to leave or view summary.
 * 
 * @param {boolean} open - Determines if the modal is open
 * @param {function} onClose - Handler to close the modal
 * @param {string} finalImage - URL of the image to preview before confirmation
 * @param {function} onConfirm - Handler for confirm action
 * @param {function} onSuccess - Callback function to execute on successful save
 * @param {number} appointmentId - The appointment ID to save
 * @returns {JSX.Element} - Rendered modal component
 */
const ConfirmationSignaturesModal = ({
    open,
    onClose,
    finalImage,
    appointmentId,
    onSuccess,
}) => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate

    // State for confirmation success
    const [confirmationSuccess, setConfirmationSuccess] = useState(false);

    const [error, setError] = useState(null);

    /**
     * Handler for confirm save action.
     * 
     * Calls the service to save the signature and lock the appointment.
     * If successful, sets confirmationSuccess to true.
     * 
     * @returns {Promise<void>} - A promise that resolves when the save operation is complete
     * @throws {Error} - An error if the save operation fails
     */
    const handleConfirmSave = async () => {
        try {
            // Payload to save the signature
            const payload = {
                id_appointment: appointmentId,
                file: finalImage,
            };

            // Call the service to sign and lock the appointment
            await AppointmentsService.signAndLockAppointment(appointmentId, payload);

            // Set confirmation success state
            setConfirmationSuccess(true);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    /**
     * Handler for success close action.
     * 
     * Resets the success state, calls onSuccess, and navigates to the overview page.
     * 
     * @returns {void}
     */
    const handleSuccessClose = () => {
        setConfirmationSuccess(false); // Reset success state
        onSuccess(); // Call onSuccess if any
        navigate(`/folders/${refFolder}/overview/`); // Navigate to overview page
    }

    // Render the ConfirmationSignaturesModal component
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            onCloseLabel={"Retour"}
            title="Prévisualisation et confirmation"
            handleSave={confirmationSuccess ? handleSuccessClose : handleConfirmSave}
            handleSaveLabel={confirmationSuccess ? "Accéder au récapitulatif" : "Signer et enregistrer l'intervention"}
        >
            {/* Content section with success message or confirmation warning */}
            <div className="confirmation-signatures-modal-content">

                {/* Error message for save failure */}
                {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

                {/* Success message for successful save */}
                {confirmationSuccess ? (
                    <InfoBox
                        text={
                            <React.Fragment>
                                <p>Les signatures ont été enregistrées avec succès!</p>
                            </React.Fragment>
                        }
                        type="validation"
                    />
                ) : (
                    <React.Fragment>
                        {/* Warning message for final confirmation */}
                        <InfoBox
                            text={
                                <React.Fragment>
                                    <p>Attention, une fois la signature enregistrée, l'intervention ne pourra plus être modifiée.</p>
                                    <strong>Voulez-vous continuer ?</strong>
                                </React.Fragment>
                            }
                            type="warning"
                        />
                        {/* Preview section with image for final confirmation */}
                        <h2>
                            <SearchIcon />
                            Aperçu avant enregistrement
                        </h2>
                        <div className="confirmation-signatures-modal-preview">
                            <img src={finalImage} alt="Aperçu" className="confirmation-signatures-modal-preview-img" />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </CustomModal>
    );
};

// Prop types for ConfirmationSignaturesModal component
ConfirmationSignaturesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    finalImage: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    appointmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default ConfirmationSignaturesModal;
