import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type validation

/**
 * ToggleButton component: A button that toggles between on and off states.
 * 
 * @param {Object} props - The properties for the component.
 * @param {boolean} props.isActive - The current state of the button (on/off).
 * @param {Function} [props.onToggle] - The function to call when the button is toggled (optional).
 */
const ToggleButton = ({ isActive, onToggle }) => {

    // Render the ToggleButton component
    return (
        <div 
            className={`toggle-button ${isActive ? 'active' : ''}`} 
            {...(onToggle ? { onClick: onToggle } : {})} // Apply onClick only if onToggle is defined
            role="button" // Indicate that this is a button for accessibility
            tabIndex={0} // Make the button focusable
            onKeyDown={(e) => { // Use onKeyDown for keyboard accessibility
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault(); // Prevent default action for space key
                    onToggle(); // Call onToggle when Enter or Space is pressed
                }
            }}
        >
            <div className="toggle-focus"></div> {/* Changed class name */}
        </div>
    );
};

// Define PropTypes for the ToggleButton component
ToggleButton.propTypes = {
    isActive: PropTypes.bool.isRequired, // Validate that isActive is required and is a boolean
    onToggle: PropTypes.func, // Validate that onToggle is a function if provided
};

export default ToggleButton; // Export the ToggleButton component