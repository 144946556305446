import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Hook to access the current location

import FoldersService from '../../../services/FoldersService';

import InfoBox from '../../blocks/messages/info-box';

/**
 * AdditionalInfo Component
 * 
 * This component displays additional information related to the folder.
 */
const AdditionalInfo = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    // State to hold additional information data
    const [data, setData] = useState(null);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Fetches the additional information for the folder.
     * Sets loading state and handles errors.
     * 
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                setError(new Error('Dossier introuvable'));
                return;
            }

            // Set the additional information data
            setData(folder.additional_informations);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };            

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // Display loading message while fetching data
    if (loading) {
        return (
            <div className="folder-section additional-info">
                <h2>Informations complémentaires</h2>
                Chargement...
            </div>
        )
    }

    // Display error message if an error occurred
    if (error) {
        return (
            <div className="folder-section additional-info">
                <h2>Informations complémentaires</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // If there is no data, return null
    return (
        <div className="folder-section additional-info">
            <h2>Informations complémentaires</h2>
            {data || 'N/A'}
        </div>
    );
};

export default AdditionalInfo;
