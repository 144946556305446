// deviceModule/hooks/useDevice.jsx

// Import necessary modules from Capacitor for device functionalities
import { Device } from '@capacitor/device'; // Import the Device API from Capacitor
import { Capacitor } from '@capacitor/core'; // Import Capacitor core for platform checks
import { useEffect, useState } from 'react'; // Import useEffect and useState hooks from React

/**
 * Custom hook for managing device information such as identifier, name,
 * and operating system details.
 */
export const useDevice = () => {
    // State variables to hold the fetched device data
    const [identifier, setIdentifier] = useState(null);          // Holds the device identifier
    const [deviceName, setDeviceName] = useState(null);          // Holds the device name
    const [operatingSystem, setOperatingSystem] = useState(null); // Holds the operating system
    const [loading, setLoading] = useState(true);                // Holds the loading state

    /**
     * useEffect hook to fetch device information when the component mounts.
     * It asynchronously calls the utility functions and stores the results in state variables.
     */
    useEffect(() => {
        const fetchDeviceInfo = async () => {
            try {
                // Fetch device identifier, name, and OS
                const identifierNumber = await showIdentifier();
                const name = await showDeviceName();
                const os = await showDeviceOperatingSystem();

                // Update state with the fetched data
                setIdentifier(identifierNumber);
                setDeviceName(name);
                setOperatingSystem(os);
            } catch (err) {
                console.error(err.message); // Log any errors that occur during the fetch
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        fetchDeviceInfo(); // Call the fetch function
    }, []); // Empty dependency array ensures the effect runs only once when the hook is used

    /**
     * Function to retrieve the identifier number of the device.
     * @returns {Promise<string>} Device identifier or a message if unavailable.
     */
    const showIdentifier = async () => {
        try {
            const deviceInfo = await Device.getId(); // Await the device ID fetch
            return deviceInfo.identifier || 'Identifiant non disponible'; // Return device ID or a default message
        } catch (error) {
            console.error('Error fetching device ID:', error);
            return 'Identifiant non disponible';
        }
        
    };

    /**
     * Function to retrieve the name of the device.
     * @returns {Promise<string>} The name of the device or a message if unavailable.
     */
    const showDeviceName = async () => {
        // Get device information and return the device name
        const deviceInfo = await Device.getInfo(); // Await device info fetch
        return deviceInfo.name || 'Nom non disponible'; // Return device name or a default message
    };

    /**
     * Function to retrieve the operating system of the device.
     * @returns {Promise<string>} The operating system of the device or a message if unavailable.
     */
    const showDeviceOperatingSystem = async () => {
        // Get device information and return the operating system
        const deviceInfo = await Device.getInfo(); // Await device info fetch
        return deviceInfo.operatingSystem || 'Système non disponible'; // Return OS or a default message
    };

    // Return the fetched values and loading state for use in components
    return { identifier, deviceName, operatingSystem, loading };
};
