import React, { useState } from 'react';

import PropTypes from 'prop-types';

import InfoBox from '../../../blocks/messages/info-box';
import CustomModal from '../../../blocks/custom-modal';

import AppointmentsService from '../../../../services/AppointmentsService';

/**
 * DeleteAppointmentModal Component
 *
 * This component displays a modal for confirming the deletion of an appointment.
 * It takes `open`, `onClose`, `appointmentId`, and `onDelete` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - appointmentId (number): The ID of the appointment to delete.
 * - onDelete: Function to delete the appointment.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const DeleteAppointmentModal = ({ open, onClose, appointmentId, onDelete }) => {
    // State to handle error messages
    const [error, setError] = useState(null);

    /**
     * Handles the deletion confirmation
     * 
     * @returns {void}
     */
    const handleConfirmDelete = async () => {
        if (appointmentId) {
            try {
                // Delete the appointment
                await AppointmentsService.deleteAppointmentById(appointmentId);

                // Trigger the onDelete callback
                onDelete();
                onClose();
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        } else {
            console.error("Appointment ID is required");
            setError("Impossible de supprimer l'intervention. Veuillez réessayer");
        }
    };

    // If the modal is not open, return null
    if (!open || !appointmentId) return null;

    // Render the modal
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title="Confirmer la suppression"
            handleSave={handleConfirmDelete}
            handleSaveLabel={'Confirmer la suppression'}
        >
            { /* Display error message if there is an error */ }
            {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

            { /* Display the confirmation message */ }
            <InfoBox
                type="warning"
                text={
                    <React.Fragment>
                        <p>Êtes-vous sûr de vouloir supprimer cette intervention ? Cette action est irréversible.</p>
                    </React.Fragment>
                }
                disabledCloseBtn={true}
            />
        </CustomModal>
    );
};

// Prop types definition
DeleteAppointmentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    appointmentId: PropTypes.oneOfType([ 
        PropTypes.string,
        PropTypes.number
    ]),
    onDelete: PropTypes.func.isRequired,
};

export default DeleteAppointmentModal;
