import React, { useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/DeleteForever';

import SignatureCanvas from 'react-signature-canvas';
import CommonButton from '../../../buttons/common-button';

/**
 * TechnicianSignature component allows technicians to sign on a canvas.
 *
 * This component provides an interface for technicians to create and save their signatures.
 * It utilizes the SignatureCanvas library to render a signature pad and 
 * provides functionality to clear and save the signature.
 *
 * @param {number} index - The index of the technician, used for labeling.
 * @param {string} signature - The current signature data URL in base64 format.
 * @param {function} setSignature - Function to update the signature in the parent component.
 */
const TechnicianSignature = ({ index, signature, setSignature }) => {

    // Reference to the signature canvas
    const signatureRef = useRef(null);

    // Run once on mount to optimize canvas rendering
    useEffect(() => {
        if (signatureRef.current) {
            const canvas = signatureRef.current.getCanvas();
            canvas.getContext("2d", { willReadFrequently: true });
        }
    }, []); // Run once on mount

    /**
     * Clears the signature canvas when invoked.
     * This function resets the signature to its initial empty state.
     */
    const handleClear = () => {
        if (signatureRef.current) {
            signatureRef.current.clear();
        }
    };

    /**
     * Handles the end of signature drawing.
     * Converts the drawn signature to a base64 image and updates the parent component.
     */
    const handleEndSignature = () => {
        const currentSignature = signatureRef.current.getTrimmedCanvas().toDataURL('image/png'); // Convert to base64
        setSignature(currentSignature); // Send base64 to parent component
    };

    // Render the technician signature component
    return (
        <div className="tech-signature--wrapper">
            {/* Technician signature canvas container */}
            <div className="tech-signature--container">

                {/* Technician signature canvas */}
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    minWidth={2}
                    maxWidth={4}
                    canvasProps={{
                        className: 'tech-signature--pad',
                    }}
                    onEnd={handleEndSignature} // Call to save signature in base64
                />

                {/* Technician signature preview */}
                {signature && (
                    <div className="tech-signature--preview">
                        <h5>Aperçu de la signature</h5>
                        <div className="tech-signature--img--container">
                            <img src={signature} alt={`Technicien ${index + 1} Signature`} />
                        </div>
                    </div>
                )}

                {/* Clear signature button */}
                <CommonButton
                    icon={<ClearIcon />}
                    label="Effacer et recommencer"
                    square={true}
                    autoWidth={true}
                    onClick={handleClear}
                    className={'erase-button'}
                />
            </div>
        </div>
    );
};

// Define PropTypes for the TechnicianSignature component
TechnicianSignature.propTypes = {
    index: PropTypes.number.isRequired, // Ensure index is a number and is required
    signature: PropTypes.string, // Signature can be a string or undefined
    setSignature: PropTypes.func.isRequired, // Ensure setSignature is a function and is required
};

export default TechnicianSignature;