import React from 'react';

import NavigationBar from '../components/blocks/navigation-bar'; // Import NavigationBar component
import Header from '../components/blocks/header'; // Import Header component

import { useNavigate, Outlet } from 'react-router-dom'; // Hook for navigation

import {
    HomeOutlined,
    AdminPanelSettings,
    SettingsSuggest,
    CollectionsBookmarkOutlined
} from '@mui/icons-material'; // Import Material UI icons

import { useTitle } from '../context/header-context'; // Import the hook to get the title

/**
 * ClassicLayout Component
 * 
 * This component serves as a layout wrapper for pages with a header, 
 * children content, and a navigation bar.
 *
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be rendered within the layout.
 */
const ClassicLayout = () => {
    const navigate = useNavigate(); // Hook to get the navigate function
    const { title } = useTitle(); // Get the title from the context

    // Navigation items for the navigation bar
    const items = [
        {
            name: 'Accueil', // Navigation item name
            icon: <HomeOutlined />, // Icon for home
            action: () => navigate('/'), // Navigation action for home
        },
        {
            name: 'Galerie', // Navigation item name
            icon: <CollectionsBookmarkOutlined />, // Icon for gallery
            action: () => navigate('/internal-gallery'), // Log for search action
        },
        {
            name: 'Paramètres', // Navigation item name
            icon: <SettingsSuggest />, // Icon for settings
            action: () => navigate('/settings'), // Navigation action for settings
        },
        {
            name: 'Admin', // Navigation item name
            icon: <AdminPanelSettings />, // Icon for settings
            action: () => navigate('/admin'), // Navigation action for settings
        }
    ];

    // Render the ClassicLayout with the header, content, and navigation bar
    return (
        <div className='app-container'>

            {/* Header with title */}
            <Header title={title} /> {/* Pass the title to the Header */} 

            {/* Content with outlet */}
            <div className='app-content' style={{ borderColor: 'var(--pastel-primary-color)' }}>
                <Outlet />
            </div>

            {/* Navigation bar */}
            <NavigationBar items={items} />
        </div>
    );
};

export default ClassicLayout; // Export the ClassicLayout component
