import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Container from '../../components/blocks/container';
import NavigateFooter from '../../components/folder-components/folder--navigate-footer';
import UploadSection from '../../components/folder-components/medias/folder--media--upload-section';
import GallerySection from '../../components/folder-components/medias/folder--media--gallery';

import { useFolder } from '../../context/folder-context';
import FoldersService from '../../services/FoldersService';

/**
 * ImageUploadTool Component
 * This component allows users to upload images and view a gallery for a specific folder.
 * It fetches folder details dynamically using the `refFolder` parameter.
 */
const ImageUploadTool = () => {
    const { refFolder } = useParams(); // Get the folder reference from the route parameters
    const navigate = useNavigate(); // Hook for navigation

    // State to store folder data
    const [folder, setFolder] = useState(null);

    // State to manage loading state
    const [loading, setLoading] = useState(true);

    // State to handle error messages
    const [error, setError] = useState(null);

    // Retrieve folder title and color from context
    const { title, color } = useFolder();

    /**
     * Fetches folder details based on `refFolder`.
     */
    const fetchFolder = async () => {

        // Set loading state to true
        setLoading(true);

        try {
            // Fetch folder details using the `refFolder` parameter
            const fetchedFolder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder does not exist, set an error message
            if (!fetchedFolder) {
                setError('Le dossier demandé n\'existe pas');
                return;
            }

            // Update the folder state with the fetched folder data
            setFolder(fetchedFolder);
        } catch (error) {
            setError(error?.message || 'Une erreur inattendue est survenue');
        } finally {
            setLoading(false);
        }
    };

    // Fetch folder details when `refFolder` changes
    useEffect(() => {
        fetchFolder();
    }, [refFolder]);

    // Handle loading, error, or empty states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!folder) return <div>No folder found</div>;

    // Render the upload and gallery sections with a navigation footer
    return (
        <Container
            className="w-100 folder-container media-upload-tool"
            title={title || ''}
            titleBackground={color || 'transparent'}
        >
            {/* Upload Section: Allows uploading images */}
            <UploadSection folder={folder} />

            {/* Gallery Section: Displays uploaded images */}
            {/* <GallerySection folder={folder} /> */}

            {/* Footer: Navigation controls for back and next actions */}
            <NavigateFooter
                onBack={() => navigate(`/folders/${refFolder}/appointments`)}
                onNext={() => navigate(`/folders/${refFolder}/parts`)}
            />
        </Container>
    );
};

export default ImageUploadTool;
