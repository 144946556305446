import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Hook to access the current location

import FoldersService from '../../services/FoldersService';

import InfoBox from '../blocks/messages/info-box';

/**
 * WarrantyInfo Component
 * 
 * This component displays detailed information about a warranty.
 */
const WarrantyInfo = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    // State to hold warranty data
    const [data, setData] = useState(null);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Fetches the warranty data for the folder.
     * Sets loading state and handles errors.
     * 
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                console.error("Folder not found");
                setError("Dossier introuvable");
                return;
            }

            // Fetch the warranty data using folderId
            const data = await FoldersService.getWarrantyByFolderId(folder.id);

            // Set the warranty data
            setData(data);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    }

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // Display loading message while fetching data
    if (loading) {
        return (
            <div className="folder-section warranty-info">
                <h2>Garantie</h2>
                Chargement...
            </div>
        );
    }

    // Display error message if an error occurred
    if (error) {
        return (
            <div className="folder-section warranty-info">
                <h2>Garantie</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // If there is no data, return null
    return (
        <div className="folder-section warranty-info">
            <h2>Garantie</h2>
            <div className="warranty-details">
                <React.Fragment>
                    <div><strong>Type:</strong> {data?.type || 'N/A'}</div>
                    <div><strong>Date d'achat:</strong> {data?.purchase_date || 'N/A'}</div>
                    <div><strong>Numéro de facture:</strong> {data?.invoice_number || 'N/A'}</div>
                    <div><strong>Payeur:</strong> {data?.payer || 'N/A'}</div>
                </React.Fragment>
            </div>
        </div>
    );
};

export default WarrantyInfo;
