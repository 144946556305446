import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AppointmentsService from '../../../../services/AppointmentsService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * CreateAppointmentActionModal Component
 *
 * This component displays a modal for creating a new appointment action.
 * It takes `open`, `onClose`, and `onCreate` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose (function): Function to close the modal.
 * - onCreate (function): Function to handle the creation logic.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const CreateAppointmentActionModal = ({ open, onClose, onCreate }) => {
    // State to handle loading state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [actionName, setActionName] = useState('');

    /**
     * Handles the creation of a new appointment action
     *
     * @returns {void}
     */
    const handleConfirmCreate = async () => {
        if (!actionName.trim()) {
            console.error('Action name is required');
            setError('Le nom de l\'action est requis.');
            return;
        }

        try {
            // Set loading state to true
            setLoading(true);

            // Create the new appointment action
            await AppointmentsService.createAppointmentAction({ name: actionName });

            // Trigger the onCreate callback to refresh data
            onCreate();

            // Close the modal after creation
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // If the modal is not open, return null
    if (!open) return null;

    // Render the CreateAppointmentActionModal component
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Créer une action de rendez-vous'}
            handleSave={handleConfirmCreate}
            handleSaveLabel={'Créer'}
        >
            {/* Create appointment action form */}
            <div className="create-appointment-action-modal">
                {error && (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                )}

                {/* Action name input */}
                <div className="appointment-action--input">
                    <label>Nom de l'action</label>
                    <input
                        type="text"
                        value={actionName}
                        onChange={(e) => setActionName(e.target.value)}
                        placeholder="Entrez un nom pour l'action"
                        disabled={loading}
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for the CreateAppointmentActionModal component
CreateAppointmentActionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default CreateAppointmentActionModal;
