import React, { useEffect, useState } from 'react';

import Logo from '../assets/images/logo.jpg';

import UpdateService from '../services/UpdateService';

/**
 * UpdatePage Component
 * 
 * This component renders the update page.
 * This page is displayed when a new version of the app is available.
 * The user can click on the button to update the app.
 * 
 * @returns {JSX.Element} The rendered UpdatePage component.
 */
const UpdatePage = () => {
  // Get the update state from the context
  const [error, setError] = useState(null);

  // Get the update state from the context
  const [url, setUrl] = useState(null);

  // Check for updates when the component mounts
  useEffect(() => {
    /**
     * Check for updates and set the download URL if an update is available.
     * If an error occurs, set the error message.
     */
    const checkUpdate = async () => {
      try {
        // Check if the app needs to be updated
        const url = await UpdateService.downloadLatestApk();

        // If the URL is not available, set an error message
        if (!url) {
          setError('Une erreur inattendue est survenue');
          return;
        }

        // Set the download URL
        setUrl(url);
        
      } catch (error) {
        setError(error?.message || 'Une erreur inattendue est survenue');
      }
    }

    checkUpdate();
  }, []);

  // Render the update page
  return (
    <div className="update-page">
      <div className="update-content">
        {/* Displaying the logo */}
        <img src={Logo} alt="Logo" className="logo" />
        
        {/* Title of the page */}
        <h1 className="title">Une nouvelle version est disponible</h1>
        
        {/* Description of the update */}
        <div className="description">
          <div className="step">
            <p className="step-title">1. Télécharger la dernière version</p>
            <p>Cliquez sur le bouton ci-dessous pour télécharger la dernière version de l'application.</p>
          </div>
          <div className="step">
            <p className="step-title">2. Installer le fichier APK</p>
            <p>Une fois le téléchargement terminé, exécuter le fichier APK téléchargé.</p>
          </div>
          <div className="step">
            <p className="step-title">3. Autoriser les applications de sources inconnues</p>
            <p>Autoriser l'installation d'applications provenant de sources inconnues dans les paramètres de votre appareil.</p>
          </div>
        </div>


        {/* Display error message if there is an error */}
        {error && <p className="error-message">{error || 'Une erreur inattendue est survenue'}</p>}
        
        {/* Button to trigger the update */}
        <a className="update-button" href={url}
        >
          Télécharger la dernière version
        </a>
      </div>
    </div>
  );
};

export default UpdatePage;
