import React from 'react'; // React and hooks for component lifecycle management
import { useParams, useNavigate } from 'react-router-dom'; // Hook to access the current location

import { useFolder } from '../../context/folder-context'; // Custom hook to use folder context

import Container from '../../components/blocks/container'; // Container component for layout
import ApplianceInfo from '../../components/folder-components/appliance/folder--appliance-info'; // Appliance information component
import WarrantyInfo from '../../components/folder-components/folder--warranty-info'; // Warranty information component
import DeclaredIssueInfo from '../../components/folder-components/issues/folder--declared-issue-info'; // Declared issue information component
import ObservedIssueInfo from '../../components/folder-components/issues/folder--observed-issue-info'; // Observed issue information component
import AdditionalInfo from '../../components/folder-components/issues/folder--additional-info'; // Additional information component
import NavigateFooter from '../../components/folder-components/folder--navigate-footer';

/**
 * FolderReportingPage Component
 * 
 * This component displays the folder reporting page with all the information related to the folder.
 */
const FolderReportingPage = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate

    const { title, color } = useFolder(); // Get the title and color from the folder context

    // Render the folder reporting page with fetched data
    return (
        <Container
            className="folder-container"
            title={title || ''}
            titleBackground={color || 'transparent'}
        >
            {/* Appliance Information Section */}
            <ApplianceInfo showTechnicalFiles={true}/>

            {/* Warranty Information Section */}
            <WarrantyInfo />

            {/* Declared and Observed Issues Section */}
            <DeclaredIssueInfo />

            {/* Observed Issue Information Section */}
            <ObservedIssueInfo />

            {/* Additional Information Section */}
            <AdditionalInfo />

            {/* Navigate Footer */}
            <NavigateFooter
                onNext={() => navigate(`/folders/${refFolder}/appointments`)}
            />
        </Container>
    );
};

export default FolderReportingPage; // Export the component for use in other parts of the application