import React from 'react';

import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';

/**
 * MediaUploadPreview Component
 * 
 * This component displays a preview of the uploaded media file.
 * 
 * @param {object} base64file - The base64 representation of the uploaded file
 * @param {boolean} loading - Whether the media is loading or not
 * 
 * @returns {JSX.Element}
 */
const MediaUploadPreview = ({
    base64file,
    loading
}) => {

    // Return the JSX for the MediaUploadPreview component
    return (
        <div className='preview-wrapper'>

            { /* Display the image preview if a file is uploaded */ }
            {base64file && (
                <React.Fragment>
                    <h3>Image capturée</h3>
                    <img 
                        src={base64file} 
                        alt="Captured" 
                        className="preview-image" 
                        aria-label="Preview de l'image capturée"
                        role="img"
                    />
                </React.Fragment>
            )}

            { /* Display a loading spinner if mediaLoading is true */ }
            {loading && (
                <div className="loading-wrapper">
                    <CircularProgress />
                    <span>Traitement de l'image en cours</span>
                </div>
            )}
        </div>
    );
};

// Prop types for the MediaUploadPreview component
MediaUploadPreview.propTypes = {
    base64file: PropTypes.string,
    loading: PropTypes.bool,
};

export default MediaUploadPreview;
