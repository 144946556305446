import React, { createContext, useContext, useState } from 'react';

// Create a context to manage the header title
const HeaderContext = createContext();

/**
 * HeaderProvider Component
 * 
 * This component provides the context for managing the application title.
 * It uses local state to store the title and makes it available
 * to child components via the HeaderContext.
 *
 * @param {Object} props - The component's properties.
 * @param {ReactNode} props.children - The child components to render.
 */
export const HeaderProvider = ({ children }) => {
    // State for the title, initialized to an empty string
    const [title, setTitle] = useState(''); 

    return (
        // Provide the title and the setTitle function to child components
        <HeaderContext.Provider value={{ title, setTitle }}>
            {children}
        </HeaderContext.Provider>
    );
};

/**
 * useTitle Hook
 * 
 * This hook allows access to the title context from any child component
 * of the HeaderProvider.
 *
 * @returns {Object} - Contains the title and the setTitle function.
 */
export const useTitle = () => {
    // Use useContext to get the values from the HeaderContext
    return useContext(HeaderContext);
};