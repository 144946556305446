// cameraModule/hooks/useCameraPhoto.jsx

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Toast } from '@capacitor/toast';
import { useIsApp } from './useIsApp';
import { useImageProcessing } from './useImageProcessing';

import CONFIG from '../config';

/**
 * Custom hook for managing camera operations such as taking a photo, selecting a photo,
 * and saving a photo to the filesystem.
 */
export const useCameraPhoto = () => {
    // Determine if the device is mobile using the custom hook
    const { checkIfApp } = useIsApp();
    const { compressImage } = useImageProcessing();

    /**
     * Check if the app is running natively.
     * @returns {Promise<boolean>} - Returns true if the app is native.
     */
    const isAppNative = async () => await checkIfApp();

    /**
     * Function to validate if a base64 image string is valid.
     * @param {string} base64String - The base64 image string to validate.
     * @returns {boolean} - Returns true if valid, otherwise false.
     */
    const isValidBase64 = (base64String) => {

        // Check if the base64 string is valid
        if (!base64String) {
            return false;
        }

        return /^data:image\/jpeg;base64,/.test(base64String);
    };

    /**
     * Function to take a photo using the device camera.
     * If the device is not mobile, it falls back to selecting from photos.
     * @returns {Promise<string | undefined>} - Base64 string of the captured image or undefined if an error occurs.
     */
    const takePhoto = async () => {
        try {
            // Check if the app is running natively
            const isApp = await isAppNative();

            // Capture photo using the Camera API
            const image = await Camera.getPhoto({
                quality: 90, // Set image quality
                allowEditing: false, // Disable editing options
                source: isApp ? CameraSource.Camera : CameraSource.Photos, // Use camera or photos based on device type
                resultType: CameraResultType.Base64, // Return base64 encoded string
            });

            // Check if an image is captured
            if (!image || !image.base64String) {
                throw new Error('Aucune image capturée.');
            }

            // Get the base64 string of the captured image
            const base64String = `data:image/jpeg;base64,${image.base64String}`;

            // Check if the base64 string is available
            if (!base64String || !isValidBase64(base64String)) {
                throw new Error('L\'image capturée n\'est pas valide.');
            }

            // Prefix with data URL and compress the image
            let modifiedImage = base64String;
            const imgElement = new Image();
            imgElement.src = modifiedImage;

            // Wait for the image to load
            await new Promise((resolve) => {
                imgElement.onload = resolve; // Wait for the image to load
            });

            // Compress the image with desired dimensions
            modifiedImage = await compressImage(imgElement, 800, 600); // Compress the image with desired dimensions

            // Check if the image was modified successfully
            if (!modifiedImage) {
                throw new Error('L\'image capturée n\'a pas pu être compressée.');
            }

            // Return the modified image
            return modifiedImage;
        } catch (error) {
            throw new Error('Erreur lors de la capture de l\'image.');
        }
    };

    /**
     * Function to select a photo from the device's gallery.
     * If the device is not mobile, it falls back to selecting from photos.
     * @returns {Promise<void>} - Returns nothing if an error occurs.
     */
    const selectPhoto = async () => {
        // Check if the app is running natively
        const isApp = await isAppNative();

        try {
            // Select photo using the Camera API
            const image = await Camera.getPhoto({
                quality: 90, // Set image quality
                allowEditing: false, // Disable editing options
                source: isApp ? CameraSource.Camera : CameraSource.Photos, // Use camera or photos based on device type
                resultType: CameraResultType.Base64, // Return base64 encoded string
            });

            // Check if an image is selected
            if (!image || !image.base64String) {
                throw new Error('Aucune image sélectionnée.');
            }

            // Get the base64 string of the selected image
            const base64String = `data:image/jpeg;base64,${image.base64String}`;

            // Check if the base64 string is available and valid
            if (!base64String || !isValidBase64(base64String)) {
                throw new Error('L\'image sélectionnée n\'est pas valide.');
            }

            // Prefix with data URL and return the base64 string
            return `${base64String}`;
        } catch (error) {
            throw new Error('Erreur lors de la sélection de l\'image.');
        }
    };

    /**
     * Function to save a base64 image string to the device's filesystem.
     * This function only works on mobile devices.
     * @param {string} base64String - Base64 string of the image to save.
     * @param {number} folderNumber - The folder number to save the image to.
     */
    const savePhoto = async (base64String, folderNumber) => {

        // Check if the base64 string is available and valid
        if (!base64String || !isValidBase64(base64String)) {
            throw new Error('L\'image à enregistrer n\'est pas valide.');
        }

        // Check if the folder number is available
        if (!folderNumber) {
            throw new Error('Le numéro de dossier pour enregistrer l\'image est requis.');
        }

        // Check if the app is running natively
        if (!await isAppNative()) {
            // Show a toast message if the function is called on a non-mobile device
            await Toast.show({ text: 'Saving function is not available in the browser', duration: 'short' });
            return;
        }

        try {
            // Generate the unique file name with folderNumber and timestamp
            const timestamp = Date.now();  // Get the current timestamp
            const imageName = `${folderNumber}_${timestamp}.jpg`;  // Format: numDossier_timestamp.jpg

            try {
                // Check if the directory exists
                await Filesystem.stat({
                    path: CONFIG.FOLDER_NAME,
                    directory: Directory.Data,
                });
            } catch (error) {
                if (error.message === 'File does not exist') {
                    // Directory does not exist, create it
                    await Filesystem.mkdir({
                        path: CONFIG.FOLDER_NAME,
                        directory: Directory.Data,
                        recursive: true,
                    });
                } else {
                    throw new Error('Une erreur est survenue lors de la vérification du dossier.');
                }
            };

            // Set the path for saving the image
            const path = `${CONFIG.FOLDER_NAME}/${imageName}`;

            // Write the image data to the filesystem
            await Filesystem.writeFile({
                path: path,
                data: base64String,
                directory: Directory.Data, // Specify the directory for saving
                encoding: Encoding.Base64, // Use base64 encoding
            });

            // Show a toast message if saving is successful
            await Toast.show({ text: 'Photo enregistrée avec succès', duration: 'short' });
        } catch (error) {
            throw new Error(error?.message || 'Une erreur est survenue lors de l\'enregistrement de l\'image.');
        }
    };

    // Return the functions for use in components
    return { takePhoto, selectPhoto, savePhoto };
};