import React, { createContext, useContext, useState, useEffect } from 'react';

import UpdateService from '../services/UpdateService';

// UpdateContext: Manages the app update state
const UpdateContext = createContext();

/**
 * UpdateProvider component: Provides the update state to its children.
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * 
 * This provider checks for app updates and provides the update state to its children.
 */
export const UpdateProvider = ({ children }) => {

    // State variables for update status and new version link
    const [isUpdateRequired, setIsUpdateRequired] = useState(false);
    const [newVersionLink, setNewVersionLink] = useState('');

    /**
     * checkForUpdates: Checks for app updates and sets the update state.
     * This function is called when the component mounts.
     * @returns {void}
     */
    const checkForUpdates = async () => {
        try {
            // Check the application version against the server
            const response = await UpdateService.checkRequiredUpdate();

            // Check the response status and set the state accordingly
            if (response === false) {
                setIsUpdateRequired(false);
                setNewVersionLink('');
            } else {
                setIsUpdateRequired(true);
                setNewVersionLink(response);
            }
        } catch (error) {
            console.error('Error checking for updates:', error);
        }
    };

    // Check for updates when the component mounts
    useEffect(() => {
        checkForUpdates();
    }, []);

    // Provide the update state to children components
    return (
        <UpdateContext.Provider value={{ isUpdateRequired, newVersionLink }}>
            {children}
        </UpdateContext.Provider>
    );
};

export const useUpdate = () => useContext(UpdateContext);
