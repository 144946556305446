import React from 'react';

import Container from '../components/blocks/container';

import MediaGallery from '../modules/cameraModule/views/media-gallery';

/**
 * InternalGalleryPage component:
 * This component renders the internal gallery page of the application.
 * It wraps the ImageGallery component inside a Container component for layout purposes.
 * 
 * @returns {JSX.Element} - The rendered gallery page.
 */
const InternalGalleryPage = () => {

    // Render the internal gallery page
    return (
        <Container
            title="Galerie de l'application"
            titleBackground='var(--primary-color)'
        >
            <MediaGallery />
        </Container>
    );
}

export default InternalGalleryPage; // Export the InternalGalleryPage component
