import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

import UsersService from '../../../../services/UsersService';

import { useAuth } from '../../../../context/auth-context';

/**
 * DeviceUpdateModal Component
 *
 * This component displays a confirmation modal to update the device identifier for a user.
 * It takes `open`, `onClose`, `onSave`, `user` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - onSave: Function to confirm the action (updating the device identifier).
 * - user (object): The user object to be updated.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const DeviceUpdateModal = ({ open, onClose, onSave, user }) => {

    // State to handle loading and error states
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State to hold the current user
    const { currentUser } = useAuth();

    // State to handle the "Allow all devices" checkbox
    const [allowAllDevices, setAllowAllDevices] = useState(user.device_identifier === "AUTHORIZEALL");
    const [deviceIdentifier, setDeviceIdentifier] = useState(user.device_identifier || "");

    /**
     * Handle save
     * 
     * @throws {Error} Throws an error if saving fails
     * @returns {Promise<void>} A promise that resolves when the data is saved
     */
    const handleSave = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Edit the device identifier
            await UsersService.editDeviceIdentifier(user.id, deviceIdentifier);
            onSave();
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles the checkbox change event.
     * Updates the device identifier state based on the checkbox value.
     * Sets the device identifier to "AUTHORIZEALL" if the checkbox is checked.
     * Reverts back to the original device identifier if the checkbox is unchecked.
     * 
     * @param {object} e - The event object
     * @returns {void}
     */
    const handleCheckboxChange = (e) => {
        // Update the state based on the checkbox value
        setAllowAllDevices(e.target.checked);

        // Set the device identifier based on the checkbox value
        if (e.target.checked) {
            setDeviceIdentifier("AUTHORIZEALL"); // Set the device identifier to "AUTHORIZEALL"
        } else {
            setDeviceIdentifier(user.device_identifier); // Revert back to the original device identifier
        }
    };

    // Return null if the user is not defined
    if (!user) return null;

    // Render the device update modal
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Mettre à jour l\'identifiant d\'appareil'}
            onCloseLabel={'Annuler'}
            handleSaveLabel={'Mettre à jour'}
            handleSave={handleSave}
        >
            <div className="device-update-modal">
                {user && currentUser ? (
                    currentUser.id === user.id ? (
                        <InfoBox
                            text="Vous ne pouvez pas modifier l'identifiant d'appareil de votre propre compte."
                            type="error"
                            disabledCloseBtn={true}
                        />
                    ) : (
                        <React.Fragment>
                            { /* Display error message if there is an error */ }
                            {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

                            <InfoBox
                                text={<p>Veuillez saisir le nouvel identifiant d'appareil pour cet utilisateur.</p>}
                                disabledCloseBtn={true}
                            />

                            { /* Display error message if there is an error */ }
                            <div className="device-update--input">
                                <label htmlFor="device-identifier">Nouvel identifiant d'appareil :</label>
                                <input
                                    type="text"
                                    id="device-identifier"
                                    value={deviceIdentifier}
                                    onChange={(e) => setDeviceIdentifier(e.target.value)}
                                    placeholder="Entrer un nouvel identifiant d'appareil"
                                    disabled={allowAllDevices} // Désactiver l'input si la case est cochée
                                />
                            </div>

                            { /* Display the "Allow all devices" checkbox */ }
                            <div className="device-update--checkbox">
                                <label htmlFor="allow-all-devices">Utilisateur administrateur :</label>
                                <div className="checkbox--btn">
                                    <input
                                        type="checkbox"
                                        id="allow-all-devices"
                                        checked={allowAllDevices}
                                        onChange={handleCheckboxChange}
                                    />
                                    <span>Autoriser cet utilisateur sur l'ensemble des appareils.</span>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                ) : (
                    <InfoBox
                        text="Une erreur s'est produite lors de la récupération des informations de l'utilisateur."
                        type="error"
                        disabledCloseBtn={true}
                    />
                )}
            </div>
        </CustomModal>
    );
};

// Prop types for the DeviceUpdateModal component
DeviceUpdateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default DeviceUpdateModal;
