import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';

import CommonButton from '../../buttons/common-button';
import InfoBox from '../../blocks/messages/info-box';
import AppointmentDetails from './components/folder--appointment-details';

import DeleteAppointmentModal from './modals/modal--folder--delete-appointment';
import EditAppointmentModal from './modals/modal--folder--edit-appointment';
import CreateAppointmentModal from './modals/modal--folder--create-appointment';

import FoldersService from '../../../services/FoldersService';
import AppointmentsService from '../../../services/AppointmentsService';

/**
 * AppointmentsInfo Component
 * Displays a list of appointments for a given folder with options to edit, delete, and create appointments.
 * 
 * @param {boolean} showEditButton - Shows the edit button if true.
 * @param {boolean} showDeleteButton - Shows the delete button if true.
 * @param {boolean} showCreateButton - Shows the create button if true.
 * @param {boolean} showContainer - Shows the container if true.
 */
const AppointmentsInfo = ({
    showEditButton = false,
    showDeleteButton = false,
    showCreateButton = false,
    showContainer = true
}) => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    // State for folder ID
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(null);

    // State for appointments data
    const [folder, setFolder] = useState(null);
    const [appointments, setAppointments] = useState([]);

    // Fetch appointments data based on the folder.
    const [hasFirstClientPresent, setHasFirstClientPresent] = useState(false);

    /**
     * Fetch appointments data based on the folder.
     * This function sets the loading state, fetches the data, and updates the state.
     * If an error occurs, it sets the error state.
     * This function is called when the folderId changes.
     * @returns {Promise<void>}
     */
    const fetchAppointments = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder data based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                console.error("Folder not found");
                setError("Dossier introuvable");
                return;
            }

            // Set the folder state
            setFolder(folder);

            // Fetch the appointments data based on the folder ID
            const appointmentsData = await AppointmentsService.getAppointmentsByFolderId(folder.id);

            // If the appointments data is not found, set the appointments state to an empty array
            if (!appointmentsData) return;

            // Update the state with the fetched data
            setAppointments(appointmentsData);

            // Check if the first appointment with client present is found
            setHasFirstClientPresent(
                !appointmentsData.some(appointment => appointment.is_client_present)
            );
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch appointments data
    useEffect(() => {
        fetchAppointments();
    }, []);

    /**
     * Opens a specific modal by setting its type and the associated appointment.
     * 
     * @param {string} type - Type of modal ('edit' or 'delete').
     * @param {object|null} appointment - The selected appointment object.
     */
    const handleModalOpen = (type, appointment = null) => {
        setModal({ type, appointment });
    };

    // Display loading, error, or fallback message
    if (loading) {
        return (
            <div className={`appointments-info ${showContainer ? "folder-section" : ""}`}>
                { showContainer && <h2>Interventions</h2> }
                <p>Chargement des interventions...</p>
            </div>
        );
    }

    // Display error message if an error occurred
    if (error) {
        return (
            <div className={`appointments-info ${showContainer ? "folder-section" : ""}`}>
                { showContainer && <h2>Interventions</h2> }
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // Return null if the folder is not defined
    if (!folder) return null;

    // Display fallback message if no appointments are available
    if (appointments.length === 0) {
        return (
            <div className={`appointments-info ${showContainer ? "folder-section" : ""}`}>
                { showContainer && <h2>Interventions</h2> }

                {/* Display info box with message and create button */}
                <InfoBox
                    disabledCloseBtn={true}
                    text={(
                        <React.Fragment>
                            <p>Aucune intervension n'a été enregistrée pour le moment.</p>
                            {showCreateButton && (
                                <CommonButton
                                    label="Ajouter une nouvelle intervention"
                                    onClick={() => 
                                        handleModalOpen("create", null)
                                    }
                                    square={true}
                                    className={'std-color'}
                                />
                            )}
                        </React.Fragment>
                    )}
                />

                {/* Edit Appointment Modal */}
                {modal && modal.type === "create" && (
                    <CreateAppointmentModal
                        open={!!modal}
                        onClose={() => setModal(null)}
                        onSave={() => fetchAppointments()}
                        folderId={folder.id}
                        qualiReparAllowed={true}
                    />
                )}
            </div>
        );
    }

    // Get the last appointment from the list
    return (
        <div className={`appointments-info ${showContainer ? "folder-section" : ""}`}>
            { showContainer && <h2>Interventions</h2> }

            {/* Display the list of appointments */}
            {appointments.map((appointment, index) => (
                <AppointmentDetails
                    key={index}
                    appointment={appointment}
                    showEditButton={showEditButton}
                    showDeleteButton={showDeleteButton}
                    onEdit={(appointment) => handleModalOpen("edit", appointment)}
                    onDelete={(appointment) => handleModalOpen("delete", appointment)}
                />
            ))}

            {/* Create new appointment button, only if the last appointment is locked */}
            {showCreateButton && (
                <button
                    className="new-appointment-btn"
                    onClick={() => handleModalOpen("create", null)}
                >
                    Créer une nouvelle intervention
                    <AddIcon />
                </button>
            )}

            {/* Create Appointment Modal */}
            {modal && modal.type === "create" && (
                <CreateAppointmentModal
                    open={!!modal}
                    onClose={() => setModal(null)}
                    onSave={() => fetchAppointments()}
                    folderId={folder.id}
                    qualiReparAllowed={hasFirstClientPresent}
                />
            )}

            {/* Edit Appointment Modal */}
            {modal && modal.type === "edit" && modal.appointment && (
                <EditAppointmentModal
                    open={!!modal}
                    onClose={() => setModal(null)}
                    onSave={() => fetchAppointments()}
                    folderId={folder.id}
                    appointment={modal.appointment}
                    qualiReparAllowed={hasFirstClientPresent}
                />
            )}

            {/* Delete Appointment Modal */}
            {modal && modal.type === "delete" && modal.appointment && (
                <DeleteAppointmentModal
                    open={!!modal}
                    onClose={() => setModal(null)}
                    appointmentId={modal.appointment.id}
                    onDelete={() => fetchAppointments()}
                />
            )}
        </div>
    );
};

// Prop types for the AppointmentsInfo component
AppointmentsInfo.propTypes = {
    showEditButton: PropTypes.bool,        // Show/hide edit button
    showDeleteButton: PropTypes.bool,      // Show/hide delete button
    showCreateButton: PropTypes.bool,      // Show/hide create button
    showContainer: PropTypes.bool,         // Show/hide container
};

export default AppointmentsInfo;