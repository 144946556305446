import React from 'react'; // Importer React
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate for navigation

import { ArrowBack } from '@mui/icons-material'; // Import Material UI icon

import logo from '../../assets/images/ctr-icn.png'; // Importer l'image du logo

/**
 * Header Component
 * 
 * This component displays the page header including the title.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.title - The title to be displayed in the header.
 */
const Header = ({ title }) => {
    const navigate = useNavigate(); // Use the useNavigate hook
    const location = useLocation(); // Use the useLocation hook to get the current location

    /**
     * handleBack function
     * 
     * This function is called when the back button is clicked.
     * It navigates to the parent route in the hierarchy by removing the last segment of the URL.
     */
    const handleBack = () => {
        const currentPath = location.pathname; // Get the current path
        
        if (currentPath !== '/') {
            // Split the current path into segments and remove the last segment
            const pathSegments = currentPath.split('/').filter(Boolean); // Remove empty segments
            pathSegments.pop(); // Remove the last segment

            // Check if the last segment is "folders"
            if (pathSegments[pathSegments.length - 1] === 'folders') {
                pathSegments.pop(); // Remove the folder number segment
            }
            
            // Join the remaining segments back together to form the new path
            const newPath = '/' + pathSegments.join('/');
            
            // Navigate to the new path
            navigate(newPath || '/'); // Default to root ("/") if no segments are left
        }
    };

    // Return the header component
    return (
        <header className="header-app"> {/* Container for the header */}

            {/* Header top bar with logo */}
            <div className="header-top-bar">
                <span className="header-hello">Bienvenue</span>
                <img 
                    className="app-logo" 
                    src={logo} 
                    alt="Logo de l'application CTR Drako"
                />
            </div>

            {/* Back button and page title */}
            <button className="back-button" onClick={handleBack} aria-label="Retour page précédente">
                {location.pathname !== '/' && <ArrowBack />} {/* Only show arrow if not on root path */}
                <h1 className="page-title">{title}</h1>
            </button>
        </header>
    );
};

// Export the Header component as the default export
export default Header;