import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Hook to access the current location

import FoldersService from '../../../services/FoldersService';

import InfoBox from '../../blocks/messages/info-box';

/**
 * ObservedIssueInfo Component
 */
const ObservedIssueInfo = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    // State to hold observed issue data
    const [data, setData] = useState(null);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Fetch appointments data based on the folder.
     * This function sets the loading state, fetches the data, and updates the state.
     * If an error occurs, it sets the error state.
     * This function is called when the folderId changes.
     * @returns {Promise<void>}
     */
    const fetchAppointments = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder data based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                console.error("Folder not found");
                setError("Dossier introuvable");
                return;
            }

            // If the folder has no appointments, set the appointments state to an empty array
            if (!folder.appointments) {
                setData([]);
            }

            // Update the state with the fetched data
            setData(folder.appointments);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch appointments data
    useEffect(() => {
        fetchAppointments();
    }, []);

    // Display loading message while fetching data
    if (loading) {
        return (
            <div className="folder-section observed-issue">
                <h2>Historique des interventions</h2>
                Chargement...
            </div>
        );
    }

    // Display error message if an error occurred
    if (error) {
        return (
            <div className="folder-section observed-issue">
                <h2>Historique des interventions</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // If there is no data, return null
    if (!data || data.length === 0) {
        return (
            <div className="folder-section observed-issue">
                <h2>Historique des interventions</h2>
                Aucune intervention enregistrée.
            </div>
        );
    }

    /**
     * Formats the date to a readable format.
     * 
     * @param {string} date - The date to format.
     * @returns {string} The formatted date.
     */
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Date(date).toLocaleDateString('fr-FR', options);
    }

    // If there is no data, return null
    return (
        <div className="folder-section observed-issue">
            <h2>Historique des interventions</h2>

            {/* Display the appointments table */}
            <table className="appointments-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Technicien</th>
                        <th>Commentaires</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((appointment, index) => (
                        <tr key={index}>
                            <td>{appointment.appointment_date ? formatDate(appointment.appointment_date) : 'N/A'}</td>
                            <td>{appointment.technician ? `${appointment.technician.last_name} ${appointment.technician.first_name}` : 'N/A'}</td>
                            <td>{appointment.comments || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ObservedIssueInfo;
