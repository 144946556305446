import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import AppointmentFields from '../components/folder--appointment-fields';
import ConfirmClientAbsent from '../components/folder--appointment-confirm-client-absent';
import InfoBox from '../../../blocks/messages/info-box';

import AppointmentsService from '../../../../services/AppointmentsService';

import CustomModal from '../../../blocks/custom-modal';
import ClientAbsentMessage from '../components/folder--appointment-client-absent-warning';

/**
 * EditAppointmentModal Component
 * 
 * This component displays a modal for editing or creating an appointment.
 * It takes open, onClose, appointmentId, folderId, and onSave as props.
 * 
 * Props:
 * - open (boolean): A boolean to control the modal visibility.
 * - onClose: A function to close the modal.
 * - onSave: A function to save the appointment data.
 * - folderId (number): The folder ID associated with the appointment.
 * - qualiReparAllowed (boolean): A boolean to allow the QualiRepar fields.
 * - appointment (object): The appointment data.
 * 
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const EditAppointmentModal = ({ open, onClose, onSave, folderId, qualiReparAllowed, appointment,  }) => {
    // State to handle loading state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // State to handle client presence
    const [confirmClientAbsent, setConfirmClientAbsent] = useState(false);
    const [confirmClientPresent, setConfirmClientPresent] = useState(false);

    // State to handle appointment data and actions
    const [appointmentData, setAppointmentData] = useState(null);
    const [appointmentActions, setAppointmentActions] = useState([]);

    /**
     * Fetches the actions data from the API.
     * 
     * @returns {void}
     * @throws {Error} Throws an error if the data fetching fails.
     */
    const fetchActionsData = async () => {
        try {
            // Update loading state
            setLoading(true);

            // Fetch all actions
            const actions = await AppointmentsService.getAppointmentActions();

            // Check if actions exist
            if (!actions) return;

            // Set the actions state
            setAppointmentActions(actions.map(action => ({
                id: action.id,
                name: action.name,
                isActive: false,
            })));
        } catch {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    }

    /**
     * Fetches the initial data for the appointment.
     * 
     * @returns {void}
     * @throws {Error} Throws an error if the data fetching fails.
     */
    const fetchInitialData = async () => {
        try {
            // Update loading state
            setLoading(true);

            // Check if the appointment exists
            if (!appointment) {
                console.error("Appointment not found");
                setError("Aucune intervention trouvée.");
                return;
            }

            // Update the `isActive` state for each action
            if (appointment.appointment_actions && appointment.appointment_actions.length > 0) {
                const activeActionIds = appointment.appointment_actions.map(action => action.id);
                setAppointmentActions(prevActions =>
                    prevActions.map(action => ({
                        ...action,
                        isActive: activeActionIds.includes(action.id), // Update based on active actions
                    }))
                );
            }

            // Check if the client is present
            if (appointment.is_client_present) {
                setConfirmClientPresent(true);
            }

            // Set the initial appointment data
            setAppointmentData({
                comments: appointment.comments,
                is_quali_repar: appointment.is_quali_repar,
                quali_repar_amount: appointment.quali_repar_amount,
            });
        } catch {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    }

    // Fetch appointment data on mount
    useEffect(() => {
        const initializeData = async () => {
            try {
                await fetchActionsData(); // Wait for actions data to be fetched
                await fetchInitialData(); // Then fetch initial data
            } catch (error) {
                console.error(error.message);
            }
        };
        initializeData();
    }, []);

    /**
     * Handles saving the appointment data and closing the modal.
     * If the appointmentId is provided, it will edit the appointment.
     * Otherwise, it will edit appointment.
     * 
     * @returns {void}
     */
    const handleSave = async () => {
        try {
            // Check if the appointment exists
            if (!appointment) {
                console.error("Appointment not found");
                setError("Aucune intervention trouvée.");
                return;
            }

            // Get the selected actions
            const selectedActions = appointmentActions
                .filter(action => action.isActive)
                .map(action => action.id);

            // Common appointment data
            let payload = {};
            if (confirmClientPresent) {
                payload = {
                    comments: appointmentData?.comments,
                    is_client_present: true,
                    is_quali_repar: appointmentData?.is_quali_repar || false,
                    quali_repar_amount: parseFloat(appointmentData?.quali_repar_amount || 0).toFixed(2),
                    ids_appointment_action: selectedActions,
                };
            } else {
                payload = {
                    comments: appointmentData?.comments,
                    is_client_present: false,
                    is_quali_repar: false,
                    quali_repar_amount: 0,
                    ids_appointment_action: [],
                };
            }

            // Edit appointment
            await AppointmentsService.editAppointment(appointment.id, payload);
            onSave();
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // If the modal is not open, return null
    if (!open || folderId === null || !appointment) return null;

    // Render the edit appointment modal
    return (
        !loading && (
            <CustomModal
                open={open}
                onClose={onClose}
                title="Editer l'intervention"
                handleSave={handleSave}
                hideSaveButton={confirmClientAbsent === false && confirmClientPresent === false}
            >

                {/* Display error message if an error occurred */}   
                {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

                {/* Render the client selection, client absent warning, or appointment fields */}
                { confirmClientAbsent ? (
                    <ConfirmClientAbsent />
                ) : confirmClientPresent ? (
                    <AppointmentFields 
                        appointmentData={appointmentData} 
                        setAppointmentData={setAppointmentData}
                        appointmentActions={appointmentActions} 
                        setAppointmentActions={setAppointmentActions}
                        qualiReparAllowed={appointment.is_quali_repar || qualiReparAllowed}
                        resetClientPresent={() => {
                            setConfirmClientPresent(false);
                            setConfirmClientAbsent(true);
                        }}
                    />
                ) : (
                    <ClientAbsentMessage
                        appointmentDate={appointment.appointment_date}
                        resetClientAbsent={() => {
                            setConfirmClientPresent(true);
                            setConfirmClientAbsent(false);
                        }}
                    />
                )}
            </CustomModal>
        )
    );        
};

// Prop types for the EditAppointmentModal component
EditAppointmentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    folderId: PropTypes.number.isRequired,
    qualiReparAllowed: PropTypes.bool,
    appointment: PropTypes.object.isRequired,
};

export default EditAppointmentModal;