import React from 'react';

import AdminHeader from '../components/admin-components/admin--header';
import AdminSidebar from '../components/admin-components/admin--sidebar';

import { Outlet } from 'react-router-dom'; // Hook for navigation

import { useTitle } from '../context/header-context'; // Import the hook to get the title

/**
 * AdminLayout Component
 * 
 * This component serves as a layout wrapper for pages with a header, 
 * children content.
 *
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be rendered within the layout.
 */
const AdminLayout = () => {
    const { title } = useTitle(); // Get the title from the context

    // Render the AdminLayout with the header, sidebar, and content
    return (
        <div className='app-admin-container'>

            { /* Admin header and sidebar */ }
            <AdminHeader title={title} />

            { /* Admin sidebar */ }
            <AdminSidebar />

            { /* Admin content */ }
            <div className='app-admin-content'>
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout; // Export the AdminLayout component
