import React from 'react'; // React and hooks for component lifecycle management
import { useNavigate, useParams } from 'react-router-dom'; // Hook to access the current location

import { useFolder } from '../../context/folder-context'; // Custom hook to use folder context

import Container from '../../components/blocks/container'; // Container component for layout
import NavigateFooter from '../../components/folder-components/folder--navigate-footer';
import AppointmentSignatures from '../../components/folder-components/signatures/folder--signatures--info';

/**
 * PaymentsAndSignaturePage Component
 * 
 * This component displays the payments and signature information for a folder.
 * It also provides navigation to other parts of the folder.
 * @returns {JSX.Element} - Rendered component
 */
const PaymentsAndSignaturePage = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate

    const { title, color } = useFolder(); // Get the title and color from the folder context

    // Render the folder payments and signature page with fetched data
    return (
        <Container
            className="folder-container"
            title={title || ''}
            titleBackground={color || 'transparent'}
        >
            {/* Signature Section */}
            <AppointmentSignatures />

            {/* Navigation footer */}
            <NavigateFooter
                onBack={() => navigate(`/folders/${refFolder}/parts`)}
                onNext={() => navigate(`/folders/${refFolder}/overview/`)}
            />
        </Container>
    );
};

export default PaymentsAndSignaturePage; // Export the component for use in other parts of the application