import React from 'react';

import PropTypes from 'prop-types';

import { Send, PictureAsPdf, Print } from '@mui/icons-material';

import CommonButton from '../buttons/common-button';

/**
 * OverviewTools Component
 * 
 * This component provides action buttons to interact with a report.
 */
const OverviewTools = () => {
    /**
     * Handles sending the report by e-mail.
     * This function triggers the logic to send the report by e-mail.
     */
    const handleSendEmail = () => {
        console.log("Envoi du rapport par e-mail...");
        // Logique d'envoi par e-mail
    };

    /**
     * Handles downloading the report as a PDF.
     * This function triggers the logic to download the report as a PDF.
     */
    const handleDownloadPDF = async () => {
        console.log("Téléchargement du rapport en PDF...");
        // Logique de téléchargement en PDF
    };

    /**
     * Handles printing the report.
     * This function triggers the logic to print the report.
     * This function is currently a placeholder and does not contain any logic.
     */
    const handlePrintReport = () => {
        console.log("Impression du rapport...");
        // Logique d'impression
    };

    // Render the overview tools component
    return (
        <div className="overview-tools">

            { /* Action buttons */}
            <div className="action-buttons">
                <CommonButton
                    onClick={handleSendEmail}
                    label="Envoyer par e-mail"
                    square={true}
                    icon={<Send />}
                    className="send-email"
                    disabled={true}
                />
                <CommonButton
                    onClick={handleDownloadPDF}
                    label="Télécharger en PDF"
                    square={true}
                    icon={<PictureAsPdf />}
                    className="download-pdf"
                    disabled={true}
                />
                <CommonButton
                    onClick={handlePrintReport}
                    label="Imprimer"
                    square={true}
                    icon={<Print />}
                    className="print-report"
                    disabled={true}
                />
            </div>
        </div>
    );
};

// Prop types for OverviewTools component
OverviewTools.propTypes = {
    overviewRef: PropTypes.object.isRequired,
};

export default OverviewTools;