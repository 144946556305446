import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ExpandLess, ExpandMore } from '@mui/icons-material'; // Import Material UI icon

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import ToggleButton from '../../../buttons/toggle-button';

import UsersService from '../../../../services/UsersService';
import PermissionsService from '../../../../services/PermissionsService';

/**
 * CreateUserModal Component
 *
 * This component displays a modal for creating a new user.
 * It takes `open`, `onClose`, and `onSave` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - onSave (function): Callback function to handle saving user data.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const CreateUserModal = ({ open, onClose, onSave }) => {

    // State to handle loading and error states
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State to hold permissions and form data
    const [permissions, setPermissions] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [formData, setFormData] = useState({});

    // State to hold selected template
    const [selectedTemplate, setSelectedTemplate] = useState('');

    // State to handle collapsed groups
    const [collapsedGroups, setCollapsedGroups] = useState({});

    /**
     * Initializes the form data.
     */
    const initFormData = () => {
        setFormData({
            last_name: '',
            first_name: '',
            email: '',
            user_code: '',
            ids_roles: [],
        });
    };

    /**
     * Fetch templates user
     * 
     * @throws {Error} Throws an error if fetching fails
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     */
    const fetchTemplatesUser = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch user data by ID
            const fetchedTemplates = await PermissionsService.getTemplatesUser();

            // Set user data and form data 
            setTemplates(fetchedTemplates);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);  // Set loading to false when data is fetched or error occurs
        }
    };

    /**
     * Fetch user permissions
     * 
     * @throws {Error} Throws an error if fetching fails
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     */
    const fetchPermissions = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the user permissions
            const fetchedPermissions = await PermissionsService.getPermissions();

            // Set the permissions data
            setPermissions(fetchedPermissions);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);  // Set loading to false when data is fetched or error occurs
        }
    };

    /**
     * Handle input change
     * 
     * @param {string} field - The field to update
     * @param {string} value - The new value
     * @returns {void}
     */
    const handleInputChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    /**
     * Handle permission toggle
     * 
     * @param {number} permissionId - The ID of the permission to toggle
     * @returns {void}
     */
    const handlePermissionToggle = (permissionId) => {
        setFormData((prev) => {
            const updatedPermissions = prev.ids_roles.includes(permissionId)
                ? prev.ids_roles.filter((id) => id !== permissionId)
                : [...prev.ids_roles, permissionId];
    
            return { ...prev, ids_roles: updatedPermissions };
        });
    };

    /**
     * Handle toggling the visibility of a permissions group
     * 
     * @param {string} groupName - The name of the group to toggle
     */
    const toggleGroupVisibility = (groupName) => {
        setCollapsedGroups((prev) => ({
            ...prev,
            [groupName]: !prev[groupName], // Toggle the collapsed state
        }));
    };
    
    /**
     * Match template
     * 
     * @param {Array} userRoles - The roles of the user
     * @returns {Object} The matched template or null
     */
    const matchTemplate = (userRoles) => {
        // Find the template that matches the user roles
        return templates.find((template) =>
            template.roles.every((role) => userRoles.includes(role.id)) &&
            userRoles.every((id) => template.roles.some((role) => role.id === id))
        ) || null;
    };

    /**
     * Handle template selection
     * 
     * @param {string} templateId - The ID of the selected template
     * @returns {void}
     */
    const handleTemplateSelection = (templateId) => {
        const selectedTemplate = templates.find((template) => template.id === parseInt(templateId, 10));

        if (selectedTemplate) {
            setFormData((prev) => ({
                ...prev,
                ids_roles: selectedTemplate.roles.map((role) => role.id), // Update permissions with template roles
            }));
        }
    };

    /**
     * Handle saving the user data
     * 
     * @returns {Promise<void>} A promise that resolves when the user data is saved
     * @throws {Error} Throws an error if saving fails
     */
    const handleSave = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Create the user with the form data
            await UsersService.createUser(formData);
            onSave();
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch permissions on mount
    useEffect(() => {
        initFormData();
        fetchPermissions();
        fetchTemplatesUser();
    }, []);

    // Set initial collapsed groups when permissions are fetched
    useEffect(() => {
        if (permissions && permissions.length > 0) {
            // Set initial collapsed groups based on the permissions
            const initialGroups = permissions.reduce((groups, permission) => {
                const group = permission.group_name || 'Autres';
                groups[group] = false;
                return groups;
            }, {});
            setCollapsedGroups(initialGroups);
        }
    }, [permissions]);

    // Set selected template when user roles or templates are fetched
    useEffect(() => {
        // Match the template based on the user roles
        if (formData.ids_roles && templates.length > 0) {
            const matchedTemplate = matchTemplate(formData.ids_roles);
            setSelectedTemplate(matchedTemplate ? matchedTemplate.id.toString() : ''); // Mettre à jour le template sélectionné
        }
    }, [formData.ids_roles, templates]);

    // If the modal is not open, return null
    if (!open) return null;

    // Render the CreateUserModal component
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Créer un utilisateur'}
            onCloseLabel={'Annuler'}
            handleSaveLabel={'Créer'}
            handleSave={handleSave}
            hideSaveButton={ (loading || error ) ? true : false }
        >
            <div className='create-user-modal'>
                {loading ? (
                    <div>Chargement...</div>
                ) : error ? (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                ) : (
                    <React.Fragment>
                        <div className="user-info">
                            <div className='user-info--details'>
                                <h3>Ajouter un nouvel utilisateur</h3>
                                <div className="user-info--input">
                                    <label>Nom :</label>
                                    <input
                                        type="text"
                                        value={formData.last_name}
                                        onChange={(e) => handleInputChange('last_name', e.target.value)}
                                    />
                                </div>
                                <div className="user-info--input">
                                    <label>Prénom :</label>
                                    <input
                                        type="text"
                                        value={formData.first_name}
                                        onChange={(e) => handleInputChange('first_name', e.target.value)}
                                    />
                                </div>
                                <div className="user-info--input">
                                    <label>Email :</label>
                                    <input
                                        type="email"
                                        value={formData.email}
                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                    />
                                </div>
                                <div className="user-info--input">
                                    <label>Code utilisateur :</label>
                                    <input
                                        type="text"
                                        value={formData.user_code}
                                        onChange={(e) => handleInputChange('user_code', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='user-info--permissions'>
                                <h3>Assigner des autorisations</h3>
                                <div className='user-role--template'>
                                    <div className='user-role--template-select'>
                                        <label>Utiliser un modèle par défaut :</label>
                                        <select
                                            value={selectedTemplate}
                                            onChange={(e) => {
                                                const templateId = e.target.value;
                                                setSelectedTemplate(templateId);
                                                handleTemplateSelection(templateId);
                                            }}
                                        >
                                            <option value="" disabled>Personnalisé</option>
                                            {templates.map((template) => (
                                                <option key={template.id} value={template.id}>
                                                    {template.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {permissions && permissions.length > 0 ? (
                                    Object.entries(
                                        permissions.reduce((groups, permission) => {
                                            const group = permission.group_name || 'Autres';
                                            if (!groups[group]) {
                                                groups[group] = [];
                                            }
                                            groups[group].push(permission);
                                            return groups;
                                        }, {})
                                    ).map(([groupName, groupPermissions]) => (
                                        <div key={groupName} className='permissions-group'>
                                            <h4
                                                className='permissions-group-title'
                                                onClick={() => toggleGroupVisibility(groupName)}
                                            >
                                                {groupName}
                                                {collapsedGroups[groupName] ? <ExpandMore /> : <ExpandLess />}
                                            </h4>

                                            { !collapsedGroups[groupName] && (
                                                <ul className='permissions-list'>
                                                    {groupPermissions.map((permission) => (
                                                        <li key={permission.id} className='permission-item'>
                                                            <label htmlFor={`permission-${permission.id}`} className='permission-label'>{permission.label}</label>
                                                            <ToggleButton
                                                                isActive={formData.ids_roles?.includes(permission.id) || false}
                                                                onToggle={() => handlePermissionToggle(permission.id)} // On passe seulement l'ID de la permission
                                                            />
                                                            <p className='permission-description'>{permission.description}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <InfoBox
                                        type={'warning'}
                                        text={'Aucune autorisation trouvée.'}
                                        disabledCloseBtn={true}
                                    />
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </CustomModal>
    );
};

// Prop types for the CreateUserModal component
CreateUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CreateUserModal;