import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';

import {
    InfoOutlined,
    EventOutlined,
    PhotoCameraOutlined,
    BuildOutlined,
    CheckCircleOutlined,
    SummarizeOutlined
} from '@mui/icons-material';

import { useTitle } from '../context/header-context';
import { useFolder } from '../context/folder-context';

import ActionBar from '../components/blocks/action-bar';
import Header from '../components/blocks/header';

/**
 * FolderLayout Component
 * 
 * This component serves as a layout wrapper for folder pages with a header,
 * children content, and an action bar.
 * 
 * @returns {JSX.Element} - Rendered component
 */
const FolderLayout = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate
    const location = useLocation(); // Hook to access the current location

    // Get the title and color from the folder context
    const { title } = useTitle();
    const { updateTitle, updateColor } = useFolder();

    // State to manage the active index of the action bar
    const [activeIndex, setActiveIndex] = useState(0);

    /**
     * Array of navigation items, each with name, icon, path, action, and color.
     * The path is defined once here and used in both action and the effect hook.
     */
    const items = [
        {
            name: 'Informations produit',
            icon: <InfoOutlined />,
            path: `/folders/${refFolder}/reporting`,
            action() { navigate(`/folders/${refFolder}/reporting`); },
            backgroundColor: '#4caf50',
        },
        {
            name: 'Interventions',
            icon: <EventOutlined />,
            path: `/folders/${refFolder}/appointments`,
            action() { navigate(`/folders/${refFolder}/appointments`); },
            backgroundColor: '#2196F3',
        },
        {
            name: 'Médias du dossier',
            icon: <PhotoCameraOutlined />,
            path: `/folders/${refFolder}/media-upload-tool`,
            action() { navigate(`/folders/${refFolder}/media-upload-tool`); },
            backgroundColor: '#FFC107',
        },
        {
            name: 'Pièces du dossier',
            icon: <BuildOutlined />,
            path: `/folders/${refFolder}/parts`,
            action() { navigate(`/folders/${refFolder}/parts`); },
            backgroundColor: '#F44336',
        },
        {
            name: 'Signatures',
            icon: <CheckCircleOutlined />,
            path: `/folders/${refFolder}/payments-and-signature`,
            action() { navigate(`/folders/${refFolder}/payments-and-signature`); },
            backgroundColor: '#3F51B5',
        },
        {
            name: 'Récapitulatif du dossier',
            icon: <SummarizeOutlined />,
            path: `/folders/${refFolder}/overview/`,
            action() { navigate(`/folders/${refFolder}/overview/`); },
            backgroundColor: '#9C27B0',
        }
    ];

    /**
     * Effect to update activeIndex based on the current URL path.
     * Sets activeIndex based on the item's path to keep the color in sync.
     */
    useEffect(() => {
        // Normalize the pathname by removing the trailing slash
        const normalizedPathname = location.pathname.replace(/\/$/, '');

        // Normalize path by removing trailing slash
        const currentIndex = items.findIndex(item => normalizedPathname === item.path.replace(/\/$/, ''))

        // Update the active index if the current path matches an item's path
        if (currentIndex !== -1) {
            setActiveIndex(currentIndex);
            updateTitle(items[currentIndex].name);
            updateColor(items[currentIndex].backgroundColor);
        }
    }, [location.pathname, items]);   
    
    // Render the folder layout with header, content, and action bar
    return (
        <div className='app-container'>
            { /* Header component with title */ }
            <Header title={title} />

            { /* Content with outlet */ }
            <div className='app-content' style={{ borderColor: items[activeIndex].backgroundColor }}>
                <Outlet />
            </div>

            { /* Action bar with navigation items */ }
            <ActionBar
                items={items}
                selectedIndex={activeIndex}
            />
        </div>
    );
};

export default FolderLayout;