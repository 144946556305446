// src/services/MediasService.jsx
import ApiService from "./ApiService";

class MediasService extends ApiService{
    /**
     * Fetches media categories.
     * @returns {Promise<Array>} A promise that resolves to an array of media categories.
     * @throws {Error} Throws an error if the request fails.
     */
    async getMediaCategories() {
        try {
            // Fetch all media categories
            const categories = await this.request('GET', '/media-category/read');
            return categories;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches a media category by ID.
     * @param {number} categoryId - The unique identifier for the category.
     * @returns {Promise<Object>} A promise that resolves to the media category.
     * @throws {Error} Throws an error if the request fails.
     */
    async getMediaCategoryById(categoryId) {

        // Check if a category ID was provided
        if (!categoryId) {
            throw new Error('Aucune catégorie n\'a été fournie pour la récupération.');
        }

        try {
            // Fetch the media category
            const category = await this.request('GET', `${categoryId}/media-category/read`);
            return category;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Creates a new media category.
     * @param {Object} payload - The data for the new category.
     * @returns {Promise<Object>} A promise that resolves to the added category.
     * @throws {Error} Throws an error if the request fails.
     */
    async createMediaCategory(payload) {

        // Prepare the data for the request
        if (!payload) {
            throw new Error('Aucune donnée n\'a été fournie pour créer la catégorie.');
        }

        try {
            // Send the request to create the media category
            const response = await this.request('POST', '/media-category/create', payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }
    
    /**
     * Edits a media category.
     * @param {number} categoryId - The unique identifier for the category.
     * @param {Object} payload - The data for the new category.
     * @returns {Promise<Object>} A promise that resolves to the added category.
     * @throws {Error} Throws an error if the request fails.
     */
    async editMediaCategory(categoryId, payload) {

        // Check if a category ID was provided
        if (!categoryId) {
            throw new Error('Aucune catégorie n\'a été fournie pour l\'édition.');
        }

        // Prepare the data for the request
        if (!payload) {
            throw new Error('Aucune donnée n\'a été fournie pour éditer la catégorie.');
        }

        try {
            // Send the request to edit the media category
            const response = await this.request('PUT', `${categoryId}/media-category/update`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Deletes a media category.
     * @param {number} categoryId - The unique identifier for the category.
     * @returns {Promise} A promise that resolves when the category is deleted.
     * @throws {Error} Throws an error if the request fails.
     */
    async deleteMediaCategory(categoryId) {

        // Check if a category ID was provided
        if (!categoryId) {
            throw new Error('Aucune catégorie n\'a été fournie pour la suppression.');
        }

        try {
            // Send the request to delete the media category
            const response = await this.request('DELETE', `${categoryId}/media-category/delete`);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches media groups.
     * @returns {Promise<Array>} A promise that resolves to an array of media groups.
     * @throws {Error} Throws an error if the request fails.
     */
    async getMediaGroups() {
        try {
            // Fetch all media groups
            const groups = await this.request('GET', '/media-group-category/read');
            return groups;
        } catch (error) {
            console.error('Failed to fetch media groups:', error);
            throw new Error('Media groups could not be retrieved.');
        }
    }

    /**
     * Fetches a media group by ID.
     * @param {number} groupId - The unique identifier for the group.
     * @returns {Promise<Object>} A promise that resolves to the media group.
     * @throws {Error} Throws an error if the request fails.
     */
    async getMediaGroupById(groupId) {

        // Check if a group ID was provided
        if (!groupId) {
            throw new Error('Aucun groupe n\'a été fourni pour la récupération.');
        }

        try {
            // Fetch the media group
            const group = await this.request('GET', `${groupId}/media-group-category/read`);
            return group;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Creates a new media group.
     * @param {Object} payload - The data for the new group.
     * @returns {Promise<Object>} A promise that resolves to the added group.
     * @throws {Error} Throws an error if the request fails.
     */
    async createMediaGroup(payload) {

        // Prepare the data for the request
        if (!payload) {
            throw new Error('Aucune donnée n\'a été fournie pour créer le groupe.');
        }

        try {
            // Send the request to create the media group
            const response = await this.request('POST', '/media-group-category/create', payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Edits a media group.
     * @param {Object} payload - The data for the new group.
     * @returns {Promise<Object>} A promise that resolves to the added group.
     * @throws {Error} Throws an error if the request fails.
     */
    async editMediaGroup(groupId, payload) {

        // Check if a group ID was provided
        if (!groupId) {
            throw new Error('Aucun groupe n\'a été fourni pour l\'édition.');
        }

        // Prepare the data for the request
        if (!payload) {
            throw new Error('Aucune donnée n\'a été fournie pour éditer le groupe.');
        }

        try {
            // Send the request to edit the media group
            const response = await this.request('PUT', `${groupId}/media-group-category/update`, payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Deletes a media group.
     * @param {number} groupId - The unique identifier for the group.
     * @returns {Promise} A promise that resolves when the group is deleted.
     * @throws {Error} Throws an error if the request fails.
     */
    async deleteMediaGroup(groupId) {

        // Check if a group ID was provided
        if (!groupId) {
            throw new Error('Aucun groupe n\'a été fourni pour la suppression.');
        }

        try {
            // Send the request to delete the media group
            const response = await this.request('DELETE', `${groupId}/media-group-category/delete`);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Uploads a media file.
     * @param {Object} payload - The data for the new media.
     * @returns {Promise<Object>} A promise that resolves to the added media.
     * @throws {Error} Throws an error if the request fails.
     */
    async uploadMedia(payload) {
        // Prepare the data for the request
        if (!payload) {
            throw new Error('Aucune donnée n\'a été fournie pour créer le média.');
        }

        try {
            // Send the request to create the media
            const response = await this.request('POST', '/media/create', payload);
            return response;
        } catch (error) {
            throw new Error(error?.message);
        }
    }

    /**
     * Fetches media by folder ID.
     * @param {number} folderId - The unique identifier for the folder.
     * @returns {Promise<Array>} A promise that resolves to an array of media objects.
     * @throws {Error} Throws an error if the request fails.
     */
    async getMediaByFolderId(folderId) {
        try {
            // Fetch all media
            const media = await this.request('GET', `/media`);

            if (!media) return []; // Return empty array if no media is found
            
            // Filter media by folder ID
            const mediaByFolder = media.filter(media => media.id_folder === folderId);
            return mediaByFolder;
            
        } catch (error) {
            console.error('Failed to fetch media for folder:', error);
            throw new Error('Media could not be retrieved.');
        }
    }

    /**
     * Fetches technical bulletins by appliance model.
     * @param {string} model - The model of the appliance.
     * @returns {Promise<Array>} A promise that resolves to an array of technical bulletins.
     * @throws {Error} Throws an error if the request fails.
     */
    async getTechnicalBulletinsByApplianceModel(model) {
        try {
            // Fetch all technical bulletins
            const technicalBulletins = await this.request('GET', `/technical-bulletin`);
    
            if (!technicalBulletins) return []; // Return empty array if no technical bulletins are found
            
            // Filter technical bulletins by appliance model
            const technicalBulletinsByModel = technicalBulletins.filter(
                (technicalBulletin) => technicalBulletin.model === model
            );
    
            return technicalBulletinsByModel;
        } catch (error) {
            console.error('Failed to fetch technical bulletins for appliance model:', error);
            throw new Error('Technical bulletins could not be retrieved.');
        }
    }    
}

// Exports a new instance of MediasService for use in other components
export default new MediasService();