import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import NavigationButtons from '../components/folder--signatures--navigation-buttons';
import SignatureContent from '../components/folder--signatures--signature-content';
import InfoBox from '../../../blocks/messages/info-box';
import TechnicianCountSelector from '../components/folder--signatures--technician-count-selector';
import SignaturesTabs from '../components/folder--signatures--tabs';

import ConfirmationSignaturesModal from './modal--folder--signatures-confirmation';
import CustomModal from '../../../blocks/custom-modal';

/**
 * EditAppointmentSignaturesModal - Component to edit, preview, and confirm signatures for appointment technicians and clients.
 *
 * @param {boolean} open - Determines if the modal is open.
 * @param {function} onClose - Handler to close the modal.
 * @param {object} appointment - The appointment object containing appointment data.
 */
const SignaturesModal = ({ open, onClose, appointment }) => {

    // State to manage technician count and active tab
    const [technicianCount, setTechnicianCount] = useState(1);
    const [activeTab, setActiveTab] = useState(0);

    // State to hold technician and client signatures
    const [technicianSignatures, setTechnicianSignatures] = useState(Array(technicianCount).fill(null));
    const [clientSignature, setClientSignature] = useState(null);

    // State to manage confirmation modal visibility
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // State to hold final image data
    const [finalImage, setFinalImage] = useState(null);

    // State to manage error messages
    const [error, setError] = useState(null);

    // Adjust technician signatures array size based on technician count
    useEffect(() => {
        // Update technician signatures array based on technician count
        setTechnicianSignatures((prevSignatures) => {
            const updatedSignatures = [...prevSignatures];
            updatedSignatures.length = technicianCount;

            // Fill new slots with null values
            return updatedSignatures.fill(null, prevSignatures.length);
        });
    }, [technicianCount]);

    /**
     * Handler to change the active tab.
     * @param {number} index - Index of the tab to activate.
     * @returns {void}
     */
    const handleTabChange = (index) => setActiveTab(index);

    /**
     * Handler to navigate to the next tab.
     * @returns {void}
     */
    const handleNext = () => activeTab < technicianCount + (appointment.is_client_present ? 1 : 0) - 1 && handleTabChange(activeTab + 1);

    /**
     * Handler to navigate to the previous tab.
     * @returns {void}
     */
    const handlePrev = () => activeTab > 0 && handleTabChange(activeTab - 1);

    /**
     * Function to generate the final image with all signatures.
     * @returns {Promise<string>} - The final image data URL.
     * @throws {Error} - Error message if the final image cannot be generated.
     */
    const generateFinalImage = async () => {

        // Init signature canvases and constants
        const signatureCanvases = [];
        const padding = 20;
        const backgroundColor = '#ffffff';
        const signatureBackgroundColor = '#f0f0f0';

        const fixedWidth = 600;
        const fixedHeight = 300;
        const innerPadding = 40;
        const maxSignaturesPerRow = 3;

        // Generate canvases for technician signatures
        for (const signatureBase64 of technicianSignatures) {

            // Skip empty signatures
            if (signatureBase64) {
                const img = new Image();
                img.src = signatureBase64;

                try {
                    // Wait for image to load before creating canvas
                    await new Promise((resolve) => {
                        img.onload = () => {
                            // Create canvas and draw image
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            canvas.width = fixedWidth;
                            canvas.height = fixedHeight;

                            ctx.fillStyle = signatureBackgroundColor;
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            ctx.drawImage(img, innerPadding, innerPadding, fixedWidth - 2 * innerPadding, fixedHeight - 2 * innerPadding);

                            // Add canvas to signature canvases
                            signatureCanvases.push(canvas);
                            resolve();
                        };
                    });
                } catch (error) {
                    setError(error?.message || "Une erreur inattendue est survenue");
                }
            }
        }

        // Generate canvas for client signature, if exists
        if (appointment.is_client_present && clientSignature) {

            // Create image element and set source
            const clientImg = new Image();
            clientImg.src = clientSignature;

            try {
                // Wait for image to load before creating canvas
                await new Promise((resolve) => {
                    clientImg.onload = () => {
                        // Create canvas and draw image
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = fixedWidth;
                        canvas.height = fixedHeight;

                        ctx.fillStyle = signatureBackgroundColor;
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
                        ctx.drawImage(clientImg, innerPadding, innerPadding, fixedWidth - 2 * innerPadding, fixedHeight - 2 * innerPadding);

                        // Add canvas to signature canvases
                        signatureCanvases.push(canvas);
                        resolve();
                    };
                });
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        }

        // Prepare final canvas dimensions
        const rows = Math.ceil(signatureCanvases.length / maxSignaturesPerRow);
        const totalWidth = Math.min(signatureCanvases.length, maxSignaturesPerRow) * fixedWidth + (padding * (Math.min(signatureCanvases.length, maxSignaturesPerRow) + 1));
        const totalHeight = (fixedHeight + padding) * rows + padding;

        // Create final canvas and context
        const finalCanvas = document.createElement('canvas');
        finalCanvas.width = totalWidth;
        finalCanvas.height = totalHeight;
        const ctx = finalCanvas.getContext('2d');

        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, finalCanvas.width, finalCanvas.height);

        let xOffset = padding;
        let yOffset = padding;

        // Draw each signature with labels
        for (let i = 0; i < signatureCanvases.length; i++) {
            // Draw signature and label
            ctx.fillStyle = 'black';
            ctx.font = '20px Arial';
            const labelText = (i < technicianSignatures.length) ? `Signature technicien ${i + 1} :` : `Signature client :`;

            ctx.fillText(labelText, xOffset + 10, yOffset + 15);
            ctx.drawImage(signatureCanvases[i], xOffset, yOffset + 20);

            xOffset += fixedWidth + padding;

            // Move to next row if max signatures per row is reached
            if ((i + 1) % maxSignaturesPerRow === 0) {
                xOffset = padding;
                yOffset += fixedHeight + padding;
            }
        }

        // Return final image data URL
        const finalImageDataUrl = finalCanvas.toDataURL('image/jpeg');

        // Set final image state
        return finalImageDataUrl;
    }

    /**
     * Handler to save the signatures and generate the final image.
     * @returns {void}
     */
    const handleSave = async () => {
        // Check if all signatures are present before saving
        const missingTechnicianSignature = technicianSignatures.some((signature) => signature === null);
        const missingClientSignature = appointment.is_client_present && clientSignature === null;

        // Display error message if any signature is missing
        if (missingTechnicianSignature || missingClientSignature) {
            console.error('Missing signatures');
            setError('Veuillez signer toutes les signatures avant de continuer.');
            return;
        }

        try {
            // Generate final image data URL
            const finalImageDataUrl = await generateFinalImage();

            // Check if final image data URL is generated
            if (!finalImageDataUrl) {
                console.error('Error generating final image data URL');
                setError('Une erreur s\'est produite lors de la sauvegarde des signatures.');
                return;
            }

            // Set final image state
            setFinalImage(finalImageDataUrl);

            // Show confirmation modal
            setShowConfirmationModal(true);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    /**
     * Handler to close all modals.
     * @returns {void}
     */
    const closeAllModals = () => {
        setShowConfirmationModal(false);
        onClose();
    };

    // Destructure appointment data
    if (!appointment) return null;

    // Render component
    return (
        <React.Fragment>

            { /* Edit Signatures Modal */ }
            <CustomModal
                open={open}
                onClose={onClose}
                title="Éditer les signatures"
                handleSave={handleSave}
                fullScreen={true}
                disableFooter={true}
            >
                { /* Display error message if present */ }
                {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

                { /* Signature Modal Container */ }
                <div className="signature-modal-container">

                    { /* Technician Count Selector */ }
                    <TechnicianCountSelector
                        technicianCount={technicianCount}
                        setTechnicianCount={setTechnicianCount}
                    />

                    { /* Tabs for Technician and Client Signatures */ }
                    <SignaturesTabs
                        technicianCount={technicianCount}
                        activeTab={activeTab}
                        handleTabChange={handleTabChange}
                        hasClient={appointment.is_client_present}
                    />

                    { /* Signature Content */ }
                    <SignatureContent
                        activeTab={activeTab}
                        technicianCount={technicianCount}
                        technicianSignatures={technicianSignatures}
                        setTechnicianSignatures={setTechnicianSignatures}
                        clientSignature={clientSignature}
                        setClientSignature={setClientSignature}
                        hasClient={appointment.is_client_present}
                    />

                    { /* Navigation Buttons */ }
                    <NavigationButtons
                        activeTab={activeTab}
                        technicianCount={technicianCount}
                        hasClient={appointment.is_client_present}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        handleSave={handleSave}
                        onClose={onClose}
                    />
                </div>
            </CustomModal>

            {/* Confirmation modal for final image */}
            {showConfirmationModal && (
                <ConfirmationSignaturesModal
                    open={showConfirmationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    finalImage={finalImage}
                    appointmentId={appointment.id}
                    onSuccess={closeAllModals}
                />
            )}
        </React.Fragment>
    );
};

// Prop types validation
SignaturesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    appointment: PropTypes.object.isRequired,
};

export default SignaturesModal;
