import React from 'react';

import PropTypes from 'prop-types';

import { Edit } from '@mui/icons-material';

import InfoBox from '../../../blocks/messages/info-box';
import CommonButton from '../../../buttons/common-button';

/**
 * ConfirmClientAbsent Component
 * 
 * This component displays a confirmation message to confirm the client absence for an appointment.
 * It takes `appointmentDate` and `resetClientAbsent` as props.
 * 
 * Props:
 * - appointmentDate (string): The appointment date.
 * - resetClientAbsent: A function to edit the appointment.
 * 
 * @param {object} props - Component props
 * 
 * @returns {JSX.Element} - Rendered component
 */
const ClientAbsentMessage = ({ appointmentDate, resetClientAbsent }) => {

    // Format the date
    const formattedDate = new Date(appointmentDate).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    // Render the confirmation message
    return(
        <React.Fragment>
            <InfoBox
                text={(
                    <React.Fragment>
                        <p className='w-100'>Le client a été marqué absent en date du <strong>{formattedDate}</strong>.</p>
                        <CommonButton
                            icon={<Edit />}
                            label="Modifier"
                            onClick={() => resetClientAbsent()}
                            square={true}
                            className={'no-side-margin std-color'}
                        />
                    </React.Fragment>
                )}
            />
        </React.Fragment>
    );
};

// Prop types for the ConfirmClientAbsent component
ClientAbsentMessage.propTypes = {
    appointmentDate: PropTypes.string.isRequired,
    resetClientAbsent: PropTypes.func.isRequired,
};

export default ClientAbsentMessage;