import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import MediasService from '../../../../services/MediasService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * DeleteMediaGroupModal Component
 *
 * This component displays a modal for confirming the deletion of an media group.
 * It takes `open`, `onClose`, `groupId`, and `onDelete` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - groupId (number): The ID of the group to delete.
 * - onDelete: Function to delete the group.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const DeleteMediaGroupModal = ({ open, onClose, groupId, onDelete }) => {
    // State to handle loading and error messages
    const [autorizedDelete, setAutorizedDelete] = useState(false);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    /**
     * Check if the group can be deleted
     *
     * @returns {void}
     */
    const checkIfGroupCanBeDeleted = async () => {
        setLoading(true); // Set loading state to true
        let count = 0;

        try {
            // Get the number of categories in the group
            const mediaCategories = await MediasService.getMediaCategories();
            count = mediaCategories.filter(category => category.id_category_group === groupId).length;

            // If there are no categories in the group, set the delete authorization to true
            setAutorizedDelete(count === 0);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles the deletion confirmation
     * 
     * @returns {void}
     */
    const handleConfirmDelete = async () => {
        if (groupId && autorizedDelete) {
            try {
                // Delete the group
                await MediasService.deleteMediaGroup(groupId);

                // Trigger the onDelete callback
                onDelete();

                // Close the modal after the operation
                onClose();
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        }
    };

    // Fetch media groups and categories on component mount
    useEffect(() => {
        checkIfGroupCanBeDeleted();
    }, []);

    // If the modal is not open, return null
    if (!open, !groupId) return null;

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={autorizedDelete ? 'Confirmer la suppression' : 'Suppression impossible'}
            handleSave={handleConfirmDelete}
            handleSaveLabel={'Confirmer la suppression'}
            onCloseLabel={autorizedDelete ? 'Annuler' : 'Fermer'}
            hideSaveButton={!autorizedDelete}
        >
            { error && (
                <InfoBox
                    type="error"
                    text={error}
                    disabledCloseBtn={true}
                />
            )}
            { autorizedDelete ? (
                <InfoBox
                    type="warning"
                    text={
                        <React.Fragment>
                            <p>Êtes-vous sûr de vouloir supprimer ce groupe ? Cette action est irréversible.</p>
                        </React.Fragment>
                    }
                    disabledCloseBtn={true}
                />
            ) : (
                <InfoBox
                    type="error"
                    text={
                        <React.Fragment>
                            <p>Impossible de supprimer ce groupe car il contient des catégories de médias.</p>
                        </React.Fragment>
                    }
                    disabledCloseBtn={true}
                />
            )}
        </CustomModal>
    );
};

// Prop types for the DeleteMediaGroupModal component
DeleteMediaGroupModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    groupId: PropTypes.oneOfType([ 
        PropTypes.string,
        PropTypes.number
    ]),
    onDelete: PropTypes.func.isRequired,
};

export default DeleteMediaGroupModal;
