import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

import UsersService from '../../../../services/UsersService';

import { useAuth } from '../../../../context/auth-context';

/**
 * UserStatusConfirmationModal Component
 *
 * This component displays a confirmation modal to confirm user status changes (activate or deactivate).
 * It takes `open`, `onClose`, `onConfirm`, and `message` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - onConfirm: Function to confirm the action (status change).
 * - user (object): The user object to be updated.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const UserStatusConfirmationModal = ({ open, onClose, onConfirm, user }) => {
    // State to handle loading and error states
    const [error, setError] = useState(null);

    // State to hold the current user
    const { currentUser } = useAuth();

    /**
     * Handles the save action to update the user status.
     * Calls the onConfirm function to update the user status.
     * Closes the modal on success.
     */
    const handleSave = async () => {
        try {
            // Edit the user status
            await UsersService.editUserStatus(user.id);
            onConfirm();
            onClose();
        } catch (error) {
            setError(error.message);
        }
    };

    // Return null if the user is not defined
    if (!user) return null;

    // Render the user status confirmation modal 
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Confirmation'}
            onCloseLabel={'Annuler'}
            handleSaveLabel={'Confirmer'}
            handleSave={() => handleSave()}
            hideSaveButton={currentUser && currentUser.id === user.id}
        >
            <div className="confirmation-modal">
                {user && currentUser ? (
                    currentUser.id === user.id ? (
                        <InfoBox
                            text="Vous ne pouvez pas modifier l'identifiant d'appareil de votre propre compte."
                            type="error"
                            disabledCloseBtn={true}
                        />
                    ) : (
                        <React.Fragment>
                            { /* Display error message if there is an error */ }
                            {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

                            { /* Display confirmation message */ }
                            <InfoBox
                                type={'warning'}
                                text={
                                    <p>Etes vous sûr de vouloir <strong>{user.is_active ? 'désactiver' : 'activer'}</strong> l'utilisateur <strong>{user.username}</strong> ?</p>
                                }
                                disabledCloseBtn={true}
                            />
                        </React.Fragment>
                    )
                ) : (
                    <InfoBox
                        text="Impossible de charger les informations de l'utilisateur."
                        type="error"
                        disabledCloseBtn={true}
                    /> 
                )}
            </div>
        </CustomModal>
    );
};

// Prop types for the UserStatusConfirmationModal component
UserStatusConfirmationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default UserStatusConfirmationModal;
