import React from 'react';

import PropTypes from 'prop-types';

/**
 * TechnicianCountSelector - Composant pour sélectionner le nombre de techniciens.
 *
 * @param {number} technicianCount - Le nombre de techniciens sélectionné.
 * @param {function} setTechnicianCount - Fonction pour mettre à jour le nombre de techniciens.
 */
const TechnicianCountSelector = ({ technicianCount, setTechnicianCount }) => {

    // Render the technician count selector
    return (
        <div className="technician-count-selector">
            <label>Nombre de techniciens présents lors de l'intervention :</label>
            <select
                value={technicianCount}
                onChange={(e) => setTechnicianCount(Number(e.target.value))}
            >
                {Array.from({ length: 10 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                        {index + 1}
                    </option>
                ))}
            </select>
        </div>
    );
};

// Define PropTypes for the TechnicianCountSelector component
TechnicianCountSelector.propTypes = {
    technicianCount: PropTypes.number.isRequired,
    setTechnicianCount: PropTypes.func.isRequired,
};

export default TechnicianCountSelector;
