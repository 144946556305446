import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { RotateRight } from '@mui/icons-material';

import MediasService from '../../../services/MediasService';

import CommonButton from '../../buttons/common-button';
import InfoBox from '../../blocks/messages/info-box';

/**
 * GallerySection Component
 * 
 * This component renders a gallery of media files from a folder.
 * @param {object} folder - The folder to fetch media files from
 * @returns {JSX.Element}
 */
const GallerySection = ({ folder }) => {
    // State variable for gallery media
    const [galleryMedia, setGalleryMedia] = useState([]);

    // State variables for loading and error handling
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Fetches the media files from the folder.
     * Sets the loading state and handles errors.
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchGalleryMedia = async () => {
        setLoading(true);
        try {
            const media = await MediasService.getMediaByFolderId(folder.id);
            setGalleryMedia(media);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch media files on component mount
    return (
        <div className="media-gallery--section">
            <h2>Galerie du dossier</h2>

            {loading && <CircularProgress />}

            {error && <div>Erreur : {error}</div>}

            {galleryMedia.length === 0 && <InfoBox type="information" text="Aucun média trouvé." />}
            {galleryMedia.map((media) => (
                <img key={media.id} src={media.data} alt={`Média ${media.id}`} />
            ))}

            <CommonButton
                icon={<RotateRight />}
                label="Charger les médias"
                onClick={fetchGalleryMedia}
            />
        </div>
    );
};

export default GallerySection;
