import React from 'react';

import PropTypes from 'prop-types';

import ToggleButton from '../../../buttons/toggle-button';

/**
 * BonusQualiReparFields Component
 * 
 * This component displays the fields for the bonus quali repar section of an appointment.
 * It takes appointmentData and setAppointmentData as props.
 * 
 * Props:
 * - appointmentData (object): The appointment data.
 * - setAppointmentData (function): A function to set the appointment data.
 * 
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const BonusQualiReparFields = ({ appointmentData, setAppointmentData }) => {

    // Render the bonus quali repar fields
    return (
        <div className="folder-section w-100 flex">
            <h2>Bonus Quali Repar</h2>

            {/* Bonus Quali Repar fields */}
            <div className="form-group w-50">
                <label className="form-label">Bonus Quali Repar</label>
                <ToggleButton
                    isActive={appointmentData?.is_quali_repar || false}
                    onToggle={() => setAppointmentData({ ...appointmentData, is_quali_repar: !appointmentData.is_quali_repar })}
                />
            </div>

            {/* Bonus Quali Repar amount */}
            {appointmentData?.is_quali_repar && (
                <div className="form-group w-50 flex">
                    <label className="form-label">Montant du bonus</label>
                    <input
                        type="number"
                        value={appointmentData?.quali_repar_amount || ''}
                        onChange={(e) => setAppointmentData({ ...appointmentData, quali_repar_amount: e.target.value })}
                    />
                </div>
            )}
        </div>
    );
};

// Prop types for the BonusQualiReparFields component
BonusQualiReparFields.propTypes = {
    appointmentData: PropTypes.object.isRequired,
    setAppointmentData: PropTypes.func.isRequired,
};

export default BonusQualiReparFields;