import React from 'react';

import AppointmentActionsTable from '../../components/admin-components/appointments/admin--appointment-actions-manager';

/**
 * AdminAppointmentActionsManager Component
 * 
 * This component displays the appointment actions management table in the admin panel.
 */
const AdminAppointmentActionsManager = () => {

    // Render the appointment actions management table
    return (
        <AppointmentActionsTable />
    );
};

export default AdminAppointmentActionsManager;
