import React from 'react';

import Container from '../../components/blocks/container';
import ClientInfo from '../../components/folder-components/folder--client-info';

/**
 * FolderPage Component
 * 
 * This component displays the folder information for a folder.
 * It also provides navigation to other parts of the folder.
 * @returns {JSX.Element} - Rendered component
 */
const FolderPage = () => {

    // Render the folder page with fetched data
    return (
        <Container className="folder-container">
            {/* Folder Client Information Section */}
            <ClientInfo
                navigateBlock={true}
                enabledMapButtons={true}
            />
        </Container>
    );
};

export default FolderPage;