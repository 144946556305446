import React from 'react';

import PropTypes from 'prop-types';

import { PersonOff } from '@mui/icons-material';

import InfoBox from '../../../blocks/messages/info-box';
import CommonButton from '../../../buttons/common-button';
import ToggleButton from '../../../buttons/toggle-button';
import BonusQualiReparFields from './folder--appointment-quali-repar';

/**
 * AppointmentFields Component
 * 
 * This component displays the fields for an appointment.
 * It takes appointmentData, setAppointmentData, appointmentActions, and setAppointmentActions as props.
 * 
 * Props:
 * - appointmentData (object): The appointment data.
 * - setAppointmentData (function): A function to set the appointment data.
 * - appointmentActions (array): The appointment actions.
 * - setAppointmentActions (function): A function to set the appointment actions.
 * - qualiReparAllowed (boolean): A boolean to allow the QualiRepar fields.
 * - resetClientPresent (function): A function to reset the client presence.
 * 
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const AppointmentFields = ({ 
    appointmentData, 
    setAppointmentData, 
    appointmentActions, 
    setAppointmentActions,
    qualiReparAllowed = false,
    resetClientPresent,
}) => {

    /**
     * Handles the toggle action for the appointment.
     * 
     * @param {number} actionId - The action ID to toggle.
     * @returns {void}
     */
    const handleToggleAction = (actionId) => {

        // Update the actions state
        const updatedActions = appointmentActions && appointmentActions.map(action => {
            if (action.id === actionId) {
                return {
                    ...action,
                    isActive: !action.isActive,
                };
            }
            return action;
        });

        // Set the actions state
        setAppointmentActions(updatedActions);

        // Update the appointment data
        setAppointmentData({
            ...appointmentData,
            actions: updatedActions.filter(action => action.isActive).map(action => action.id),
        });
    };

    // Render the appointment fields
    return (
        <div className="appointment-form w-100">
            <div className="folder-section w-100 flex">
                <h2>Intervention</h2>
                <InfoBox
                    text={(
                        <React.Fragment>
                            <p>Le client est absent lors de votre passage ?</p>
                            <strong>Passer l'intervension en mode absent.</strong>
                            <CommonButton
                                icon={<PersonOff />}
                                label="Client absent"
                                onClick={() => resetClientPresent()}
                                square={true}
                                className={'no-side-margin std-color'}
                            />
                        </React.Fragment>
                    )}
                />

                { /* Render the technician comments */}
                <div className="form-group w-100">
                    <label>Compte rendu du technicien</label>
                    <textarea
                        value={appointmentData?.comments || ''}
                        onChange={(e) => setAppointmentData({ ...appointmentData, comments: e.target.value })}
                    />
                </div>

                {/* Render the appointment actions */}
                {appointmentActions && appointmentActions.map(action => (
                    <div className="form-group appointment-item" key={action.id}>
                        <label className="form-label">{action.name}</label>
                        <ToggleButton
                            isActive={action.isActive ? true : false}
                            onToggle={() => handleToggleAction(action.id)}
                        >
                            {action.isActive ? 'Désactiver' : 'Activer'}
                        </ToggleButton>
                    </div>
                ))}
            </div>
            {qualiReparAllowed && (
                <BonusQualiReparFields
                    appointmentData={appointmentData}
                    setAppointmentData={setAppointmentData}
                />
            )}
        </div>
    );
};

// Prop types for the AppointmentFields component
AppointmentFields.propTypes = {
    appointmentData: PropTypes.object,
    setAppointmentData: PropTypes.func,
    appointmentActions: PropTypes.array,
    setAppointmentActions: PropTypes.func,
    qualiReparAllowed: PropTypes.bool,
    resetClientPresent: PropTypes.func,
};

export default AppointmentFields;