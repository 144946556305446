import React from 'react';

import PropTypes from 'prop-types';

/**
 * SignaturesTabs - Composant pour afficher les onglets des techniciens et du client.
 *
 * @param {number} technicianCount - Le nombre de techniciens.
 * @param {number} activeTab - L'onglet actif.
 * @param {function} handleTabChange - Fonction pour changer l'onglet actif.
 * @param {boolean} hasClient - Indique si le client est présent.
 */
const SignaturesTabs = ({ technicianCount, activeTab, handleTabChange, hasClient }) => {

    // Render the tabs
    return (
        <div className="tabs">
            {/* Render technician tabs */}
            {Array.from({ length: technicianCount }).map((_, index) => (
                <button
                    key={index}
                    className={`tab-button ${activeTab === index ? 'active' : ''}`}
                    onClick={() => handleTabChange(index)}
                >
                    Signature Technicien {index + 1}
                </button>
            ))}

            {/* Render client tab if client is present */}
            {hasClient && (
                <button
                    className={`tab-button ${activeTab === technicianCount ? 'active' : ''}`}
                    onClick={() => handleTabChange(technicianCount)}
                >
                    Signature Client
                </button>
            )}
        </div>
    );
};

// Define PropTypes for the SignaturesTabs component
SignaturesTabs.propTypes = {
    technicianCount: PropTypes.number.isRequired,
    activeTab: PropTypes.number.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    hasClient: PropTypes.bool.isRequired,
};

export default SignaturesTabs;
