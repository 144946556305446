import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Hook to access the current location

import PropTypes from 'prop-types'; // Import PropTypes for type validation

import FolderCopyOutlined from '@mui/icons-material/FolderCopyOutlined';

/**
 * ActionBar component responsible for rendering an action menu
 * with selectable items. Highlights the current active item based 
 * on the provided selectedIndex from the parent component.
 * 
 * @param {Array} items - Array of action items. Each item contains:
 *   - name: The name of the action item.
 *   - icon: An icon component to display.
 *   - action: A function that navigates to a specific route.
 *   - backgroundColor: Optional background color for the action item.
 * @param {Number} selectedIndex - Index of the currently selected item.
 */
const ActionBar = ({ items, selectedIndex }) => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    const [isMenuActive, setIsMenuActive] = useState(false); // Track if menu is active

    // Return null if no items are provided to avoid rendering an empty action bar
    if (!items || items.length === 0) {
        return null;
    }

    /**
     * Event handler for when an action item is clicked.
     * It triggers the action without modifying the selected index.
     * 
     * @param {Function} action - The function to navigate to the route.
     */
    const handleItemClick = (action) => {
        action(); // Trigger the action function for navigation
        setIsMenuActive(false); // Close the menu after clicking an item
    };

    /**
     * Toggles the menu visibility when the menu button is clicked.
     * It updates the state to show or hide the menu.
     * 
     * @returns {void}
     */
    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    // Render the action bar with clickable items
    return (
        <React.Fragment>

            { /* Menu button to toggle the action bar */ }
            <button className="action-bar-btn-menu" onClick={toggleMenu}>
                <FolderCopyOutlined /> {/* MUI Menu Icon */}
                <span>Dossier</span> {/* Display the folder number */}
            </button>

            { /* Action bar with clickable items */ }
            <div className={`action-bar ${isMenuActive ? 'active' : ''}`}>
                <span className="action-bar--title">DOSSIER N° {refFolder}</span> {/* Display the folder number */}
                {items.map((item, index) => (
                    <div 
                        key={index} 
                        className={`nav-item ${selectedIndex === index ? 'selected' : ''}`} // Highlight selected item
                        onClick={() => handleItemClick(item.action)} // Handle item click
                        role="button" // Indicate that this div acts as a button
                        tabIndex={0} // Make the div focusable with keyboard
                        onKeyDown={(e) => {
                            // Trigger action on Enter or Space key press
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleItemClick(item.action);
                            }
                        }}
                        style={{ backgroundColor: item.backgroundColor || 'transparent' }} // Set background color if provided
                    >
                        {/* Display the item's icon and label */}
                        <div className="nav-icon">{item.icon}</div> {/* Render icon */}
                        <div className="nav-label">{item.name}</div> {/* Render label */}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

// Define PropTypes for the ActionBar component to validate its props
ActionBar.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired, // Name is a required string
            icon: PropTypes.element.isRequired, // Icon is a required React element
            action: PropTypes.func.isRequired, // Action is a required function
            backgroundColor: PropTypes.string // Optional background color
        })
    ).isRequired, // Validate that items is a required array of objects
    selectedIndex: PropTypes.number.isRequired, // selectedIndex is a required number
};

export default ActionBar; // Export the ActionBar component