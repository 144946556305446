import React from 'react';

import PropTypes from 'prop-types';

import { Modal } from '@mui/material';
import SaveIcon from '@mui/icons-material/Check'; // Importation de l'icône de sauvegarde
import CloseIcon from '@mui/icons-material/Close'; // Importation de l'icône de fermeture

import CommonButton from '../buttons/common-button';

/**
 * CustomModal component that wraps Material-UI's Modal.
 *
 * @param {boolean} open - Determines if the modal is open.
 * @param {function} onClose - Function to call when the modal is closed.
 * @param {string} title - The title of the modal.
 * @param {React.ReactNode} children - The content to be displayed inside the modal.
 * @param {function} [handleSave] - Optional function to call when saving. 
 * @param {string} [onCloseLabel] - Optional label for the close button.
 * @param {string} [handleSaveLabel] - Optional label for the save button.
 * @param {boolean} [fullScreen] - Optional flag to make the modal full screen.
 * @param {boolean} [disableFooter] - Optional flag to disable the footer section.
 * @param {boolean} [hideSaveButton] - Optional flag to hide the save button.
 */
const CustomModal = ({
    open,
    onClose,
    title,
    children,
    handleSave,
    onCloseLabel = 'Annuler',
    handleSaveLabel = 'Sauvegarder',
    fullScreen = false,
    disableFooter = false, // Added disableFooter prop
    hideSaveButton = false, // Added hideSaveButton prop
}) => {

    // Render the CustomModal component
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={`${title}-description`}
        >
            <div className={`modal__wrapper ${fullScreen ? 'modal--fullscreen' : ''}`}>

                {/* Modal title */} 
                <h2 className="modal--title" id={title}>{title}</h2>

                {/* Modal content */ }
                <div className="modal__content">
                    {children}
                </div>

                {/* Modal footer */}
                {!disableFooter && ( // Conditionally render footer if not disabled
                    <div className="modal__footer">
                        <CommonButton
                            className="close"
                            onClick={onClose}
                            label={onCloseLabel}
                            square={true}
                            icon={<CloseIcon />}
                        />
                        {!hideSaveButton && ( // Conditionally render save button
                            <CommonButton
                                className="save"
                                onClick={handleSave}
                                label={handleSaveLabel}
                                square={true}
                                icon={<SaveIcon />}
                            />
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

// Define prop types for the CustomModal component
CustomModal.propTypes = {
    open: PropTypes.bool.isRequired, // Determines if the modal is open
    onClose: PropTypes.func.isRequired, // Function to call when closing the modal
    title: PropTypes.string.isRequired, // Title of the modal
    children: PropTypes.node.isRequired, // Content to be rendered inside the modal
    handleSave: PropTypes.func, // Optional save handler
    onCloseLabel: PropTypes.string, // Optional label for the close button
    handleSaveLabel: PropTypes.string, // Optional label for the save button
    fullScreen: PropTypes.bool, // Optional flag for full-screen modal
    disableFooter: PropTypes.bool, // Optional flag to disable footer
    hideSaveButton: PropTypes.bool, // Optional flag to hide the save button
};

export default CustomModal;
