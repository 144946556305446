import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import MediasService from '../../../../services/MediasService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * EditMediaGroupModal Component
 *
 * This component displays a modal for confirming the deletion of an media group.
 * It takes `open`, `onClose`, `groupId`, and `onEdit` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - groupId (number): The ID of the group to edit.
 * - onEdit: Function to edit the group.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const EditMediaGroupModal = ({ open, onClose, groupId, onEdit }) => {
    // State to handle the group data
    const [group, setGroup] = useState(null);
    const [groupName, setGroupName] = useState('');

    // State to handle loading and error messages
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    /**
     * Check if the group can be editd
     *
     * @returns {void}
     */
    const checkIfGroupCanBeEdit = async () => {
        try {
            setLoading(true); // Set loading state to true

            // Fetch the current group name
            const group = await MediasService.getMediaGroupById(groupId);

            // If the group does not exist, set an error message
            if (!group) {
                setError('Le groupe n\'existe pas.');
                return;
            }

            // Set the group name in state
            setGroup(group);
            setGroupName(group.name); // Set the group name in state
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Check if the group can be created
     * 
     * @returns {void}
     */
    const checkIfGroupDoesNotExist = async () => {
        if (!groupName || groupName.trim() === '') {
            // Return false immediately if groupName is empty or just spaces
            setLoading(false);
            return false;
        }

        if (groupName === group.name) {
            // If the group name has not changed, return true
            return true;
        }
    
        try {
            // Get the list of existing groups
            const existingGroups = await MediasService.getMediaGroups();
    
            // Check if the group name already exists in the list of existing groups
            const isGroupNameUnique = existingGroups.every(group => group.name !== groupName.trim());
    
            // Return the result based on uniqueness
            return isGroupNameUnique;
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
            return false; // Return false in case of error
        }
    }

    /**
     * Handles the deletion confirmation
     * 
     * @returns {void}
     */
    const handleConfirmEdit = async () => {
        if (!groupName.trim()) {
            console.error('Group name is required');
            setError('Le nom du groupe est requis.');
            return;
        }
    
        // Check if the group name is unique
        const isGroupNameUnique = await checkIfGroupDoesNotExist();
    
        if (!isGroupNameUnique) {
            console.error('Group name already exists');
            setError('Le nom du groupe existe déjà.');
            return;
        }
    
        if (groupId) {
            try {
                // Edit the group with the new group name
                await MediasService.editMediaGroup(groupId, { name: groupName });
    
                // Trigger the onEdit callback to refresh data
                onEdit();

                // Close the modal after the operation
                onClose();
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        } else {
            setError('Le groupe n\'existe pas.');
            return;
        }
    };    

    // Fetch media groups and categories on component mount
    useEffect(() => {
        checkIfGroupCanBeEdit();
    }, []);

    // If the modal is not open, return null
    if (!open, !groupId) return null;

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Modifier le groupe'}
            handleSave={handleConfirmEdit}
            handleSaveLabel={'Confirmer la modification'}
        >
            <div className='edit-media-group-modal'>
                { error && (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                )}

                { /* Warning message */ }
                <InfoBox
                    type="warning"
                    text={
                        <React.Fragment>
                            <p>Attention, si vous modifiez ce groupe, les modifications seront appliquées à toutes les catégories associées.</p>
                        </React.Fragment>
                    }
                    disabledCloseBtn={true}
                />

                { /* Group name input field */ }
                <div className="media-group--input">
                    <label>Nom du groupe</label>
                    <input
                        type="text"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        placeholder="Entrez un nom pour le groupe"
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for the EditMediaGroupModal component
EditMediaGroupModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    groupId: PropTypes.oneOfType([ 
        PropTypes.string,
        PropTypes.number
    ]),
    onEdit: PropTypes.func.isRequired,
};

export default EditMediaGroupModal;
