import React, { useState } from 'react'; // Import the useState hook
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

import { useApp } from '../modules/appModule';

import {
    AdminPanelSettings,
    Search,
    SettingsSuggest,
    Apps
} from '@mui/icons-material';

import SearchBar from '../components/forms/search-bar';

/**
 * Home Component
 * This component renders a button that navigates the user to the intervention page when clicked.
 */
const Home = () => {
    const navigate = useNavigate(); // Initialize the navigate hook
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false); // State to manage modal visibility
    const { version } = useApp();

    /**
     * handleSearchIntervention
     * Function to handle button click and open the search modal.
     * This function sets the isSearchModalOpen state to true.
     * This will open the search modal.
     * @returns {void}
     */
    const handleSearchIntervention = () => {
        setIsSearchModalOpen(true);
    };

    /**
     * handleGalleryAccess
     * Function to handle button click and navigate to the gallery page.
     */
    const handleGalleryAccess = () => {
        navigate('/internal-gallery');
    }

    /**
     * handleParametersAccess
     * Function to handle button click and navigate to the parameters page.
     */
    const handleParametersAccess = () => {
        navigate('/settings');
    }

    /**
     * handleAdminAccess
     * Function to handle button click and navigate to the admin page.
     */
    const handleAdminAccess = () => {
        navigate('/admin');
    }

    return (
        <React.Fragment>
            <div className="home-page">

                { /* Buttons for different actions */ }
                <div className="home-buttons">
                    <button 
                        className="search-intervention-btn"
                        onClick={handleSearchIntervention}
                        aria-label="Rechercher un dossier"
                    >
                        <Search />
                        Rechercher un dossier
                    </button>
                    <button
                        className="admin-access-btn"
                        onClick={handleAdminAccess}
                        aria-label="Accéder à l'interface d'administration"
                    >
                        <AdminPanelSettings />
                        Accéder à l'interface d'administration
                    </button>
                    <button
                        className="parameters-btn"
                        onClick={handleParametersAccess}
                        aria-label="Paramètres de l'application"
                    >
                        <SettingsSuggest />
                        Paramètres de l'application
                    </button>
                    <button
                        className="internal-gallery-btn"
                        onClick={handleGalleryAccess}
                        aria-label="Galerie interne de l'application"
                    >
                        <Apps />
                        Galerie interne de l'application
                    </button>
                </div>

                { /* Welcome message */ }
                <div className="welcome-message">
                    <div className="welcome-message-content">
                        <h1>Bienvenue</h1>
                        <p>Sur l'application de gestion des <strong>interventions</strong>.</p>
                        <p><strong>Utilisez les boutons ci-contre</strong> pour accéder aux différentes fonctionnalités de l'application.</p>
                    </div>
                </div>

                { /* App information */ }
                <div className="app-info">
                    <strong>Informations sur l'application</strong>
                    <p>{`Version de l'application: ${version}`}</p>
                </div>
            </div>

            {/* Search modal */}
            <SearchBar
                open={isSearchModalOpen}
                onClose={() => setIsSearchModalOpen(false)}
            />
        </React.Fragment>
    );
};

export default Home;
