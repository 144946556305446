import React from 'react'; // React and hooks for component lifecycle management
import { useParams, useNavigate } from 'react-router-dom'; // Hook to access the current location

import { useFolder } from '../../context/folder-context'; // Custom hook to use folder context

import Container from '../../components/blocks/container'; // Container component for layout
import PartsInfo from '../../components/folder-components/folder--parts-info';
import NavigateFooter from '../../components/folder-components/folder--navigate-footer';

/**
 * FolderPartsPage Component
 * 
 * This component displays the spare parts information for a folder.
 * It also provides navigation to other parts of the folder.
 * @returns {JSX.Element} - Rendered component
 */
const FolderPartsPage = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate

    const { title, color } = useFolder(); // Get the title and color from the folder context

    // Render the folder parts page with fetched data
    return (
        <Container
            className="folder-container"
            title={title || ''}
            titleBackground={color || 'transparent'}
        >
            {/* Spare Parts Section */}
            <PartsInfo
                selectable={true}
            />

            {/* Navigation footer */}
            <NavigateFooter
                onBack={() => navigate(`/folders/${refFolder}/media-upload-tool`)}
                onNext={() => navigate(`/folders/${refFolder}/payments-and-signature`)}
            />
        </Container>
    );
};

export default FolderPartsPage; // Export the component for use in other parts of the application