import React, { createContext, useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types'; // Import PropTypes for type validation

import { Preferences } from '@capacitor/preferences'; // Importing Preferences from Capacitor

// Create the context for the gallery
const GalleryContext = createContext();

/**
 * GalleryProvider component: Provides the gallery state to its children.
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * 
 * This provider manages user preferences related to gallery settings,
 * including timestamp and geolocation preferences. It uses Capacitor Preferences 
 * for persistent storage and local storage as a fallback.
 */
export const GalleryProvider = ({ children }) => {
    // State variable for timestamp and geolocation preferences
    const [timestamp, setTimestamp] = useState(() => {
        // Retrieve stored preference from localStorage or default to false
        const storedTimestamp = localStorage.getItem('timestamp');
        return storedTimestamp === 'true' || storedTimestamp === null; // Convert to boolean
    });

    const [geolocation, setGeolocation] = useState(() => {
        // Retrieve stored preference from localStorage or default to false
        const storedGeolocation = localStorage.getItem('geolocation');
        return storedGeolocation === 'true' || storedGeolocation === null; // Convert to boolean
    });

    /**
     * Function to apply stored preferences to the state on mount.
     * This function retrieves the stored preferences from Capacitor and updates the state.
     * @returns {void}
     */
    const applyPreferences = async () => {
        try {
            // Get the stored preferences from Capacitor
            const storedTimestamp = await Preferences.get({ key: 'timestamp' });
            const storedGeolocation = await Preferences.get({ key: 'geolocation' });
    
            // Update state based on stored preferences, defaulting to false if null
            setTimestamp(storedTimestamp.value === 'true' || storedTimestamp.value === null);
            setGeolocation(storedGeolocation.value === 'true' || storedGeolocation.value === null);
        } catch (error) {
            console.error('Error applying preferences:', error);
        }
    };

    /**
     * Function to save the current preferences to Capacitor and localStorage.
     * This function saves the current state of the preferences to Capacitor and localStorage.
     * @returns {void}
     */
    const savePreferences = async () => {
        try {
            const timestampValue = String(timestamp); // Convert boolean to string
            const geolocationValue = String(geolocation); // Convert boolean to string

            // Save the preferences to Capacitor Preferences and localStorage
            await Preferences.set({ key: 'timestamp', value: timestampValue });
            localStorage.setItem('timestamp', timestampValue);

            await Preferences.set({ key: 'geolocation', value: geolocationValue });
            localStorage.setItem('geolocation', geolocationValue);
        } catch (error) {
            console.error('Error saving preferences:', error);
        }
    };

    // useEffect to apply preferences from Capacitor on mount
    useEffect(() => {
        applyPreferences(); // Call the async function to apply preferences
    }, []); // Run once when the component mounts

    // useEffect to save preferences when state changes
    useEffect(() => {
        savePreferences(); // Call the async function to save preferences
    }, [timestamp, geolocation]); // Runs when timestamp or geolocation changes

    // Function to toggle timestamp preference
    const toggleTimestamp = () => setTimestamp((prev) => !prev);

    // Function to toggle geolocation preference
    const toggleGeolocation = () => setGeolocation((prev) => !prev);

    // Provide the current state and toggle functions to the context consumers
    return (
        <GalleryContext.Provider value={{
            timestamp, // Current preference for showing timestamps
            geolocation, // Current preference for using geolocation
            toggleTimestamp, // Function to toggle the timestamp preference
            toggleGeolocation, // Function to toggle the geolocation preference
        }}>
            {children} {/* Render child components */}
        </GalleryContext.Provider>
    );
};

/**
 * Custom hook to use the GalleryContext in other components.
 * This hook provides easy access to the gallery state and management functions.
 * 
 * @returns {Object} - Returns the current gallery state and functions to manage it.
 * 
 * Usage:
 * const { timestamp, geolocation, toggleTimestamp, toggleGeolocation } = useGallery();
 */
export const useGallery = () => useContext(GalleryContext);

// Define PropTypes for better validation and debugging during development
GalleryProvider.propTypes = {
    children: PropTypes.node.isRequired, // children are required to wrap the provider
};