import React, { useState, useEffect } from 'react';

import {
    Edit,
    Add,
    VisibilityOutlined
} from '@mui/icons-material';

import Container from '../../blocks/container';
import CommonButton from '../../buttons/common-button';
import InfoBox from '../../blocks/messages/info-box';

import ViewUserModal from './modals/modal--admin--show-user';
import EditUserModal from './modals/modal--admin--edit-user';
import CreateUserModal from './modals/modal--admin--create-user';

import UsersService from '../../../services/UsersService';

/**
 * UsersTable Component
 * 
 * This component renders a table of users and allows the user to manage them.
 * @returns {JSX.Element}
 */
const UsersTable = () => {
    
    // State to handle modal display and loading state and error messages
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State to hold users data
    const [users, setUsers] = useState([]);

    // State for table search
    const [search, setSearch] = useState(""); // State for search query
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' }); // Default sort by name

    /**
     * Fetch users
     * Function to fetch users from the API
     * and update the state with the results.
     * @returns {Promise<void>}
     */
    const fetchUsers = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch users from the API
            const users = await UsersService.getUsers();

            // Set the users state
            setUsers(users);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch users on component mount
    useEffect(() => {
        fetchUsers();
    }, []);

    // Display loading message while fetching data
    if (loading) {
        return (
            <React.Fragment>
                Chargement...
            </React.Fragment>
        );
    }

    // Display an error message if the data could not be loaded
    if (error) {
        return (
            <InfoBox
                type={'error'}
                disabledCloseBtn={true}
                text={error}
            />
        );
    }

    // Filter users by search term (name or device identifier)
    const filteredUsers = users.filter(user => {
        const searchTerm = search.toLowerCase();
        const fullName = `${user.last_name} ${user.first_name}`.toLowerCase();
        return fullName.includes(searchTerm);
    });

    // Sort users by column
    const sortedUsers = filteredUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    // Calculate the indexes for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentUsers = sortedUsers.slice(startIndex, endIndex);

    // Pagination buttons
    const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);

    // Handle pagination controls
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getPageRange = () => {
        const range = [];
        const start = Math.max(1, currentPage - 1);
        const end = Math.min(totalPages, currentPage + 1);

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        return range;
    };

    const handleShow = (userId) => {
        setModal({ type: 'show', userId: userId });
    };

    // Handle edit action
    const handleEdit = (userId) => {
        setModal({ type: 'edit', userId: userId });
    };

    // Handle add action
    const handleAdd = () => {
        setModal({ type: 'add' });
    };

    // Handle sorting
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
    };

    // Render the users table
    return (
        <React.Fragment>
            <Container className="admin-users-manager auto-height">
                <h2>Gestion des utilisateurs</h2>

                {users.length > 0 ? (
                    <div className="users-list">

                        {/* Search input */}
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Rechercher par nom/prénom"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>

                        {/* Users table */}
                        { filteredUsers.length > 0 ? (
                            <React.Fragment>
                                <table className="users-table">
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('name')}>
                                                Nom / Prénom 
                                                {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                                            </th>
                                            <th onClick={() => handleSort('last_login')}>
                                                Dernière connexion
                                                {sortConfig.key === 'last_login' && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentUsers.map((user) => (
                                            <tr key={user.id}>
                                                <td>{user.last_name} {user.first_name}</td>
                                                <td>{user.last_login ? new Date(user.last_login).toLocaleString() : '-'}</td>
                                                <td>
                                                    <button
                                                        onClick={() => handleShow(user.id)}
                                                        className="users-table-btn"
                                                    >
                                                        <VisibilityOutlined />
                                                    </button>
                                                    <button
                                                        onClick={() => handleEdit(user.id)}
                                                        className="users-table-btn"
                                                    >
                                                        <Edit />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <div className="pagination">
                                    <button
                                        onClick={() => setCurrentPage(1)}
                                        disabled={currentPage === 1}
                                        className="first pagination-btn"
                                    >
                                        Première
                                    </button>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className="back pagination-btn"
                                    >
                                        Précédent
                                    </button>
                                    {getPageRange().map((page) => (
                                        <button
                                            key={page}
                                            onClick={() => setCurrentPage(page)}
                                            className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                                        >
                                            {page}
                                        </button>
                                    ))}
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className="next pagination-btn"
                                    >
                                        Suivant
                                    </button>
                                    <button
                                        onClick={() => setCurrentPage(totalPages)}
                                        disabled={currentPage === totalPages}
                                        className="last pagination-btn"
                                    >
                                        Dernière
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="no-users">
                                <p>Aucun utilisateur trouvé.</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <p>Aucun utilisateur trouvé.</p>
                )}
                <CommonButton
                    label="Ajouter un utilisateur"
                    icon={<Add />}
                    onClick={handleAdd}
                />
            </Container>

            {modal && modal.type === 'show' && (
                <ViewUserModal open={true} onClose={() => setModal(null)} userId={modal.userId} />
            )}

            {modal && modal.type === 'edit' && (
                <EditUserModal open={true} onClose={() => setModal(null)} userId={modal.userId} onSave={() => fetchUsers()} />
            )}

            {modal && modal.type === 'add' && (
                <CreateUserModal open={true} onClose={() => setModal(null)} onSave={() => fetchUsers()} />
            )}
        </React.Fragment>
    );
};

export default UsersTable;
