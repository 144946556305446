import React from 'react';

import InfoBox from '../../../blocks/messages/info-box';

/**
 * ConfirmClientAbsent Component
 * 
 * This component displays a confirmation message to confirm the client absence for an appointment.
 * @returns {JSX.Element} - Rendered component
 */
const ConfirmClientAbsent = () => {
    // Get the current date and time
    const today = new Date();

    // Format the date and time
    const formattedDateTime = `${today.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    })}`;

    // Render the confirmation message
    return (
        <InfoBox
            text={
                <React.Fragment>
                    <p>Voulez-vous vraiment marquer le client absent pour l'intervention du</p>
                    <strong>{formattedDateTime}</strong> ?
                </React.Fragment>
            }
            disabledCloseBtn={true}
        />
    );
};

export default ConfirmClientAbsent;