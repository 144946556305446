import React from 'react';

import PropTypes from 'prop-types';

import CommonButton from '../../../buttons/common-button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UndoIcon from '@mui/icons-material/Undo';
import SendIcon from '@mui/icons-material/Send';

/**
 * NavigationButtons component provides navigation options (Cancel, Previous, Next, Save).
 * @param {number} activeTab - Current active tab index
 * @param {number} technicianCount - Number of technician steps
 * @param {boolean} hasClient - Indicates if client step is included
 * @param {function} handlePrev - Handler for previous action
 * @param {function} handleNext - Handler for next action
 * @param {function} handleSave - Handler for save action
 * @param {function} onClose - Handler to close/cancel
 * @returns {JSX.Element} - Rendered button components
 */
const NavigationButtons = ({
    activeTab,
    technicianCount,
    hasClient,
    handlePrev,
    handleNext,
    handleSave,
    onClose 
}) => {

    // Render the navigation buttons
    return (
        <div className="navigation-buttons">
            
            {/* Cancel button - triggers the onClose function */}
            <div className="cancel-button">
                <CommonButton
                    label="Annuler"
                    onClick={onClose}
                    square={true}
                    className={'cancel'}
                    icon={<UndoIcon />}
                />
            </div>

            {/* Navigation buttons - Previous and Next or Save based on active tab */}
            <div className="next-prev-buttons">
                {activeTab > 0 && (
                    <CommonButton
                        label="Précédent"
                        onClick={handlePrev}
                        square={true}
                        className={'back'}
                        icon={<ArrowBackIcon />}
                    />
                )}
                {activeTab < technicianCount + (hasClient ? 1 : 0) - 1 ? (
                    <CommonButton
                        label="Suivant"
                        onClick={handleNext}
                        square={true}
                        className={'next'}
                        icon={<ArrowForwardIcon />}
                    />
                ) : (
                    <CommonButton
                        label="Prévisualiser et confirmer"
                        onClick={handleSave}
                        square={true}
                        className={'save'}
                        icon={<SendIcon />}
                    />
                )}
            </div>
        </div>
    );
};

// Prop types for NavigationButtons component
NavigationButtons.propTypes = {
    activeTab: PropTypes.number.isRequired,
    technicianCount: PropTypes.number.isRequired,
    hasClient: PropTypes.bool.isRequired,
    handlePrev: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default NavigationButtons;