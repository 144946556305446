import React, { createContext, useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types'; // Import PropTypes for type validation

import { Preferences } from '@capacitor/preferences'; // Importing Preferences from Capacitor

// Create a ThemeContext to manage theme state across the application
const ThemeContext = createContext();

/**
 * ThemeProvider component: Provides the theme state to its children.
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * 
 * This provider manages the application theme state, allowing components 
 * to toggle between dark and light themes while persisting the preference.
 */
export const ThemeProvider = ({ children }) => {
    // State variable to hold the theme status (dark or light)
    const [isDarkTheme, setIsDarkTheme] = useState(() => {
        // Initial state from localStorage or default to light theme
        const storedTheme = localStorage.getItem('theme');
        return storedTheme === 'dark'; // Return true for dark theme or false for light
    });

    /**
     * useEffect hook to apply the theme on mount and update the document's root element class.
     */
    useEffect(() => {
        const root = document.documentElement; // Get the root element of the document
        if (isDarkTheme) {
            root.classList.add('dark-theme'); // Add dark theme class
            root.classList.remove('light-theme'); // Remove light theme class
        } else {
            root.classList.add('light-theme'); // Add light theme class
            root.classList.remove('dark-theme'); // Remove dark theme class
        }
        
        // Save the current theme preference to Capacitor Preferences and localStorage
        const themeValue = isDarkTheme ? 'dark' : 'light';
        Preferences.set({ key: 'theme', value: themeValue }); // Save to Capacitor Preferences
        localStorage.setItem('theme', themeValue); // Save to localStorage
    }, [isDarkTheme]); // Runs when isDarkTheme changes

    /**
     * Function to toggle the theme between dark and light.
     */
    const toggleTheme = () => {
        setIsDarkTheme((prev) => !prev); // Toggle the theme
    };

    // Provide the theme state and toggle function to children components
    return (
        <ThemeContext.Provider value={{
            isDarkTheme, // Current theme status
            toggleTheme, // Function to toggle the theme
        }}>
            {children} {/* Render child components wrapped in ThemeContext provider */}
        </ThemeContext.Provider>
    );
};

/**
 * Custom hook to use the ThemeContext in other components.
 * @returns {Object} - Returns the current theme status and the function to set the theme.
 */
export const useTheme = () => useContext(ThemeContext);

// Define PropTypes for better validation and debugging during development
ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired, // children are required to wrap the provider
};