import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AppointmentsService from '../../../../services/AppointmentsService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * EditAppointmentActionModal Component
 *
 * This component displays a modal for editing an appointment action.
 * It takes `open`, `onClose`, `actionId`, and `onEdit` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose (function): Function to close the modal.
 * - actionId (number): The ID of the action to edit.
 * - onEdit (function): Function to handle the editing logic.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const EditAppointmentActionModal = ({ open, onClose, actionId, onEdit }) => {
    // State to handle loading and error messages
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // State to handle action name
    const [actionName, setActionName] = useState('');

    /**
     * Fetch the current action details
     * 
     * @returns {void}
     */
    const fetchActionDetails = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the current action details
            const action = await AppointmentsService.getAppointmentActionById(actionId);

            // Set the action name in state
            setActionName(action.name);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    /**
     * Handles the edit confirmation
     * 
     * @returns {void}
     */
    const handleConfirmEdit = async () => {
        if (!actionName.trim()) {
            console.error('Action name is required');
            setError('Le nom de l\'action est requis.');
            return;
        }

        try {
            // Edit the action
            await AppointmentsService.editAppointmentAction(actionId, { name: actionName });

            // Trigger the onEdit callback
            onEdit();

            // Close the modal after editing
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // Fetch action details on component mount
    useEffect(() => {
        fetchActionDetails();
    }, []);

    // If the modal is not open, return null
    if (!open || !actionId) return null;

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Modifier l\'action de rendez-vous'}
            handleSave={handleConfirmEdit}
            handleSaveLabel={'Confirmer la modification'}
        >
            <div className="edit-appointment-action-modal">
                {error && (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                )}
                <InfoBox
                    type="warning"
                    text={
                        <React.Fragment>
                            <p>Attention, modifier cette action peut affecter d'autres entités dépendantes.</p>
                        </React.Fragment>
                    }
                    disabledCloseBtn={true}
                />
                <div className="appointment-action--input">
                    <label>Nom de l'action</label>
                    <input
                        type="text"
                        value={actionName}
                        onChange={(e) => setActionName(e.target.value)}
                        placeholder="Entrez un nom pour l'action"
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for the EditAppointmentActionModal component
EditAppointmentActionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    actionId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onEdit: PropTypes.func.isRequired,
};

export default EditAppointmentActionModal;