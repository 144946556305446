// src/services/api-service.jsx
import axios from 'axios';

import { Preferences } from '@capacitor/preferences';

import CONFIG from '../config/config';

/**
 * Abstract class for making API requests.
 */
class ApiService {

    constructor() {
        this.api = axios.create({
            baseURL: CONFIG.BASE_URL, // Base URL for the API requests
            timeout: 10000, // Timeout set to 10 seconds for requests
            headers: {
                'Content-Type': 'application/json', // Specifies that the request body format is JSON
                'Accept': 'application/json', // Indicates that the response format should be JSON
            },
        });
    }

    /**
     * Links the device to the user using the session token.
     * 
     * @param {string} session_token - The session token to use for linking
     * @param {string} device_identifier - The unique identifier of the device
     * 
     * @returns {Promise<Object>} A promise that resolves to the response data
     * @throws {Error} Throws an error if the request fails
     */
    async linkDeviceToUser(session_token, device_identifier) {
        try {
            // Make a request to link the device to the user
            const linkDevicePayload = {
                session_token: session_token,
                device_identifier: device_identifier,
            };

            // Send the link device request
            const response = await this.api.post('/user/link-device', linkDevicePayload);
            return response.data;
        } catch (error) {
            // Handle errors
            console.error('Error linking device to user:', error);
            throw new Error('Une erreur est survenue lors de la liaison de l\'appareil à l\'utilisateur.');
        }
    }

    /**
     * Logs the user in using the session token.
     * @returns {Promise<Object>} A promise that resolves to the user data
     * 
     * @param {string} session_token - The session token to use for login
     * @param {string} device_identifier - The unique identifier of the device
     * 
     * @returns {Promise<Object>} A promise that resolves to the response data
     * @throws {Error} Throws an error if the request fails
     */
    async login(session_token, device_identifier) {

        try {
            // Check if the session token is available
            if (!session_token) {
                console.error('No session token provided.');
                throw new Error('Aucun token de session fourni.');
            }

            // Make a request to login
            const loginPayload = {
                username: session_token,
                password: session_token,
                device_identifier: device_identifier,
            };

            // Send the login request
            const response = await this.api.post('/login_check', loginPayload);
            const { token } = response.data;

            // Store the session and JWT tokens
            await this.setSessionToken(session_token);
            await this.setJwtToken(token);
            await this.setDeviceIdentifier(device_identifier);

            window.location.reload(); // Reload the page to apply the new tokens

            return response.data; // Return the entire response for further use
        } catch (error) {
            if (error.response?.status === 401 && 
                this.extractErrorMessage(error) === "Cet utilisateur n'a pas encore d'appareil lié, veuillez passer par une liaison d'appareil.") {
                
                // Handle no device linked
                try {
                    // Link the device to the user
                    await this.linkDeviceToUser(session_token, device_identifier);

                    // Retry the login
                    return this.login(session_token, device_identifier);

                } catch (linkError) {
                    // Handle link error
                    console.error('Error linking device to user:', linkError);
                    throw new Error('Une erreur est survenue lors de la liaison de l\'appareil à l\'utilisateur.');
                }
            } else if (error.response?.status === 401 && this.extractErrorMessage(error) === "Invalid device identifier.") {
                // Handle invalid device identifier
                console.error('Invalid device identifier:', error);
                throw new Error('L\'utilisateur est déjà lié à un autre appareil. Veuillez contacter un administrateur.');
            } else {
                // Handle other errors
                console.error('Error logging in:', error);
                throw new Error(this.extractErrorMessage(error, 'Une erreur est survenue lors de la connexion.'));
            }
        }
    }

    /**
     * Refreshes the JWT token using the session token and device identifier.
     * @returns {Promise<Object>} A promise that resolves to the response data
     * @throws {Error} Throws an error if the request fails
     */
    async refreshJWTToken() {
        try {
            const session_token = await this.getSessionToken();
            const device_identifier = await this.getDeviceIdentifier();

            if (!session_token || !device_identifier) {
                console.error('No session token or device identifier available.');
                throw new Error('Aucun token de session ou identifiant d\'appareil disponible.');
            }

            // Make a request to refresh the JWT token
            const refreshPayload = {
                session_token: session_token,
                device_identifier: device_identifier,
            };

            // Send the refresh token request
            const response = await this.api.post('/refresh_token', refreshPayload);
            const { token } = response.data;

            // Store the JWT token
            await this.setJwtToken(token);

            return response.data; // Return the entire response for further use
        } catch (error) {
            // Handle errors
            console.error('Error refreshing JWT token:', error);
            throw new Error('Une erreur est survenue lors du rafraîchissement du token JWT.');
        }
    }

    /**
     * Updates the API instance with the session_token for authenticated requests.
     */
    async setSessionToken(session_token) {
        await Preferences.set({ key: 'sessionToken', value: session_token }); // Store securely
    }

    /**
     * Updates the API instance with the JWT token for subsequent requests.
     */
    async setJwtToken(jwt_token) {
        await Preferences.set({ key: 'jwtToken', value: jwt_token }); // Store securely
    }

    /**
     * Updates the API instance with the device identifier for subsequent requests.
     */
    async setDeviceIdentifier(device_identifier) {
        await Preferences.set({ key: 'deviceIdentifier', value: device_identifier }); // Store securely
    }

    /**
     * Retrieve the stored session token.
     */
    async getSessionToken() {
        const { value } = await Preferences.get({ key: 'sessionToken' });
        return value;
    }

    /**
     * Retrieve the stored JWT token.
     */
    async getJwtToken() {
        const { value } = await Preferences.get({ key: 'jwtToken' });
        return value;
    }

    /**
     * Retrieve the stored device identifier.
     */
    async getDeviceIdentifier() {
        const { value } = await Preferences.get({ key: 'deviceIdentifier' });
        return value;
    }

    /**
     * Clear the stored session and JWT tokens.
     * Useful for logging out the user.
     * @returns {Promise<void>} A promise that resolves when the tokens are cleared
     */
    async resetStorageSession() {
        await Preferences.clear(); // Clear all stored preferences

        window.location.reload(); // Reload the page to apply the new tokens
    }

    /**
     * Generic request method.
     */
    async request(method, url, data = null, isFileDownload = false) {
        try {
            // Check if a token is available and set it in the headers
            const token = await this.getJwtToken();
            if (token) {
                this.api.defaults.headers['Authorization'] = `Bearer ${token}`;
            }

            // Prepare the request configuration
            const config = {
                method,
                url,
                data,
                responseType: isFileDownload ? 'blob' : 'json'
            };

            // Make the request
            const response = await this.api.request(config);

            // Check if the request is for downloading a file
            if (isFileDownload) {
                return response.data;
            }

            return response.data;
        } catch (error) {
            if (error.response?.status === 401 && error.response?.data?.message === "Expired JWT Token") {
                try {
                    await this.refreshJWTToken(); // Refresh the token
                    // Retry the request
                    return this.request(method, url, data);
                } catch (loginError) {
                    // Handle login error
                    console.error('Error refreshing JWT token:', loginError);
                    throw new Error(this.extractErrorMessage(loginError, 'Votre session a expiré. Veuillez vous reconnecter.'));
                }
            } else if (error.response?.status === 401 && error.response?.data?.message === "Invalid credentials.") {
                // Handle invalid credentials
                console.error('Invalid credentials:', error);
                this.resetStorageSession();
                throw new Error(this.extractErrorMessage(error, 'Vos identifiants sont invalides. Veuillez vous reconnecter.'));
            } else {
                // Other errors
                console.error('Error making request:', error);
                throw new Error(this.extractErrorMessage(error, 'Une erreur est survenue lors de la requête.'));
            }        
        }
    }

    /**
     * Extracts a meaningful error message from various error structures.
     * @param {any} error - The error object to process.
     * @returns {string} - The extracted error message.
     */
    extractErrorMessage(error, customErrorMessage = null) {
        // Extract the error message from the error object
        if (typeof error === 'string') {
            return error; // If error is directly a string, return it
        }
    
        // Extract the error message from the response data
        if (typeof error.response?.data === 'string') {
            return customErrorMessage || 'Une erreur est survenue sur la route demandée.'; // Custom message if data is a string
        }
    
        // Extract the error message from the response data
        return (
            error.response?.data?.message ||       // Server-provided message
            error.response?.data?.erreur ||        // Alternative error field
            error.response?.data?.error ||         // Common error field
            customErrorMessage ||                  // Custom fallback message
            'Une erreur est survenue.'             // Default fallback message
        );
    }
}

export default ApiService;