import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom'; // Hook to access the current location

import FoldersService from '../../services/FoldersService';

import InfoBox from '../blocks/messages/info-box';

/**
 * FolderInfo Component
 * 
 * This component displays the folder information, including creation date, last modified date,
 * payer, reference, and ST. If no folder information is available, a fallback message is displayed.
 * 
 */
const FolderInfo = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    // State to hold warranty data
    const [data, setData] = useState(null);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Fetches the folder information.
     * Sets loading state and handles errors.
     * 
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // Set the warranty data
            setData(folder);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            // Set loading state to false
            setLoading(false);
        }
    }

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // Display loading message while fetching data
    if (loading) {
        return (
            <div className="folder-section folder-info">
                <h2>Informations Dossier</h2>
                Chargement...
            </div>
        )
    }

    // Display error message if an error occurred
    if (error) {
        return (
            <div className="folder-section folder-info">
                <h2>Informations Dossier</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        )
    }

    // Display the folder information
    if (!data) {
        return (
            <div className="folder-section folder-info">
                <h2>Informations Dossier</h2>
                Aucune information disponible
            </div>
        )
    }

    /**
     * Formats the date to a locale string.
     * 
     * @param {string} date - The date to format
     * @returns {string} The formatted date
     */
    const formattedDate = (date) => {
        // Options for the date format
        const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        // Return the formatted date
        return new Date(date).toLocaleDateString('fr-FR', options);
    }

    // Return the folder information
    return (
        <div className="folder-section folder-info">
            <h2>Informations Dossier</h2>
            <div className="folder-info-details">
                <div><strong>Date de création:</strong> {formattedDate(data?.created_at)}</div>
                <div><strong>Dernière modification:</strong> {formattedDate(data?.updated_at)}</div>
                <div><strong>Référence:</strong> {data?.reference || "N/A"}</div>
                <div><strong>ST:</strong> {data?.ST || "N/A"}</div>
            </div>
        </div>
    );
};

export default FolderInfo;