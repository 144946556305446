import React, { useState, useEffect } from 'react';

import {
    Edit,
    DeleteOutline,
    Add
} from '@mui/icons-material';

import Container from '../../blocks/container';
import CommonButton from '../../buttons/common-button';
import MediasService from '../../../services/MediasService';
import InfoBox from '../../blocks/messages/info-box';

import DeleteMediaCategoryModal from './modals/modal--admin--delete-media-category';
import EditMediaCategoryModal from './modals/modal--admin--edit-media-catogory';
import CreateMediaCategoryModal from './modals/modal--admin--create-media-category';

/**
 * MediaCategoriesTable Component
 * 
 * @param {object} refreshKey - Key to refresh the component
 * @param {object} refreshData - Function to refresh the parent component
 *
 * This component renders a table of media categories and allows the user to manage them.
 * @returns {JSX.Element}
 */
const MediaCategoriesTable = ({ refreshKey, refreshData }) => {
    // Initialize state variables
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State to hold media categories
    const [mediaCategories, setMediaCategories] = useState([]);

    // State to handle pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    /**
     * Fetch media categories
     * Function to fetch media categories from the API
     * and update the state with the results.
     * @returns {Promise<void>}
     */
    const fetchMediaCategories = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch media categories
            const mediaCategories = await MediasService.getMediaCategories();

            // Update media categories state
            setMediaCategories(mediaCategories);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch media categories on component mount
    useEffect(() => {
        fetchMediaCategories();
    }, []);

    // Fetch media categories on refresh
    useEffect(() => {
        fetchMediaCategories();
    }, [refreshKey]);

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    // Display an error message if the data could not be loaded
    if (error) {
        return (
            <InfoBox
                type={'error'}
                disabledCloseBtn={true}
                text={error}
            />
        );
    }

    // Calculate the indexes for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentCategories = mediaCategories.slice(startIndex, endIndex);

    // Pagination buttons
    const totalPages = Math.ceil(mediaCategories.length / itemsPerPage);

    // Handle pagination controls
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    // Handle pagination controls
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    // Get range of pages to display in pagination
    const getPageRange = () => {
        const range = [];
        const start = Math.max(1, currentPage - 1);
        const end = Math.min(totalPages, currentPage + 1);

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        return range;
    };

    // Handle delete action
    const handleDelete = (categoryId) => {
        setModal({type: 'delete', categoryId: categoryId});
    }

    // Handle edit action
    const handleEdit = (categoryId) => {
        setModal({type: 'edit', categoryId: categoryId});
    }

    // Handle add action
    const handleAdd = () => {
        setModal({type: 'add'});
    }

    // Render the component
    return (
        <React.Fragment>
            <Container className="admin-medias-manager auto-height">
                <h2>Gestion des catégories de médias</h2>
                {mediaCategories.length > 0 ? (
                    <div className="media-categories-list">

                        {/* Media categories table */}
                        <table className="media-table">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Suffixe</th>
                                    <th>Groupe associé</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCategories.map((category) => (
                                    <tr key={category.id}>
                                        <td>{category.name}</td>
                                        <td>{category.qualification_suffix}</td>
                                        <td>{category.media_group_category?.name || 'Aucun groupe'}</td>
                                        <td>
                                            <button
                                                onClick={() => handleEdit(category.id)}
                                                className="media-table-btn"
                                            >
                                                <Edit />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(category.id)}
                                                className="media-table-btn"
                                            >
                                                <DeleteOutline />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Pagination controls */}
                        <div className="pagination">
                            <button
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                                className='first pagination-btn'
                            >
                                Première
                            </button>
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className='back pagination-btn'
                            >
                                Précédent
                            </button>
                            {getPageRange().map((page) => (
                                <button
                                    key={page}
                                    onClick={() => setCurrentPage(page)}
                                    className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                                >
                                    {page}
                                </button>
                            ))}
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className='next pagination-btn'
                            >
                                Suivant
                            </button>
                            <button
                                onClick={() => setCurrentPage(totalPages)}
                                disabled={currentPage === totalPages}
                                className='last pagination-btn'
                            >
                                Dernière
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className="no-data">Aucune catégorie de médias trouvée.</p>
                )}
                <CommonButton
                    label="Ajouter une catégorie"
                    icon={<Add />}
                    onClick={() => handleAdd()}
                />
            </Container>

            {modal && modal.type === 'delete' && (
                <DeleteMediaCategoryModal open={true} onClose={() => setModal(null)} categoryId={modal.categoryId} onDelete={() => refreshData()} />
            )}

            {modal && modal.type === 'edit' && (
                <EditMediaCategoryModal open={true} onClose={() => setModal(null)} categoryId={modal.categoryId} onEdit={() => refreshData()} />
            )}

            {modal && modal.type === 'add' && (
                <CreateMediaCategoryModal open={true} onClose={() => setModal(null)} onCreate={() => refreshData()} />
            )}
        </React.Fragment>
    );
};

export default MediaCategoriesTable;