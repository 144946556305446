import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import AppointmentFields from '../components/folder--appointment-fields';
import ConfirmClientAbsent from '../components/folder--appointment-confirm-client-absent';
import ClientSelection from '../components/folder--appointment-client-selection';
import InfoBox from '../../../blocks/messages/info-box';

import AppointmentsService from '../../../../services/AppointmentsService';

import CustomModal from '../../../blocks/custom-modal';

/**
 * CreateAppointmentModal Component
 * 
 * This component displays a modal for editing or creating an appointment.
 * It takes open, onClose, appointmentId, folderId, and onSave as props.
 * 
 * Props:
 * - open (boolean): A boolean to control the modal visibility.
 * - onClose: A function to close the modal.
 * - onSave: A function to save the appointment data.
 * - folderId (number): The folder ID associated with the appointment.
 * - qualiReparAllowed (boolean): A boolean to allow the QualiRepar fields.
 * 
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const CreateAppointmentModal = ({ open, onClose, onSave, folderId, qualiReparAllowed = false }) => {
    // State to handle loading state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // State to handle client presence
    const [confirmClientAbsent, setConfirmClientAbsent] = useState(false);
    const [confirmClientPresent, setConfirmClientPresent] = useState(false);

    // State to handle appointment data and actions
    const [appointmentData, setAppointmentData] = useState(null);
    const [appointmentActions, setAppointmentActions] = useState([]);

    /**
     * Fetches the actions data from the API.
     * 
     * @returns {void}
     * @throws {Error} Throws an error if the data fetching fails.
     */
    const fetchActionsData = async () => {
        try {
            // Update loading state
            setLoading(true);

            // Fetch all actions
            const actions = await AppointmentsService.getAppointmentActions();

            if (!actions) return;

            // Set the actions state
            setAppointmentActions(actions.map(action => ({
                id: action.id,
                name: action.name,
                isActive: false,
            })));
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            // Update loading state
            setLoading(false);
        }
    }

    /**
     * Fetches the initial data for the appointment.
     * 
     * @returns {void}
     * @throws {Error} Throws an error if the data fetching fails.
     */
    const fetchInitialData = async () => {
        try {
            // Update loading state
            setLoading(true);

            // Set the initial appointment data
            setAppointmentData({
                comments: '',
                actions: [],
                is_quali_repar: false,
                quali_repar_amount: 0,
            });
        } catch {
            throw new Error('Une erreur s\'est produite lors de la récupération des données initiales.');
        } finally {
            // Update loading state
            setLoading(false);
        }
    }

    // Fetch appointment data on mount
    useEffect(() => {
        const initializeData = async () => {
            try {
                await fetchActionsData(); // Wait for actions data to be fetched
                await fetchInitialData(); // Then fetch initial data
            } catch (error) {
                console.error(error.message);
            }
        };
        initializeData();
    }, []);

    /**
     * Handles saving the appointment data and closing the modal.
     * If the appointmentId is provided, it will edit the appointment.
     * Otherwise, it will create a new appointment.
     * 
     * @returns {void}
     */
    const handleSave = async () => {
        try {
            // Get the selected actions
            const selectedActions = appointmentActions
                .filter(action => action.isActive)
                .map(action => action.id);

            // Common appointment data
            let payload = {};
            if (confirmClientPresent) {
                payload = {
                    id_folder: folderId,
                    comments: appointmentData?.comments,
                    is_client_present: true,
                    is_quali_repar: appointmentData?.is_quali_repar || false,
                    quali_repar_amount: parseFloat(appointmentData?.quali_repar_amount || 0).toFixed(2),
                    ids_appointment_action: selectedActions,
                };
            } else {
                payload = {
                    id_folder: folderId,
                    comments: appointmentData?.comments,
                    is_client_present: false,
                    is_quali_repar: false,
                    quali_repar_amount: 0,
                    ids_appointment_action: [],
                };
            }

            // Create a new appointment
            await AppointmentsService.createAppointment(payload);
            onSave();
            onClose();
        } catch (error) {
            throw new Error('Une erreur s\'est produite lors de la sauvegarde de l\'intervention.');
        }
    };

    // If the modal is not open, return null
    if (!open || folderId === null) return null;

    // Render the create appointment modal
    return (
        !loading && (
            <CustomModal
                open={open}
                onClose={onClose}
                title="Créer une intervention"
                handleSave={handleSave}
                hideSaveButton={confirmClientAbsent === false && confirmClientPresent === false}
            >
                { /* Render the error message if an error occurs */ }
                {error && <InfoBox type="error" text={error} disabledCloseBtn={true} />}

                {/* Render the client selection, client absent warning, or appointment fields */}
                { confirmClientAbsent ? (
                    <ConfirmClientAbsent />
                ) : confirmClientPresent ? (
                    <AppointmentFields 
                        appointmentData={appointmentData} 
                        setAppointmentData={setAppointmentData}
                        appointmentActions={appointmentActions} 
                        setAppointmentActions={setAppointmentActions}
                        qualiReparAllowed={qualiReparAllowed}
                        resetClientPresent={() => {
                            setConfirmClientPresent(false);
                            setConfirmClientAbsent(true);
                        }}
                    />
                ) : (
                    <ClientSelection
                        onClientPresent={() => setConfirmClientPresent(true)}
                        onClientAbsent={() => setConfirmClientAbsent(true)}
                    />
                )}
            </CustomModal>
        )
    );        
};

// Prop types for the CreateAppointmentModal component
CreateAppointmentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    folderId: PropTypes.number.isRequired,
    qualiReparAllowed: PropTypes.bool,
};

export default CreateAppointmentModal;