// Import necessary modules from Capacitor for filesystem access and toast notifications
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { useIsApp } from './useIsApp';

import CONFIG from '../config';

/**
 * Custom hook for retrieving images from the device's filesystem.
 */
export const useGalleryPhoto = () => {
    // Determine if the device is mobile using the custom hook
    const { checkIfApp } = useIsApp();

    /**
     * Function to retrieve all images saved in the filesystem.
     * @returns {Promise<string[]>} - An array of base64 strings of the retrieved images.
     */
    const getImages = async () => {
        // Check if the app is running natively
        const isApp = await checkIfApp();

        // If the app is not running natively, return an error
        if (!isApp) {
            console.error('The application is not running as a mobile app.');
            throw new Error('Cette fonctionnalité est disponible uniquement sur les appareils mobiles.');
        }

        // Try to read the files from the filesystem
        try {
            // Read the files from the specified directory
            const result = await Filesystem.readdir({
                directory: Directory.Data,
                path: CONFIG.FOLDER_NAME
            });

            // Map the files to base64 images
            const base64Images = await Promise.all(result.files.map(async (fileInfo) => {
                const fileData = await Filesystem.readFile({
                    path: `${CONFIG.FOLDER_NAME}/${fileInfo.name}`,
                    directory: Directory.Data,
                    encoding: Encoding.Base64
                });
                return `data:image/jpeg;base64,${fileData.data}`;
            }));

            // Return the base64 images
            return {
                images: base64Images
            }
        } catch (error) {
            // Log and return an error if reading the files fails
            console.error('Error retrieving images:', error);
            return {
                error: 'Erreur lors de la récupération des images.'
            }
        }
    };

    /**
     * Function to purge old images from the gallery.
     * Images older than a specified number of days will be deleted.
     * @param {number} days - The number of days to keep images. 
     * Images older than this value will be deleted.
     */
    const purgeGallery = async (days) => {
        // Check if the app is running natively
        const isApp = await checkIfApp();

        // If the app is not running natively, return an error
        if (!isApp) return;

        try {            
            // Check if the folder exists in the filesystem
            await Filesystem.stat({
                path: CONFIG.FOLDER_NAME,
                directory: Directory.Data,
            });

            // If the folder exists, continue with the purge process
            const { files } = await Filesystem.readdir({
                path: CONFIG.FOLDER_NAME,
                directory: Directory.Data,
            });

            // Get the current date to compare with file creation dates
            const currentDate = new Date();

            // Loop through each file and check if it is older than the specified number of days
            for (const file of files) {
                // Get file metadata to determine the creation date
                const { metadata } = await Filesystem.stat({
                    path: `${CONFIG.FOLDER_NAME}/${file.name}`,
                    directory: Directory.Data,
                });

                const fileCreationDate = new Date(metadata.creationTime);
                const ageInMilliseconds = currentDate - fileCreationDate;
                const ageInDays = ageInMilliseconds / (1000 * 3600 * 24); // Convert to days

                // If the file is older than the specified number of days, delete it
                if (ageInDays > days || days === 0) {
                    await Filesystem.remove({
                        path: `${CONFIG.FOLDER_NAME}/${file.name}`,
                        directory: Directory.Data,
                    });
                }
            }

        } catch (error) {
            console.error('Error during the purge of old photos:', error);
        }
    };

    // Return the getImages function for use in components
    return { getImages, purgeGallery };
};
