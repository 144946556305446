import React, { useState } from 'react'; // Importer React et useState

import PropTypes from 'prop-types'; // Importer PropTypes pour la validation des propriétés

import ToggleButton from '../buttons/toggle-button'; // Importer le composant ToggleButton

/**
 * ItemOption component: Represents a setting item with a toggle button.
 * 
 * @param {Object} props - The properties for the component.
 * @param {string} props.name - The name of the setting.
 * @param {string} props.description - The description of the setting.
 * @param {Function} [props.onToggle] - The function to call when the toggle state changes (optional).
 * @param {boolean} props.initialState - The initial state of the toggle (on/off).
 */
const ItemOption = ({ name, description, onToggle, initialState = false }) => {
    // State to keep track of whether the toggle button is active (on) or not (off)
    const [isActive, setIsActive] = useState(initialState);

    /**
     * Function to handle toggle button click
     * This function toggles the state of the button and triggers any callback function provided.
     */
    const handleToggle = () => {
        const newState = !isActive; // Determine the new state by negating the current state
        setIsActive(newState); // Update the state to the new value
        
        // Check if an onToggle function is provided and is a function
        if (typeof onToggle === 'function') {
            onToggle(newState); // Call the passed function with the new state
        }
    };

    // Render the setting item with a name, description, and toggle button
    return (
        <li className="setting-item"> {/* List item for each setting */}
            <div className="details"> {/* Container for the setting details */}
                <h3 className="name">{name}</h3> {/* Display the name of the setting */}
                <p className="description">{description}</p> {/* Display the description */}
            </div>
            <ToggleButton 
                isActive={isActive} // Pass the current active state to the ToggleButton
                onToggle={handleToggle} // Pass the toggle handler to the ToggleButton
            />
        </li>
    );
};

// Validation of the props using PropTypes
ItemOption.propTypes = {
    name: PropTypes.string.isRequired, // The name of the setting, required
    description: PropTypes.string.isRequired, // The description of the setting, required
    onToggle: PropTypes.func, // Optional function to call on toggle change
    initialState: PropTypes.bool, // Initial state of the toggle (default is false)
};

// Export the ItemOption component as the default export
export default ItemOption;