import React from 'react'; // Import React

import Container from '../../components/blocks/container'; // Container component for layout
import ItemOption from '../../components/blocks/item-option'; // Component for individual item options

import { useTheme } from '../../context/theme-context'; // Custom hook for theme management

/**
 * AccessibilityPage component: Displays information about app accessibility features.
 */
const AccessibilityPage = () => {
    const { isDarkTheme, toggleTheme } = useTheme(); // Retrieve current theme state and toggle function

    /**
     * An array representing the accessibility settings to be displayed.
     * Each object includes an icon, name, and description for the UI.
     */
    const accessibilitySettingsList = [
        {
            name: 'Mode sombre',       // Label for dark mode setting 
            description: isDarkTheme ? 'Activé' : 'Désactivé', // Display current status
            onToggle: toggleTheme, // Function to toggle dark mode
            initialState: isDarkTheme // Initial state based on the current theme
        },
    ];

    /**
     * Renders the container with a list of accessibility information.
     * Maps over the accessibility settings list to create individual ItemOption components for each setting.
     */
    return (
        <Container>
            <ul className='settings-block' role="list"> {/* Use role="list" for better accessibility */}
                {accessibilitySettingsList.map((setting, index) => (
                    <ItemOption
                        key={index}           // Unique key for each setting
                        name={setting.name}   // Name of the setting 
                        description={setting.description} // Description of the setting
                        onToggle={setting.onToggle} // Toggle function for changing the setting
                        initialState={setting.initialState} // Initial state of the setting
                        aria-labelledby={`setting-${index}-label`} // Associate with a label for accessibility
                        aria-describedby={`setting-${index}-description`} // Associate with a description for accessibility
                    />
                ))}
            </ul>
        </Container>
    );
}

export default AccessibilityPage; // Export the AccessibilityPage component