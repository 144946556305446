import React, { useState, useEffect } from 'react';

import {
    Edit,
    DeleteOutline,
    Add
} from '@mui/icons-material';

import Container from '../../blocks/container';
import CommonButton from '../../buttons/common-button';
import AppointmentsService from '../../../services/AppointmentsService';
import InfoBox from '../../blocks/messages/info-box';

import DeleteAppointmentActionModal from './modals/modal--admin--delete-appointment-action';
import EditAppointmentActionModal from './modals/modal--admin--edit-appointment-action';
import CreateAppointmentActionModal from './modals/modal--admin--create-appointment-action';

/**
 * AppointmentActionsTable Component
 *
 * This component renders a table of appointment actions and allows the user to manage them.
 * @returns {JSX.Element}
 */
const AppointmentActionsTable = () => {
    // State for loading, error, and modal
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State for appointment actions
    const [appointmentActions, setAppointmentActions] = useState([]);

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    /**
     * Fetches the appointment actions from the API.
     * Sets the loading state and handles errors.
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchAppointmentActions = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the appointment actions
            const actions = await AppointmentsService.getAppointmentActions();

            // Set the appointment actions state
            setAppointmentActions(actions);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch appointment actions on component mount
    useEffect(() => {
        fetchAppointmentActions();
    }, []);

    // Display loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    // Display error message if fetching fails
    if (error) {
        return (
            <InfoBox
                type={'error'}
                disabledCloseBtn={true}
                text={error}
            />
        );
    }

    // Pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentActions = appointmentActions.slice(startIndex, endIndex);

    const totalPages = Math.ceil(appointmentActions.length / itemsPerPage);

    // Pagination handlers
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const getPageRange = () => {
        const range = [];
        const start = Math.max(1, currentPage - 1);
        const end = Math.min(totalPages, currentPage + 1);

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        return range;
    };

    // Modal handlers for deleting an existing action
    const handleDelete = (actionId) => {
        setModal({ type: 'delete', actionId: actionId });
    };

    // Modal handlers for editing an existing action
    const handleEdit = (actionId) => {
        setModal({ type: 'edit', actionId: actionId });
    };

    // Modal handlers for adding a new action
    const handleAdd = () => {
        setModal({ type: 'add' });
    };

    // Component to be rendered
    return (
        <React.Fragment>
            <Container className="admin-appointments-manager auto-height">
                <h2>Gestion des actions d'intervention</h2>
                {appointmentActions.length > 0 ? (
                    <div className="appointment-actions-list">

                        { /* Table of appointment actions */ }
                        <table className="appointment-actions-table">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentActions.map((action) => (
                                    <tr key={action.id}>
                                        <td>{action.name}</td>
                                        <td>
                                            <button
                                                onClick={() => handleEdit(action.id)}
                                                className="appointment-table-btn"
                                            >
                                                <Edit />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(action.id)}
                                                className="appointment-table-btn"
                                            >
                                                <DeleteOutline />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        { /* Pagination */ }
                        <div className="pagination">
                            <button
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                                className="first pagination-btn"
                            >
                                Première
                            </button>
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className="back pagination-btn"
                            >
                                Précédent
                            </button>
                            {getPageRange().map((page) => (
                                <button
                                    key={page}
                                    onClick={() => setCurrentPage(page)}
                                    className={`pagination-btn ${page === currentPage ? 'active' : ''}`}
                                >
                                    {page}
                                </button>
                            ))}
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className="next pagination-btn"
                            >
                                Suivant
                            </button>
                            <button
                                onClick={() => setCurrentPage(totalPages)}
                                disabled={currentPage === totalPages}
                                className="last pagination-btn"
                            >
                                Dernière
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className='no-appointment-actions'>Aucune action d'intervention n'a été trouvée.</p>
                )}

                {/* Add new action button */}
                <CommonButton
                    label="Ajouter une action"
                    icon={<Add />}
                    onClick={() => handleAdd()}
                />
            </Container>

            { /* Modals */ }
            {modal && modal.type === 'delete' && (
                <DeleteAppointmentActionModal open={true} onClose={() => setModal(null)} actionId={modal.actionId} onDelete={() => fetchAppointmentActions()} />
            )}

            {modal && modal.type === 'edit' && (
                <EditAppointmentActionModal open={true} onClose={() => setModal(null)} actionId={modal.actionId} onEdit={() => fetchAppointmentActions()} />
            )}

            {modal && modal.type === 'add' && (
                <CreateAppointmentActionModal open={true} onClose={() => setModal(null)} onCreate={() => fetchAppointmentActions()} />
            )}
        </React.Fragment>
    );
};

export default AppointmentActionsTable;