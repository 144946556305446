import React from 'react'; // Importer React

import PropTypes from 'prop-types'; // Importer PropTypes pour la validation des propriétés

/**
 * ItemCard component: Represents a card item that displays an icon,
 * a name, and a description, with an optional click handler.
 * 
 * @param {Object} props - The properties for the component.
 * @param {React.Element} props.icon - The icon to display for the item.
 * @param {string} props.name - The name of the item.
 * @param {string} props.description - The description of the item.
 * @param {Function} [props.onClick] - Optional function to call when the item is clicked.
 */
const ItemCard = ({ icon, name, description, onClick }) => {

    // Render the item card component
    return (
        <li 
            className="setting-item" 
            {...(onClick && { onClick })} // Attach the onClick handler if provided
        >
            <span className="icon">{icon}</span> {/* Display the icon */}
            <div className="details"> {/* Container for the item details */}
                <h3 className="name">{name}</h3> {/* Display the name of the item */}
                <p className="description">{description}</p> {/* Display the description */}
            </div>
        </li>
    );
};

// Validation of the props using PropTypes
ItemCard.propTypes = {
    icon: PropTypes.element.isRequired, // The icon, which is a required React element
    name: PropTypes.string.isRequired, // The name of the item, required
    description: PropTypes.string.isRequired, // The description of the item, required
    onClick: PropTypes.func, // Optional function to call on item click
};

// Export the ItemCard component as the default export
export default ItemCard;