import React from 'react'; // React and hooks for component lifecycle management
import { useParams, useNavigate } from 'react-router-dom'; // Hook to access the current location

import { useFolder } from '../../context/folder-context'; // Custom hook to use folder context

import Container from '../../components/blocks/container'; // Container component for layout
import AppointmentsInfo from '../../components/folder-components/appointments/folder--appointments-info';
import NavigateFooter from '../../components/folder-components/folder--navigate-footer';

/**
 * FolderAppointmentsPage Component
 * 
 * This component displays the appointments information for a folder.
 * It also provides navigation to other parts of the folder.
 * @returns {JSX.Element} - Rendered component
 */
const FolderAppointmentsPage = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate

    const { title, color } = useFolder(); // Get the title and color from the folder context

    // Fetch folder data when the component mounts
    return (
        <Container
            className="folder-container"
            title={title || ''}
            titleBackground={color || 'transparent'}
        >
            {/* Appointments Section */}
            <AppointmentsInfo
                showEditButton={true}
                showDeleteButton={true}
                showCreateButton={true}
                showContainer={false}
            />

            <NavigateFooter
                onBack={() => navigate(`/folders/${refFolder}/reporting`)}
                onNext={() => navigate(`/folders/${refFolder}/media-upload-tool`)}
            />
        </Container>
    );
};

export default FolderAppointmentsPage; // Export the component for use in other parts of the application