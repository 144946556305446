import React, { useRef } from 'react';

import { useParams, useNavigate } from 'react-router-dom'; // Import useLocation

import { useFolder } from '../../context/folder-context'; // Custom hook to use folder context

import Container from '../../components/blocks/container';
import ApplianceInfo from '../../components/folder-components/appliance/folder--appliance-info';
import WarrantyInfo from '../../components/folder-components/folder--warranty-info';
import ClientInfo from '../../components/folder-components/folder--client-info';
import FolderInfo from '../../components/folder-components/folder--folder-info';
import PartsInfo from '../../components/folder-components/folder--parts-info';
import NavigateFooter from '../../components/folder-components/folder--navigate-footer';
import OverviewTools from '../../components/folder-components/folder--overview--tools';
import DeclaredIssueInfo from '../../components/folder-components/issues/folder--declared-issue-info';
import AdditionalInfo from '../../components/folder-components/issues/folder--additional-info';
import AppointmentsInfo from '../../components/folder-components/appointments/folder--appointments-info';

/**
 * FolderOverviewPage Component
 * 
 * This component displays the folder overview page with all the information related to the folder.
 * @returns {JSX.Element} - Rendered component
 */
const FolderOverviewPage = () => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate

    // Reference to the overview container
    const overviewRef = useRef();

    const { title, color } = useFolder(); // Get the title and color from the folder context

    // State to hold folder data
    return (
        <React.Fragment>
            <Container
                className="w-100 folder-container"
                title={title || ''}
                titleBackground={color || 'transparent'}
            >
                <div
                    ref={overviewRef}
                    className="overview-container"
                    id="folder-overview"
                >
                    {/* Overview Tools */}
                    <OverviewTools overviewRef={overviewRef} />

                    {/* Folder Overview Page */}
                    <h1 className="folder-container--title">Rapport d'intervention N° {refFolder}</h1>

                    {/* Client Information Section */}
                    <ClientInfo 
                        enabledMapButtons={false}
                    />

                    {/* Folder Information Section */}
                    <FolderInfo />

                    {/* Appliance Information Section */}
                    <ApplianceInfo />

                    {/* Warranty Information Section */}
                    <WarrantyInfo />

                    {/* Declared and Observed Issues Section */}
                    <DeclaredIssueInfo />

                    {/* Additional Information Section */}
                    <AdditionalInfo />

                    {/* Appointments Section */}
                    <AppointmentsInfo />

                    {/* Spare Parts Section */}
                    <PartsInfo />
                </div>

                {/* Navigate Footer */}
                <NavigateFooter
                    onBack={() => navigate(`/folders/${refFolder}/payments-and-signature`)}
                />
            </Container>
        </React.Fragment>
    );
};

export default FolderOverviewPage;