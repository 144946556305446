import React, {useState} from 'react';

import PropTypes from 'prop-types';

import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

/**
 * InfoBox component to display messages with customizable styles and icons.
 * @param {string} text - The text to display in the info box.
 * @param {string} backgroundColor - The background color of the box.
 * @param {string} textColor - The text color.
 * @param {string} type - The type of message (information, error, validation, novelty).
 * @param {boolean} disabledCloseBtn - Flag to disable the close button.
 * @returns {JSX.Element} - The rendered info box component.
 */
const InfoBox = ({ text, backgroundColor, textColor, type = "information", disabledCloseBtn = false }) => {
    const [isVisible, setIsVisible] = useState(true); // State to control the visibility of the info box

    if (!isVisible) return null; // If the info box is not visible, return null

    // Default styles and icons based on the type
    const typeStyles = {
        information: {
            backgroundColor: backgroundColor || `var(--app-info-bg-color)`,
            textColor: textColor || `var(--app-info-color)`,
            icon: <InfoIcon />
        },
        error: {
            backgroundColor: backgroundColor || `var(--app-error-bg-color)`,
            textColor: textColor || `var(--app-error-color)`,
            icon: <ErrorIcon />
        },
        validation: {
            backgroundColor: backgroundColor || `var(--app-validating-bg-color)`,
            textColor: textColor || `var(--app-validating-color)`,
            icon: <CheckCircleIcon />
        },
        novelty: {
            backgroundColor: backgroundColor || `var(--app-novelty-bg-color)`,
            textColor: textColor || `var(--app-novelty-color)`,
            icon: <NewReleasesIcon />
        },
        warning: {
            backgroundColor: backgroundColor || `var(--app-warning-bg-color)`,
            textColor: textColor || `var(--app-warning-color)`,
            icon: <WarningIcon />
        }
    };

    // Get styles based on the type provided
    const { backgroundColor: bgColor, textColor: txtColor, icon } = typeStyles[type] || typeStyles.information;

    // Render the info box component
    return (
        <div className="info-box" style={{ backgroundColor: bgColor, color: txtColor }}>
            {/* Close button */}
            {!disabledCloseBtn && (
                <button 
                    onClick={() => setIsVisible(false)} // Ferme l'info box au clic
                    className='close-button'
                    style={{
                        color: txtColor
                    }}
                >
                    <CloseIcon />
                </button>
            )}
            {/* Display the icon and text */}
            {icon}
            <span className="info-box--text">{text}</span>
        </div>
    );
};

// Prop type validation
InfoBox.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    type: PropTypes.oneOf(['information', 'error', 'validation', 'novelty', 'warning']),
    disabledCloseBtn: PropTypes.bool
};

export default InfoBox; // Export the InfoBox component for use in other parts of the application
