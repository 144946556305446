import React from 'react';

import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

/**
 * NavigateFooter Component
 * 
 * This component displays navigation buttons for navigating to a previous page or a next page.
 * It takes tonBack, and onNext functions as props.
 * 
 * Props:
 * - onBack: A function triggered when the back button is clicked.
 * - onNext: A function triggered when the next button is clicked.
 */
const NavigateFooter = ({ onBack, onNext }) => {

    // Render the navigation footer
    return (
        <div className="navigate-footer">

            { /* Navigation buttons */}
            <div className="navigation-buttons">
                {onBack && (
                    <button 
                        onClick={onBack} 
                        className="btn-back"
                    >
                        <ArrowBackIcon style={{ marginRight: '5px' }} /> Retour
                    </button>
                )}
                {onNext && (
                    <button 
                        onClick={onNext} 
                        className="btn-next"
                    >
                        Suivant <ArrowForwardIcon style={{ marginLeft: '5px' }} />
                    </button>
                )}
            </div>
        </div>
    );
};

// Prop types for the NavigateFooter component
NavigateFooter.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func,
};

export default NavigateFooter;