import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    PictureAsPdfOutlined,
    ArrowBackIos
} from '@mui/icons-material';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import PdfViewer from '../../../blocks/pdf-viewer';
import CommonButton from '../../../buttons/common-button';

/**
 * TechnicalDetailsModal renders a modal to display a list of technical bulletins.
 *
 * @param {boolean} open - Determines if the modal is open
 * @param {function} onClose - Handler to close the modal
 * @param {object[]} technicalData - Array of technical data to display
 * @returns {JSX.Element} - Rendered technical details modal component
 */
const TechnicalDetailsModal = ({ open, onClose, technicalData }) => {
    const [bulletins, setBulletins] = useState(technicalData || []); // List of technical bulletins
    const [selectedBulletin, setSelectedBulletin] = useState(null); // Selected bulletin to display

    // Render the technical details modal
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={selectedBulletin ? 'Bulletin technique' : 'Bulletins techniques'}
            onCloseLabel="Fermer"
            hideSaveButton={true}
        >
            <div className="technical-details-modal-content">
                <React.Fragment>
                    { !selectedBulletin ? (
                        <ul className="technical-bulletins-list">
                            {bulletins.length > 0 ? (
                                bulletins.map((bulletin) => (
                                    <li key={bulletin.id} className="technical-bulletin-item">
                                        <button
                                            className="bulletin-link"
                                            onClick={() => setSelectedBulletin(bulletin)}
                                        >
                                            <PictureAsPdfOutlined />
                                            <span className="bulletin-name">{bulletin.file_name}</span>
                                        </button>
                                    </li>
                                ))
                            ) : (
                                <p>Aucun bulletin technique disponible.</p>
                            )}
                        </ul>
                    ) : (
                        <div className="pdf-viewer-container">
                            <CommonButton
                                label="Retour à la liste"
                                icon={<ArrowBackIos />}
                                className="no-side-margin"
                                onClick={() => setSelectedBulletin(null)}
                                square={true}
                            />
                            <PdfViewer file={selectedBulletin.file_path} title={selectedBulletin.file_name} />
                        </div>
                    )}
                </React.Fragment>
            </div>
        </CustomModal>
    );
};

// Prop types for TechnicalDetailsModal component
TechnicalDetailsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    technicalData: PropTypes.array.isRequired,
};

export default TechnicalDetailsModal;
