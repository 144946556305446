// src/App.jsx
import React, { useEffect } from 'react'; // Import useEffect for side effects
import { BrowserRouter as Router, Route, Routes, useLocation, useParams, Navigate } from 'react-router-dom'; // Import useLocation for accessing current path

import { ThemeProvider } from './context/theme-context'; // Context for theme management
import { GalleryProvider } from './context/gallery-context'; // Context for gallery management
import { FolderProvider } from './context/folder-context'; // Context for folder management
import { HeaderProvider, useTitle } from './context/header-context'; // Header context for managing page titles
import { AuthProvider, useAuth } from './context/auth-context';
import { UpdateProvider, useUpdate } from './context/update-context';

import './assets/styles/styles.min.css'; // Global styles for the application

// Layout components
import ClassicLayout from './layouts/classic-layout'; // Layout component for consistent page structure
import FolderLayout from './layouts/folder-layout';
import AdminLayout from './layouts/admin-layout';

// Page components
import Home from './views/home-page'; // Home page component
import LoginPage from './views/login-page'; // Login page component
import UpdatePage from './views/update-page'; // Update page component
import Settings from './views/settings/settings-page'; // Settings page component
import InfoPage from './views/settings/settings--app-details-page'; // App details page component
import ConfigPage from './views/settings/settings--app-config-page'; // App configuration page component
import AccessibilityPage from './views/settings/settings--app-accessibility-page'; // Accessibility page component
import InternalGalleryPage from './views/app--internal-gallery-page';
import FolderPage from './views/folders/folders--folder-page';

// Folder pages
import FolderOverviewPage from './views/folders/folders--folder-overview-page';
import FolderReportingPage from './views/folders/folders--folder-reporting-page';
import MediaUploadTool from './views/folders/folders--media-upload-tool-page'; // Image upload tool page component
import FolderAppointmentsPage from './views/folders/folders--folder-appointments-page';
import FolderPartsPage from './views/folders/folders--folder-parts-page';
import PaymentsAndSignaturePage from './views/folders/folders--folder-payments-and-signatures';

// Admin pages
import AdminHomePage from './views/admin/admin--home-page';
import AdminMediasManagerPage from './views/admin/admin--medias-manager-page';
import AdminUsersManagerPage from './views/admin/admin--users-manager-page';
import AdminAppointmentActionsManagerPage from './views/admin/admin--appointment-actions-page';

// 404 Page
import NotFoundPage from './views/not-found-page';
import { useGalleryPhoto } from './modules/cameraModule';

// Import the function to purge old images
import CONFIG from './config/config';
import { Toast } from '@capacitor/toast';

// List of pages with paths, components, and titles
const pages = [
  { path: '/', element: <Home />, title: 'Accueil' },
  { path: '/settings', element: <Settings />, title: 'Paramètres' },
  { path: '/settings/app-details', element: <InfoPage />, title: 'Détails de l\'application' },
  { path: '/settings/app-config', element: <ConfigPage />, title: 'Configuration de l\'application' },
  { path: '/settings/app-accessibility', element: <AccessibilityPage />, title: 'Accessibilité' },
  { path: '/internal-gallery', element: <InternalGalleryPage />, title: 'Galerie interne' },
  { path: '/folders/:refFolder', element: <FolderPage />, title: 'Informations client' },
];

const foldersPages = [
  { path: '/folders/:refFolder/reporting', element: <FolderReportingPage />, title: 'Informations produit' },
  { path: '/folders/:refFolder/appointments', element: <FolderAppointmentsPage />, title: 'Interventions' },
  { path: '/folders/:refFolder/media-upload-tool', element: <MediaUploadTool />, title: 'Médias du dossier' },
  { path: '/folders/:refFolder/parts', element: <FolderPartsPage />, title: 'Pièces du dossier' },
  { path: '/folders/:refFolder/payments-and-signature', element: <PaymentsAndSignaturePage />, title: 'Signature' },
  { path: '/folders/:refFolder/overview/', element: <FolderOverviewPage />, title: 'Récapitulatif du dossier' },
];

const adminPages = [
  { path: '/admin', element: <AdminHomePage />, title: 'Menu d\'administration' },
  { path: '/admin/medias', element: <AdminMediasManagerPage />, title: 'Gestion des médias' },
  { path: '/admin/users', element: <AdminUsersManagerPage />, title: 'Gestion des utilisateurs' },
  { path: '/admin/appointment-actions', element: <AdminAppointmentActionsManagerPage />, title: 'Gestion des actions d\'intervention' },
];

/**
 * TitleUpdater Component
 * 
 * This component updates the page title based on the current route.
 * It uses the useEffect hook to set the title whenever the path changes.
 */
const TitleUpdater = () => {
  const { setTitle } = useTitle(); // Hook to set the title
  const location = useLocation(); // Hook to get the current location
  const { refFolder } = useParams(); // Get dynamic URL params (e.g., refFolder)

  useEffect(() => {
    // Function to match the path dynamically, replacing parameters like ":refFolder"
    const matchPathWithParams = (path, locationPath) => {
      const pathRegex = new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`);
      return pathRegex.test(locationPath);
    };

    // Check if the current path matches any page from the pages or foldersPages arrays
    const currentPage = pages.find(page => matchPathWithParams(page.path, location.pathname)) ||
                        foldersPages.find(page => matchPathWithParams(page.path, location.pathname)) ||
                        adminPages.find(page => matchPathWithParams(page.path, location.pathname));

    if (currentPage) {
      // Optionally use `refFolder` in the title if it's part of the route
      const dynamicTitle = currentPage.title.replace(':refFolder', refFolder || '');
      setTitle(dynamicTitle); // Update the title if a match is found
    } else {
      setTitle('CTR Drako'); // Set default title if no match is found
    }
  }, [location, refFolder, setTitle]); // Update when location or refFolder changes

  return null; // No rendering needed
};

/**
 * RequireAuth Component
 * 
 * This component checks if the user is authenticated before rendering
 * the children components. If the user is not authenticated, it redirects
 * to the login page.
 * 
 * @param {Object} children - The child components to render
 * @returns The children components if authenticated, otherwise redirects to login
 */
function RequireAuth({ children }) {
  const { isAuthenticated, isLoading } = useAuth(); // Get authentication status
  const location = useLocation(); // Get the current location

  if (isLoading) return null; // Show nothing while loading

  // Redirect to the login page with the current location as the "from" state
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

/**
 * RedirectIfAuthenticated Component
 * 
 * This component redirects the user to the home page if they are already authenticated
 * when they try to access the login page.
 * 
 * @param {Object} children - The child components to render
 * @returns The children components if not authenticated, otherwise redirects to home
 */
function RedirectIfAuthenticated({ children }) {
  const { isAuthenticated, isLoading } = useAuth(); // Get authentication status

  if (isLoading) return null; // Show nothing while loading

  // Redirect to the home page if already authenticated
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
}


/**
 * UpdatePage Component
 * 
 * This component displays the update page with a link to the update.
 * 
 * @param {Object} updateLink - The link to the update
 * @returns The update page component
 */
const RequireUpdate = ({ children }) => {
  const { isUpdateRequired } = useUpdate();

  if (isUpdateRequired) {
    return <Navigate to="/update" replace />;
  }

  return children;
};

/**
 * RedirectIfNotUpdate Component
 * 
 * This component redirects the user to the home page if they are not required to update
 * when they try to access the update page.
 * 
 * @param {Object} children - The child components to render
 * @returns The children components if an update is required, otherwise redirects to home
 */
const RedirectIfNotUpdate = ({ children }) => {
  const { isUpdateRequired } = useUpdate(); // Get update status

  if (!isUpdateRequired) {
    // Redirect to the home page if no update is required
    return <Navigate to="/" replace />;
  }

  return children;
}

/**
 * App Component
 * 
 * This is the main application component that provides context
 * and sets up routing for the application.
 */
function App() {
  const { purgeGallery } = useGalleryPhoto();

  // Function to handle purging old images (older than 7 days)
  const handlePurge = async () => {
    try {
      // Purge images older than 7 days
      await purgeGallery(CONFIG.GALLERY_MEDIAS_DELETE_DELAY);
    } catch (error) {
      console.error('Error purging images:', error);
    }
  };

  // // Optionally, you can use this effect to purge old images on component mount
  // useEffect(() => {
  //   // Purge images when the app is loaded or at regular intervals
  //   handlePurge();
  // }, []); // Empty dependency array ensures this runs only once when the component is mounted

  return (
    <AuthProvider>
      <ThemeProvider>
        <GalleryProvider>
          <HeaderProvider>
            <UpdateProvider>
              <Router>
                <div className="App">
                  <TitleUpdater />
                  <Routes>
                    {/* Public routes */}
                    <Route
                      path="/login"
                      element={
                        <RedirectIfAuthenticated>
                          <LoginPage />
                        </RedirectIfAuthenticated>
                      }
                    />

                    {/* Update route */}
                    <Route
                      path="/update"
                      element={
                        <RequireAuth>
                          <RedirectIfNotUpdate>
                            <UpdatePage />
                          </RedirectIfNotUpdate>
                        </RequireAuth>
                      }
                    />

                    <Route
                      path="*"
                      element={
                        <RequireAuth>
                          <RequireUpdate>
                            <Routes>
                              {/* Private routes */}
                              <Route element={<ClassicLayout />}>
                                {pages.map(({ path, element }) => (
                                  <Route
                                    key={path}
                                    path={path}
                                    element={<RequireAuth>{element}</RequireAuth>}
                                  />
                                ))}
                              </Route>
                              {/* Folder layout routes */}
                              <Route
                                element={
                                  <FolderProvider>
                                    <FolderLayout />
                                  </FolderProvider>
                                }
                              >
                                {foldersPages.map(({ path, element }) => (
                                  <Route key={path} path={path} element={element} />
                                ))}
                              </Route>
                              <Route element={<AdminLayout />}>
                                {adminPages.map(({ path, element }) => (
                                  <Route key={path} path={path} element={element} />
                                ))}
                              </Route>
                              {/* 404 Page */}
                              <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                          </RequireUpdate>
                        </RequireAuth>
                      }
                    />
                  </Routes>
                </div>
              </Router>
            </UpdateProvider>
          </HeaderProvider>
        </GalleryProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;