import React from 'react';

import PropTypes from 'prop-types';

import CalendarIcon from '@mui/icons-material/CalendarToday';
import { Edit, Delete } from '@mui/icons-material';

import CommonButton from '../../../buttons/common-button';

/**
 * AppointmentDetails Component
 * 
 * This component displays the details of an appointment.
 * It takes appointment, onEdit, onDelete, showEditButton, and showDeleteButton as props.
 * 
 * Props:
 * - appointment (object): The appointment data.
 * - onEdit (function): A function to edit the appointment.
 * - onDelete (function): A function to delete the appointment.
 * - showEditButton (boolean): A boolean to show the edit button.
 * - showDeleteButton (boolean): A boolean to show the delete button.
 * 
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const AppointmentDetails = ({
    appointment,
    onEdit,
    onDelete,
    showEditButton,
    showDeleteButton
}) => {

    // Return null if no appointment is provided
    if (!appointment) return null;

    // Format the date
    const formattedDate = new Date(appointment.appointment_date).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    // Render the appointment details
    return (
        <div className="appointment-container">
            <div className="appointment-content">

                {/* Appointment number */}
                <h3 className="appointment-number">
                    <CalendarIcon /> Intervention {appointment.appointment_number}
                </h3>

                {/* Appointment details */}
                <div className="appointment-details">

                    {/* Appointment lines */}
                    <div className="appointment-line">
                        <strong>Date et heure: </strong>
                        <span>{formattedDate}</span>
                    </div>
                    <div className="appointment-line">
                        <strong>Technicien: </strong>
                        <span>
                            {appointment.technician
                                ? `${appointment.technician?.last_name} ${appointment.technician?.first_name}`
                                : 'Aucun technicien assigné'}
                        </span>
                    </div>

                    {/* Client presence */}
                    {appointment.is_client_present ? (
                        <React.Fragment>
                            { /* Appointment actions and remarks */ }
                            {appointment.appointment_actions && appointment.appointment_actions.length > 0 ? (
                                <table className="actions-table">
                                    <thead>
                                        <tr>
                                            <th>Action(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {appointment.appointment_actions.map((action, actionIndex) => (
                                            <tr key={actionIndex}>
                                                <td>{action?.name || 'N/A'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>Aucune action enregistrée pour cette intervention</p>
                            )}
                            <div className="appointment-remarks appointment-line">
                                <strong>Remarque(s): </strong>
                                <span>{appointment.comments || 'Aucune remarque'}</span>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="client-not-present">
                            <strong>Client absent lors du passage du technicien</strong>
                        </div>
                    )}

                    {/* Appointment signature */}
                    <div className="appointment-signature">
                        <strong>Signature(s): </strong>
                        <div className="appointment-signature--container">
                            {appointment.signature ? (
                                <img src={appointment.signature} alt="Signature des parties" />
                            ) : (
                                <p>Aucune signature enregistrée</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            { /* Appointment footer */}
            <div className="appointment-footer">
                {showEditButton && (appointment.is_locked === false) && (
                    <CommonButton
                        label="Éditer"
                        className={'btn-edit no-side-margin'}
                        onClick={() => onEdit(appointment)}
                        square={true}
                        icon={<Edit />}
                    />
                )}
                {showDeleteButton && (appointment.is_locked === false) && (
                    <CommonButton
                        label="Supprimer"
                        className={'btn-delete no-side-margin'}
                        onClick={() => onDelete(appointment)}
                        square={true}
                        icon={<Delete />}
                    />
                )}
            </div>
        </div>
    );
};

// Prop types for the AppointmentDetails component
AppointmentDetails.propTypes = {
    appointment: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showEditButton: PropTypes.bool,
    showDeleteButton: PropTypes.bool
};

export default AppointmentDetails;
