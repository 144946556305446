import React from 'react';

import PropTypes from 'prop-types';

import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

/**
 * ClientSelection Component
 * 
 * This component displays buttons to select client presence or absence.
 * It takes onClientPresent and onClientAbsent as props.
 * 
 * Props:
 * - onClientPresent: A function to handle client present selection.
 * - onClientAbsent: A function to handle client absent selection.
 * 
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const ClientSelection = ({ onClientPresent, onClientAbsent }) => {

    // Render the client selection buttons
    return (
        <div className="client-selection-buttons">
            <button
                onClick={onClientPresent}
                className="box-btn client-present"
            >
                <PersonIcon />
                Client Présent
            </button>
            <button
                onClick={onClientAbsent}
                className="box-btn client-absent"
            >
                <PersonOffIcon />
                Client Absent
            </button>
        </div>
    );
};

// Prop types for the ClientSelection component
ClientSelection.propTypes = {
    onClientPresent: PropTypes.func.isRequired,
    onClientAbsent: PropTypes.func.isRequired,
};

export default ClientSelection;
