import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import MediasService from '../../../../services/MediasService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * EditMediaCategoryModal Component
 *
 * This component displays a modal for confirming the editing of a media category.
 * It takes `open`, `onClose`, `categoryId`, and `onEdit` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - categoryId (number): The ID of the category to edit.
 * - onEdit: Function to edit the category.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const EditMediaCategoryModal = ({ open, onClose, categoryId, onEdit }) => {
    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State to handle category data
    const [category, setCategory] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [suffix, setSuffix] = useState('');
    const [groupCategory, setGroupCategory] = useState(null);
    const [categoriesGroups, setCategoriesGroups] = useState([]);

    // Fetch the category data when the modal is opened
    useEffect(() => {
        if (categoryId) {
            setLoading(true);  // Set loading to true when the modal is opened
            
            // Fetch the category data
            const fetchData = async () => {
                try {
                    // Fetch available category groups
                    const groups = await MediasService.getMediaGroups();

                    // If the groups do not exist, set an error message
                    if (!groups) {
                        setError('Les groupes de catégories n\'existent pas.');
                        return;
                    }

                    setCategoriesGroups(groups);

                    // Fetch the category data
                    const category = await MediasService.getMediaCategoryById(categoryId);

                    // If the category does not exist, set an error message
                    if (!category) {
                        setError('La catégorie n\'existe pas.');
                        return;
                    }

                    // Set the category name and group
                    setCategory(category);
                    setCategoryName(category.name);
                    setSuffix(category.qualification_suffix);
                    setGroupCategory(category.media_group_category?.id || null);
                } catch (error) {
                    setError(error?.message || "Une erreur inattendue est survenue");
                } finally {
                    setLoading(false);  // Set loading to false when data is fetched or error occurs
                }
            };

            fetchData();
        }
    }, []);

    /**
     * Check if the category name does not already exist
     * 
     * @returns {boolean} - Whether the category name is unique or not
     */
    const checkIfCategoryNameDoesNotExist = async () => {
        if (!categoryName || categoryName.trim() === '') {
            // Return false immediately if categoryName is empty or just spaces
            return false;
        }

        if (categoryName === category.name) {
            // If the category name has not changed, return true
            return true;
        }

        try {
            // Get the list of existing categories
            const existingCategories = await MediasService.getMediaCategories();

            // Check if the category name already exists in the list of existing categories
            const isCategoryNameUnique = existingCategories.every(category => category.name !== categoryName.trim());

            // Return the result
            return isCategoryNameUnique;
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
            return false; // Return false in case of error
        }
    }

    /**
     * Handles the confirmation of category editing
     * 
     * @returns {void}
     */
    const handleConfirmEdit = async () => {
        // Validate the category name and group
        if (!categoryName.trim()) {
            console.error('Category name is required');
            setError('Le nom de la catégorie est requis.');
            return;
        }

        // Validate the category suffix
        if (!suffix.trim()) {
            console.error('Category suffix is required');
            setError('Le suffixe de la catégorie est requis.');
            return;
        }

        // Validate the category group
        if (!groupCategory) {
            console.error('Category group is required');
            setError('Le groupe de catégorie est requis.');
            return;
        }

        // Check if the category name is unique
        const isCategoryNameUnique = await checkIfCategoryNameDoesNotExist();

        if (!isCategoryNameUnique) {
            console.error('Category name already exists');
            setError('Le nom de la catégorie existe déjà.');
            return;
        }

        if (categoryId) {
            try {
                // Prepare the payload for editing the category
                const payload = {
                    name: categoryName,
                    id_media_group_category: groupCategory,
                    qualification_suffix: suffix
                };

                // Edit the category with the new name
                await MediasService.editMediaCategory(categoryId, payload);

                // Trigger the onEdit callback to refresh data
                onEdit();

                // Close the modal after the operation
                onClose();
            } catch (error) {
                setError(error?.message || "Une erreur inattendue est survenue");
            }
        }
    };

    // If the modal is not open, return null
    if (!open || !categoryId) return null;

    // Display a loading message while fetching data
    if (loading) return <div>Chargement...</div>;

    // Render the edit media category modal
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Modifier la catégorie'}
            handleSave={handleConfirmEdit}
            handleSaveLabel={'Confirmer la modification'}
        >
            <div className='edit-media-category-modal'>
                {error ? (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                ) : (
                    <InfoBox
                        type="warning"
                        text={
                            <React.Fragment>
                                <p>Attention, si vous modifiez cette catégorie, cela pourrait affecter les médias associés.</p>
                            </React.Fragment>
                        }
                        disabledCloseBtn={true}
                    />
                )}

                { /* Display loading message while fetching data */ }
                <div className="media-category--input">
                    <label>Nom de la catégorie</label>
                    <input
                        type="text"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        placeholder="Entrez un nom pour la catégorie"
                    />
                </div>

                { /* Display the category group selection */ }
                <div className="media-category--select">
                    <label>Groupe de catégorie</label>
                    <select
                        value={groupCategory || ''}
                        onChange={(e) => setGroupCategory(e.target.value)}
                    >
                        <option value="">Sélectionnez un groupe</option>
                        {categoriesGroups.map(group => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Category name and group selection */}
                <div className="media-category--input">
                    <label>Suffixe de la catégorie</label>
                    <input
                        type="text"
                        value={suffix}
                        onChange={(e) => setSuffix(e.target.value)}
                        placeholder="Entrez un suffixe pour la catégorie"
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for the EditMediaCategoryModal component
EditMediaCategoryModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    categoryId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onEdit: PropTypes.func.isRequired,
};

export default EditMediaCategoryModal;
