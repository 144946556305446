import React, { useState, useEffect } from 'react';
import { Device } from '@capacitor/device';

import { useParams, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import GoogleIcon from '@mui/icons-material/Google';
import FolderOpen from '@mui/icons-material/FolderOpen';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import InfoBox from '../blocks/messages/info-box';

import FoldersService from '../../services/FoldersService';

/**
 * ClientInfo Component
 * 
 * This component displays the client information, including city code, identity details,
 * address, phone numbers, email, and folder number. If no client information is available, a fallback message is displayed.
 * 
 * Props:
 * - navigateBlock (boolean): If true, will use navigate instead of opening URLs directly.
 * - enabledMapButtons (boolean): If true, will display buttons for Google Maps and Waze.
 */
const ClientInfo = ({ navigateBlock = false, enabledMapButtons = true }) => {
    const { refFolder } = useParams(); // Get the folder number from the URL
    const navigate = useNavigate(); // Hook to programmatically navigate

    // State to hold warranty data
    const [data, setData] = useState(null);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Fetches the warranty data for the folder.
     * Sets loading state and handles errors.
     * 
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     * @throws {Error} Throws an error if fetching fails
     */
    const fetchData = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the folder based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                setError(new Error('Dossier introuvable'));
                return;
            }

            // If the folder has no client information, throw an error
            if (!folder.client) {
                console.error("No client information available.");
                setError("Aucune information sur le client n'est disponible.");
                return;
            }

            // Set the warranty data
            setData(folder.client);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    }

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Generate map URLs for Google Maps and Waze based on the address data.
     * @param {object} address - The address object containing city, address, and zip_code.
     * 
     * @returns {object} An object containing the URLs for Google Maps and Waze.
     * - googleMaps: The URL for Google Maps.
     * - waze: The URL for Waze.
     * 
     * Note: The URLs are formatted differently for mobile apps and browsers.
     * For mobile apps, the URLs use custom URI schemes to open the respective map apps.
     * For browsers, the URLs open the map services in the browser.
     */
    const generateMapUrls = async (address) => {
        const { city, address: streetAddress, zip_code } = address || {};

        // If any of the address fields are missing, return placeholder URLs
        if (!streetAddress || !city || !zip_code) return { googleMaps: '#', waze: '#' };

        // Construct the query string for the address
        const query = `${streetAddress}, ${zip_code}, ${city}`.trim();
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`;
        const wazeUrl = `https://www.waze.com/ul?q=${encodeURIComponent(query)}`;

        // Get device information
        const info = await Device.getInfo();

        // Check if the platform is iOS or Android
        const isMobileApp = info.platform === 'ios' || info.platform === 'android';

        // Return URLs based on the platform
        if (isMobileApp) {
            return {
                googleMaps: `geo:0,0?q=${encodeURIComponent(query)}`, // Opens Google Maps on mobile
                waze: `waze://?q=${encodeURIComponent(query)}`, // Opens Waze on mobile
            };
        } else {
            return {
                googleMaps: googleMapsUrl, // Opens Google Maps in browser
                waze: wazeUrl, // Opens Waze in browser
            };
        }
    };

    /**
     * Function to handle safe navigation.
     * Redirects to the folder reporting page.
     */
    const handleNavigate = () => {
        navigate(`/folders/${refFolder}/reporting`);
    };

    // Display loading message while fetching data
    if (loading) {
        return (
            <div className="folder-section client-info">
                <h2>Informations du client</h2>
                Chargement...
            </div>
        );
    }

    // Display error message if an error occurred
    if (error) {
        return (
            <div className="folder-section client-info">
                <h2>Informations du client</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // Fetch client data based on the client ID
    if (!data) {
        return (
            <div className="folder-section client-info">
                <h2>Informations du client</h2>
                <p>Aucune information sur le client n'est disponible.</p>
            </div>
        );
    }

    // Generate map URLs for Google Maps and Waze
    const addressLinks = data?.address ? generateMapUrls(data.address) : null;

    // Extract client data
    return (
        <div className="folder-section client-info">

            {/* Display the folder number if navigateBlock is true */}
            <h2>{navigateBlock ?
                    `Dossier ${refFolder}` :
                    'Informations du client'
                }
            </h2>

            {/* Display client information */}
            <div className="client-details">

                {!navigateBlock && (
                    <div className="client-city-code"><strong>Code ville: </strong>{data.address?.city_code || "N/A"}</div>
                )}

                {/* Display client identity details, address, and contact information */}
                <div className="client-identity-details">
                    <div className="client-name">{data.complete_name || "N/A"}</div>

                    { /* Display address information */ }
                    {data?.address ? (
                        <div className="client-address">
                            <p className="client-address--address">{data.address.address || "N/A"}, {data.address.zip_code || "N/A"}, {data.address.city || "N/A"}</p>
                            {data.address.complement && <p>{data.address.complement}</p>}
                            {data.address.address_info && <p className="client-address--info"><strong>Note pour le technicien : </strong>{data.address.address_info}</p>}
                        </div>
                    ) : (
                        <div className="client-address">
                            <p>Adresse non disponible</p>
                        </div>
                    )}

                    {/* Buttons for Google Maps and Waze */}
                    {enabledMapButtons && addressLinks && (
                        <div className="client-maps-buttons">
                            <button className="google-maps-icn" onClick={() => window.open(addressLinks.googleMaps, '_blank')}>
                                <GoogleIcon />
                                Google Maps
                            </button>
                            <button className="waze-icn" onClick={() => window.open(addressLinks.waze, '_blank')}>
                                <DirectionsCarIcon />
                                Waze
                            </button>
                        </div>
                    )}

                    {/* Redirect to folder reporting page */}
                    {navigateBlock && (
                        <button className="folder-reporting-btn" onClick={handleNavigate}>
                            <FolderOpen />
                            Démarrer l'intervention
                        </button>
                    )}
                </div>

                {/* Display client contact information */}
                <div className="client-contact">

                    { /* Display phone numbers and email */ }
                    <div className="client-phones-item">
                        <strong>Téléphone: </strong>
                        <a href={`tel:${data.phone || ''}`}>
                            {data.phone || "N/A"}
                        </a>
                    </div>

                    { /* Display office phone, mobile phone, and email */ }
                    <div className="client-phones-item">
                        <strong>Téléphone bureau: </strong>
                        <a href={`tel:${data.office_phone || ''}`}>
                            {data.office_phone || "N/A"}
                        </a>
                    </div>

                    { /* Display mobile phone and email */ }
                    <div className="client-phones-item">
                        <strong>Mobile: </strong>
                        <a href={`tel:${data.mobile_phone || ''}`}>
                            {data.mobile_phone || "N/A"}
                        </a>
                    </div>

                    { /* Display email */ }
                    <div className="client-email">
                        <strong>Email: </strong>
                        <a href={`mailto:${data.mail_address}`}>
                            {data.mail_address || "N/A"}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Prop types for ClientInfo component
ClientInfo.propTypes = {
    navigateBlock: PropTypes.bool, // Add navigateBlock prop
    enabledMapButtons: PropTypes.bool, // Add enabledMapButtons prop
};

export default ClientInfo;