import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import MediasService from '../../../../services/MediasService';
import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';

/**
 * CreateMediaCategoryModal Component
 *
 * This component displays a modal for creating a new media category.
 * It takes `open`, `onClose`, and `onCreate` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - onCreate: Function to create a new category.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const CreateMediaCategoryModal = ({ open, onClose, onCreate }) => {
    // State to handle loading state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // State to handle the category name and group
    const [categoryName, setCategoryName] = useState('');
    const [suffix, setSuffix] = useState('');
    const [groupCategory, setGroupCategory] = useState(null);
    const [categoriesGroups, setCategoriesGroups] = useState([]);

    useEffect(() => {
        if (open) {
            setLoading(true);  // Set loading to true when the modal is opened

            const fetchData = async () => {
                try {
                    // Fetch available category groups
                    const groups = await MediasService.getMediaGroups();
                    setCategoriesGroups(groups);
                } catch (error) {
                    setError(error?.message || "Une erreur inattendue est survenue");
                } finally {
                    setLoading(false);  // Set loading to false when data is fetched or error occurs
                }
            };

            fetchData();
        }
    }, [open]);

    /**
     * Check if the category name does not already exist
     * 
     * @returns {boolean} - Whether the category name is unique or not
     */
    const checkIfCategoryNameDoesNotExist = async () => {
        if (!categoryName || categoryName.trim() === '') {
            // Return false immediately if categoryName is empty or just spaces
            return false;
        }

        try {
            // Get the list of existing categories
            const existingCategories = await MediasService.getMediaCategories();
            const isCategoryNameUnique = existingCategories.every(category => category.name !== categoryName.trim());

            // Return the result based on uniqueness
            return isCategoryNameUnique;
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
            return false;
        }
    };

    /**
     * Handles the confirmation of category creation
     * 
     * @returns {void}
     */
    const handleConfirmCreate = async () => {
        // Validate the category name and group
        if (!categoryName.trim()) {
            console.error('Category name is required');
            setError('Le nom de la catégorie est requis.');
            return;
        }

        // Validate the category suffix
        if (!suffix.trim()) {
            console.error('Category suffix is required');
            setError('Le suffixe de la catégorie est requis.');
            return;
        }

        // Validate the category group
        if (!groupCategory) {
            console.error('Category group is required');
            setError('Le groupe de catégorie est requis.');
            return;
        }

        // Check if the category name is unique
        const isCategoryNameUnique = await checkIfCategoryNameDoesNotExist();

        // If the category name is not unique, show an error and return
        if (!isCategoryNameUnique) {
            console.error('Category name already exists');
            setError('Le nom de la catégorie existe déjà.');
            return;
        }

        try {
            // Prepare the payload for creating the new category
            const payload = {
                name: categoryName,
                id_media_group_category: groupCategory,
                qualification_suffix: suffix
            };

            // Create the new category
            await MediasService.createMediaCategory(payload);

            // Trigger the onCreate callback to refresh data
            onCreate();

            // Close the modal after the operation
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // If the modal is not open, return null
    if (!open) return null;

    // Render the create media category modal
    if (loading) {
        return (
            <CustomModal
                open={open}
                onClose={onClose}
                title={'Créer une nouvelle catégorie'}
            >
                <p>Loading...</p>
            </CustomModal>
        );
    }

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Créer une nouvelle catégorie'}
            handleSave={handleConfirmCreate}
            handleSaveLabel={'Créer la catégorie'}
        >
            <div className='create-media-category-modal'>
                {error ? (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                ) : (
                    <InfoBox
                        type="warning"
                        text={
                            <React.Fragment>
                                <p>Attention, la création de cette catégorie pourrait affecter les médias associés.</p>
                            </React.Fragment>
                        }
                        disabledCloseBtn={true}
                    />
                )}

                {/* Category name and group selection */}
                <div className="media-category--input">
                    <label>Nom de la catégorie</label>
                    <input
                        type="text"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        placeholder="Entrez un nom pour la catégorie"
                    />
                </div>

                {/* Category group selection */}
                <div className="media-category--select">
                    <label>Groupe de catégorie</label>
                    <select
                        value={groupCategory || ''}
                        onChange={(e) => setGroupCategory(e.target.value)}
                    >
                        <option value="">Sélectionnez un groupe</option>
                        {categoriesGroups.map(group => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Category name and group selection */}
                <div className="media-category--input">
                    <label>Suffixe de la catégorie</label>
                    <input
                        type="text"
                        value={suffix}
                        onChange={(e) => setSuffix(e.target.value)}
                        placeholder="Entrez un suffixe pour la catégorie"
                    />
                </div>
            </div>
        </CustomModal>
    );
};

// Prop types for the CreateMediaCategoryModal component
CreateMediaCategoryModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default CreateMediaCategoryModal;
