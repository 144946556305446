import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ExpandLess, ExpandMore } from '@mui/icons-material'; // Import Material UI icon

import { Clipboard } from '@capacitor/clipboard';
import { Device } from '@capacitor/device';

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import ToggleButton from '../../../buttons/toggle-button';

import UsersService from '../../../../services/UsersService';
import PermissionsService from '../../../../services/PermissionsService';

/**
 * ViewUserModal Component
 *
 * This component displays a modal for viewing the details of a user.
 * It takes `open`, `onClose`, `userId` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - userId (number or string): The ID of the user to view.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const ViewUserModal = ({ open, onClose, userId }) => {

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    // State to hold permissions data
    const [permissions, setPermissions] = useState([]);

    // State to handle collapsed groups
    const [collapsedGroups, setCollapsedGroups] = useState({});

    // Fetch user data and permissions when the component mounts
    useEffect(() => {
        if (userId) {
            // Fetch user data
            const fetchUser = async () => {
                try {
                    // Set loading state to true
                    setLoading(true);

                    // Fetch the user data
                    const fetchedUser = await UsersService.getUserById(userId);

                    // Set the user data
                    setUser(fetchedUser);
                } catch (error) {
                    setError(error?.message || "Une erreur inattendue est survenue");
                }
            };

            // Fetch permissions data
            const fetchPermissions = async () => {
                try {
                    // Fetch the user permissions
                    const fetchedPermissions = await PermissionsService.getPermissions();

                    // Set the permissions data
                    setPermissions(fetchedPermissions);
                } catch (error) {
                    setError(error?.message || "Une erreur inattendue est survenue");
                } finally {
                    setLoading(false);  // Set loading to false when data is fetched or error occurs
                }
            };

            fetchUser();
            fetchPermissions();
        }
    }, []);

    // Set the initial collapsed groups when permissions data is fetched
    useEffect(() => {
        if (permissions && permissions.length > 0) {
            // Group permissions by group name
            const initialGroups = permissions.reduce((groups, permission) => {
                const group = permission.group_name || 'Autres';
                groups[group] = true;
                return groups;
            }, {});
            setCollapsedGroups(initialGroups);
        }
    }, [permissions]);

    /**
     * Handle toggling the visibility of a permissions group
     * 
     * @param {string} groupName - The name of the group to toggle
     */
    const toggleGroupVisibility = (groupName) => {
        setCollapsedGroups((prev) => ({
            ...prev,
            [groupName]: !prev[groupName], // Toggle the collapsed state
        }));
    };

    /**
     * Handle copying the device identifier to the clipboard
     * 
     * @returns {Promise<void>} A promise that resolves when the device identifier is copied
     */
    const handleCopyAuthKey = async () => {
        try {
            const info = await Device.getInfo();
            if (info.platform !== 'web') {
                await Clipboard.write({ string: user.auth_key });
            } else {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(user.auth_key)
                } else {
                    console.error('La copie du texte n\'est pas supportée par votre navigateur.');
                    setError('La copie du texte n\'est pas supportée par votre navigateur.');
                }
            }            
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        }
    };

    // If the modal is not open or userId is not provided, return null
    if (!open || !userId) return null;

    // Render the ViewUserModal component
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            onCloseLabel={'Fermer'}
            title={'Détails de l\'utilisateur'}
            hideSaveButton={true}
        >
            <div className='view-user-modal'>
                {loading ? (
                    <div>Chargement...</div>
                ) : error ? (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                ) : (
                    <React.Fragment>
                        <div className="user-info">
                            <React.Fragment>
                                <div className='user-info--header'>
                                    <span>Clé d'authentification: <strong>{user?.auth_key || 'N/A'}</strong></span>
                                    <button
                                        onClick={handleCopyAuthKey}
                                        className='copy-button'
                                    >
                                        Copier
                                    </button>
                                </div>

                                {/* Custom block for displaying user info */}
                                <div className="user-info--overview">
                                    <div className="user-info--overview-item">
                                        <strong>Identifiant appareil:</strong>
                                        <span>{user?.device_identifier || 'N/A'}</span>
                                    </div>
                                    <div className="user-info--overview-item">
                                        <strong>Créé le:</strong>
                                        <span>{user?.created_at ? new Date(user.created_at).toLocaleString() : 'N/A'}</span>
                                    </div>
                                    <div className="user-info--overview-item">
                                        <strong>Modifié le:</strong>
                                        <span>{user?.updated_at ? new Date(user.updated_at).toLocaleString() : 'N/A'}</span>
                                    </div>
                                    <div className="user-info--overview-item">
                                        <strong>Statut:</strong>
                                        <span>{!user?.is_active ? 'Désactivé' : 'Activé' || 'N/A'}</span>
                                    </div>
                                </div>

                                { /* Custom block for displaying user details */ }
                                <div className='user-info--details'>
                                    <h3>Informations de l'utilisateur</h3>
                                    <div className="user-info--input">
                                        <label>Nom :</label>
                                        <input
                                            type="text"
                                            value={user?.last_name || 'N/A'}
                                            readOnly
                                            className="input-disabled"
                                        />
                                    </div>
                                    <div className="user-info--input">
                                        <label>Prénom :</label>
                                        <input
                                            type="text"
                                            value={user?.first_name || 'N/A'}
                                            readOnly
                                            className="input-disabled"
                                        />
                                    </div>
                                    <div className="user-info--input">
                                        <label>Email :</label>
                                        <input
                                            type="email"
                                            value={user?.email || 'N/A'}
                                            readOnly
                                            className="input-disabled"
                                        />
                                    </div>
                                    <div className="user-info--input">
                                        <label>Code utilisateur :</label>
                                        <input
                                            type="text"
                                            value={user?.user_code || 'N/A'}
                                            readOnly
                                            className="input-disabled"
                                        />
                                    </div>
                                </div>

                                { /* Custom block for displaying user permissions */ }
                                <div className='user-info--permissions'>
                                    <h3>Autorisations de l'utilisateur</h3>
                                    {permissions && permissions.length > 0 ? (
                                        // Group permissions by group name
                                        Object.entries(
                                            permissions.reduce((groups, permission) => {
                                                const group = permission.group_name || 'Autres';
                                                if (!groups[group]) {
                                                    groups[group] = [];
                                                }
                                                groups[group].push(permission);
                                                return groups;
                                            }, {})
                                        ).map(([groupName, groupPermissions]) => (
                                            <div key={groupName} className='permissions-group'>
                                                <h4
                                                    className='permissions-group-title'
                                                    onClick={() => toggleGroupVisibility(groupName)}
                                                >
                                                    {groupName}
                                                    {collapsedGroups[groupName] ? <ExpandMore /> : <ExpandLess />}
                                                </h4>

                                                { !collapsedGroups[groupName] && (
                                                    <ul className='permissions-list'>
                                                        {groupPermissions.map((permission) => (
                                                            <li key={permission.id} className='permission-item'>
                                                                <label htmlFor={`permission-${permission.id}`} className='permission-label'>{permission.label}</label>
                                                                <ToggleButton
                                                                    isActive={user?.roles?.some((perm) => perm.id === permission.id) || false}
                                                                />
                                                                <p className='permission-description'>{permission.description || 'N/A'}</p>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <InfoBox
                                            type={'warning'}
                                            text={'Aucune autorisation trouvée.'}
                                            disabledCloseBtn={true}
                                        />
                                    )}
                                </div>
                            </React.Fragment>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </CustomModal>
    );
};

// Prop types for the ViewUserModal component
ViewUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ViewUserModal;