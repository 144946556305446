import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import FoldersService from '../../services/FoldersService';

import InfoBox from '../blocks/messages/info-box';

/**
 * PartsInfo Component
 * 
 * This component displays a table of parts information based on the provided folderId.
 * It allows selecting a quantity for each part from 0 to the available amount if selectable is true.
 * 
 * Props:
 * - selectable (boolean): Controls whether the quantity selector is enabled or not.
 */
const PartsInfo = ({ selectable = false }) => {
    const { refFolder } = useParams(); // Get the folder number from the URL

    // State to hold parts data
    const [parts, setParts] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [folder, setFolder] = useState(null);

    // State to handle loading and error messages
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchParts = async () => {
        try {
            setLoading(true); // Start loading

            // Fetch the folder based on the folder number
            const folder = await FoldersService.getFolderByNumber(refFolder);

            // If the folder is not found, throw an error
            if (!folder) {
                console.error("Folder not found");
                setError("Dossier introuvable");
                return;
            }

            // Set the folder state
            setFolder(folder);

            // Fetch the parts data using folderId
            const response = await FoldersService.getPartsByFolderId(folder.id);

            // If no response is found, return
            if (!response) {
                setParts([]);
                return;
            }

            // Format the data to only include the part and quantity_used
            const dataFormatted = response.map(data => {
                return {
                    id: data.part?.id,
                    reference: data.part?.reference || "N/A",
                    name: data.part?.name || "N/A",
                    quantity_used: data.quantity_used || 0,
                    stock: data.stock || 0,
                };
            });

            // Set the parts and quantities state
            setParts(dataFormatted || []);

            if (!dataFormatted || dataFormatted.length === 0) return;

            // Set the quantities state
            setQuantities(dataFormatted.map(part => part.quantity_used || 0)); // Use quantity_used or default to 0
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue"); // Set the error message
        } finally {
            setLoading(false); // End loading
        }
    };

    // Effect to fetch parts
    useEffect(() => {
        fetchParts();
    }, []);

    /**
     * Handle quantity change for a specific part.
     * 
     * @param {number} index - The index of the part in the parts array.
     * @param {number} value - The new quantity value selected.
     */
    const handleQuantityChange = async (index, value) => {
        const updatedQuantities = [...quantities];
        updatedQuantities[index] = Number(value); // Update the quantity for the specific part
        handleSave(updatedQuantities); // Call the save function with the updated quantities
    };

    /**
     * Handle saving the selected quantities.
     */
    const handleSave = async (updatedQuantities) => {
        try {
            // Check if the folder and parts are available
            if (!parts || parts.length === 0) return;

            // Iterate over each part and save changes if the quantity has been modified
            const updatePromises = parts.map(async (part, index) => {

                // Check if the quantity has been modified
                const selectedQuantity = updatedQuantities[index];

                // If the selected quantity is different from the current quantity, update the part
                if (selectedQuantity !== part.quantity_used) {

                    // Call the service to update the part data in the folder
                    const payload = { 
                        quantity_used: selectedQuantity, // Update quantity
                    };

                    // Update the part in the folder
                    await FoldersService.editPartFolderById(folder.id, part.id, payload);
                }
            });

            // Wait for all updates to be completed
            await Promise.all(updatePromises);

            // Fetch the parts again to update the data
            await fetchParts();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue"); // Set the error message
        }
    };

    // Early return for loading state
    if (loading) {
        return (
            <div className="folder-section parts-info">
                <h2>Pièces de rechange</h2>
                Chargement...
            </div>
        );
    }

    // Display error message if an error occurred
    if (error) {
        return (
            <div className="folder-section parts-info">
                <h2>Pièces de rechange</h2>
                <InfoBox
                    type={'error'}
                    disabledCloseBtn={true}
                    text={error}
                />
            </div>
        );
    }

    // If there are no parts, display a message
    if (!parts || parts.length === 0) {
        return (
            <div className="folder-section parts-info">
                <h2>Pièces de rechange</h2>
                Aucune pièce de rechange trouvée.
            </div>
        );
    }

    // Render the PartsInfo component
    return (
        <div className="folder-section parts-info">
            <h2>Pièces de rechange</h2>
                <table className="parts-table">

                    {/* Table header */}
                    <thead>
                        <tr>
                            <th>Référence</th>
                            <th>Désignation</th>
                            <th>Pièces Utilisées</th>
                            <th>Nombre de pièces</th>
                        </tr>
                    </thead>

                    {/* Table body */}
                    <tbody>
                        {parts?.map((part, index) => (
                            <tr key={index} className="part-details">
                                <td data-label="Référence">{part.reference || "N/A"}</td>
                                <td data-label="Désignation">{part.name || "N/A"}</td>
                                <td data-label="Pièces Utilisées">
                                    {selectable ? (
                                        <select
                                            value={quantities[index]}
                                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                                        >
                                            {Array.from({ length: part.stock + 1 }, (_, i) => (
                                                <option key={i} value={i}>{i}</option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{part.quantity_used > 0 ? "Oui" : "Non"}</span> // Displays the selected quantity
                                    )}
                                </td>
                                <td data-label="Nombre de pièces">{part.stock || "N/A"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
        </div>
    );
};

// Define PropTypes for the PartsInfo component
PartsInfo.propTypes = {
    selectable: PropTypes.bool, // Option to enable or disable the quantity selector
};

export default PartsInfo;