import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Lock, LockOpen, ExpandLess, ExpandMore, PhonelinkLock } from '@mui/icons-material'; // Import Material UI icon

import CustomModal from '../../../blocks/custom-modal';
import InfoBox from '../../../blocks/messages/info-box';
import ToggleButton from '../../../buttons/toggle-button';
import UserStatusConfirmationModal from './modal--admin--update-user-status';
import DeviceUpdateModal from './modal--admin--update-device-identifier';

import PermissionsService from '../../../../services/PermissionsService';
import UsersService from '../../../../services/UsersService';

/**
 * EditUserModal Component
 *
 * This component displays a modal for editing the details of a user.
 * It takes `open`, `onClose`, `userId`, and `onSave` as props.
 *
 * Props:
 * - open (boolean): Controls the modal visibility.
 * - onClose: Function to close the modal.
 * - userId (number or string): The ID of the user to edit.
 * - onSave (function): Callback function to handle saving user data.
 *
 * @param {object} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const EditUserModal = ({ open, onClose, userId, onSave }) => {

    // State to handle loading and error messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    // State to hold permissions and form data
    const [permissions, setPermissions] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [formData, setFormData] = useState({});

    // State to hold selected template
    const [selectedTemplate, setSelectedTemplate] = useState('');

    // State to handle confirmation modal visibility
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);

    // State to handle collapsed groups
    const [collapsedGroups, setCollapsedGroups] = useState({});

    /**
     * Fetch user data by ID
     * 
     * @throws {Error} Throws an error if fetching fails
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     */
    const fetchUser = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch user data by ID
            const fetchedUser = await UsersService.getUserById(userId);

            // Set user data and form data 
            setUser(fetchedUser);
            setFormData({
                last_name: fetchedUser?.last_name,
                first_name: fetchedUser?.first_name,
                email: fetchedUser?.email,
                user_code: fetchedUser?.user_code,
                ids_roles: fetchedUser?.roles && fetchedUser.roles.map((role) => role.id),
            });
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);  // Set loading to false when data is fetched or error occurs
        }
    };

    /**
     * Fetch templates user
     * 
     * @throws {Error} Throws an error if fetching fails
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     */
    const fetchTemplatesUser = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch user data by ID
            const fetchedTemplates = await PermissionsService.getTemplatesUser();

            // Set user data and form data 
            setTemplates(fetchedTemplates);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);  // Set loading to false when data is fetched or error occurs
        }
    };

    /**
     * Fetch user permissions
     * 
     * @throws {Error} Throws an error if fetching fails
     * @returns {Promise<void>} A promise that resolves when the data is fetched
     */
    const fetchPermissions = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Fetch the user permissions
            const fetchedPermissions = await PermissionsService.getPermissions();

            // Set the permissions data
            setPermissions(fetchedPermissions);
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);  // Set loading to false when data is fetched or error occurs
        }
    };

    /**
     * Handle input change
     * 
     * @param {string} field - The field to update
     * @param {string} value - The new value
     * @returns {void}
     */
    const handleInputChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    /**
     * Handle permission toggle
     * 
     * @param {number} permissionId - The ID of the permission to toggle
     * @returns {void}
     */
    const handlePermissionToggle = (permissionId) => {
        setFormData((prev) => {
            const updatedPermissions = prev.ids_roles.includes(permissionId)
                ? prev.ids_roles.filter((id) => id !== permissionId)
                : [...prev.ids_roles, permissionId];
    
            return { ...prev, ids_roles: updatedPermissions };
        });
    };

    /**
     * Handle toggling the visibility of a permissions group
     * 
     * @param {string} groupName - The name of the group to toggle
     */
    const toggleGroupVisibility = (groupName) => {
        setCollapsedGroups((prev) => ({
            ...prev,
            [groupName]: !prev[groupName], // Toggle the collapsed state
        }));
    };

    /**
     * Match template
     * 
     * @param {Array} userRoles - The roles of the user
     * @returns {Object} The matched template or null
     */
    const matchTemplate = (userRoles) => {
        // Find the template that matches the user roles
        return templates.find((template) =>
            template.roles.every((role) => userRoles.includes(role.id)) &&
            userRoles.every((id) => template.roles.some((role) => role.id === id))
        ) || null;
    };    

    /**
     * Handle template selection
     * 
     * @param {string} templateId - The ID of the selected template
     * @returns {void}
     */
    const handleTemplateSelection = (templateId) => {
        const selectedTemplate = templates.find((template) => template.id === parseInt(templateId, 10));

        if (selectedTemplate) {
            setFormData((prev) => ({
                ...prev,
                ids_roles: selectedTemplate.roles.map((role) => role.id), // Update permissions with template roles
            }));
        }
    };

    /**
     * Handle save
     * 
     * @throws {Error} Throws an error if saving fails
     * @returns {Promise<void>} A promise that resolves when the data is saved
     */
    const handleSave = async () => {
        try {
            // Set loading state to true
            setLoading(true);

            // Check if form data is set
            if (!formData) {
                console.error('Form data is required');
                setError('Veuillez remplir les champs requis.');
                return;
            }

            // Save the user data
            await UsersService.editUser(userId, formData);
            onSave();
            onClose();
        } catch (error) {
            setError(error?.message || "Une erreur inattendue est survenue");
        } finally {
            setLoading(false);
        }
    };

    // Fetch user data and permissions on mount
    useEffect(() => {
        // Fetch user data and permissions if the user ID is set
        if (userId) {
            fetchUser();
            fetchTemplatesUser();
            fetchPermissions();
        }
    }, []);

    // Set initial collapsed groups when permissions are fetched
    useEffect(() => {
        if (permissions && permissions.length > 0) {
            // Set initial collapsed groups based on the permissions
            const initialGroups = permissions.reduce((groups, permission) => {
                const group = permission.group_name || 'Autres';
                groups[group] = false;
                return groups;
            }, {});
            setCollapsedGroups(initialGroups);
        }
    }, [permissions]);

    // Set selected template when user roles or templates are fetched
    useEffect(() => {
        // Match the template based on the user roles
        if (formData.ids_roles && templates.length > 0) {
            const matchedTemplate = matchTemplate(formData.ids_roles);
            setSelectedTemplate(matchedTemplate ? matchedTemplate.id.toString() : ''); // Mettre à jour le template sélectionné
        }
    }, [formData.ids_roles, templates]);    

    // If the modal is not open or the user ID is not set, return null
    if (!open || !userId) return null;

    // Render the EditUserModal component
    return (
        <CustomModal
            open={open}
            onClose={onClose}
            title={'Éditer l\'utilisateur'}
            onCloseLabel={'Annuler'}
            handleSaveLabel={'Enregistrer'}
            handleSave={handleSave}
            hideSaveButton={ (loading || !user || error ) ? true : false }
        >
            <div className='edit-user-modal'>
                {loading ? (
                    <div>Chargement...</div>
                ) : error ? (
                    <InfoBox
                        type="error"
                        text={error}
                        disabledCloseBtn={true}
                    />
                ) : (
                    <React.Fragment>
                        <div className="user-info">
                            {user ? (
                                <React.Fragment>
                                    {/* Actions */}
                                    <div className='user-info--actions'>
                                        <button
                                            onClick={() => setIsDeviceModalOpen(true)}
                                            className="btn-update-device"
                                        >
                                            <PhonelinkLock />
                                            Mettre à jour l'identifiant d'appareil
                                        </button>

                                        <button
                                            onClick={() => setIsConfirmationModalOpen(true)} // Set the confirmation modal to open
                                            className={`btn-toggle-status ${user.is_active ? 'active' : 'inactive'}`}	
                                        >
                                            {user.is_active ? <Lock /> : <LockOpen />}
                                            {user.is_active ? 'Désactiver l\'utilisateur' : 'Activer l\'utilisateur'}
                                        </button>
                                    </div>

                                    {/* Custom block for displaying user info */}
                                    <div className="user-info--overview">
                                        <div className="user-info--overview-item">
                                            <strong>Identifiant appareil:</strong>
                                            <span>{user.device_identifier || 'N/A'}</span>
                                        </div>
                                        <div className="user-info--overview-item">
                                            <strong>Créé le:</strong>
                                            <span>{user.created_at ? new Date(user.created_at).toLocaleDateString('fr-FR') : 'N/A'}</span>
                                        </div>
                                        <div className="user-info--overview-item">
                                            <strong>Modifié le:</strong>
                                            <span>{user.updated_at ? new Date(user.updated_at).toLocaleDateString('fr-FR') : 'N/A'}</span>
                                        </div>
                                        <div className="user-info--overview-item">
                                            <strong>Statut:</strong>
                                            <span>{!user.is_active ? 'Désactivé' : 'Activé'}</span>
                                        </div>
                                    </div>

                                    {/* User Details */}
                                    <div className='user-info--details'>
                                        <h3>Éditer les informations de l'utilisateur</h3>
                                        <div className="user-info--input">
                                            <label>Nom :</label>
                                            <input
                                                type="text"
                                                value={formData.last_name || ''}
                                                onChange={(e) => handleInputChange('last_name', e.target.value)}
                                            />
                                        </div>
                                        <div className="user-info--input">
                                            <label>Prénom :</label>
                                            <input
                                                type="text"
                                                value={formData.first_name || ''}
                                                onChange={(e) => handleInputChange('first_name', e.target.value)}
                                            />
                                        </div>
                                        <div className="user-info--input">
                                            <label>Email :</label>
                                            <input
                                                type="email"
                                                value={formData.email || ''}
                                                onChange={(e) => handleInputChange('email', e.target.value)}
                                            />
                                        </div>
                                        <div className="user-info--input">
                                            <label>Code utilisateur :</label>
                                            <input
                                                type="text"
                                                value={formData.user_code || ''}
                                                onChange={(e) => handleInputChange('user_code', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* Roles */}
                                    <div className='user-info--permissions'>
                                        <h3>Éditer les autorisations</h3>
                                        <div className='user-role--template'>
                                            <div className='user-role--template-select'>
                                                <label>Utiliser un modèle par défaut :</label>
                                                <select
                                                    value={selectedTemplate}
                                                    onChange={(e) => {
                                                        const templateId = e.target.value;
                                                        setSelectedTemplate(templateId);
                                                        handleTemplateSelection(templateId);
                                                    }}
                                                >
                                                    <option value="" disabled>Personnalisé</option>
                                                    {templates.map((template) => (
                                                        <option key={template.id} value={template.id}>
                                                            {template.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {permissions && permissions.length > 0 ? (
                                            Object.entries(
                                                permissions.reduce((groups, permission) => {
                                                    const group = permission.group_name || 'Autres';
                                                    if (!groups[group]) {
                                                        groups[group] = [];
                                                    }
                                                    groups[group].push(permission);
                                                    return groups;
                                                }, {})
                                            ).map(([groupName, groupPermissions]) => (
                                                <div key={groupName} className='permissions-group'>
                                                    <h4
                                                        className='permissions-group-title'
                                                        onClick={() => toggleGroupVisibility(groupName)}
                                                    >
                                                        {groupName}
                                                        {collapsedGroups[groupName] ? <ExpandMore /> : <ExpandLess />}
                                                    </h4>

                                                    { !collapsedGroups[groupName] && (
                                                        <ul className='permissions-list'>
                                                            {groupPermissions.map((permission) => (
                                                                <li key={permission.id} className='permission-item'>
                                                                    <label htmlFor={`permission-${permission.id}`} className='permission-label'>{permission.label}</label>
                                                                    <ToggleButton
                                                                        isActive={formData.ids_roles?.includes(permission.id) || false}
                                                                        onToggle={() => handlePermissionToggle(permission.id)} // On passe seulement l'ID de la permission
                                                                    />
                                                                    <p className='permission-description'>{permission.description || 'N/A'}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <InfoBox
                                                type={'warning'}
                                                text={'Aucune autorisation trouvée.'}
                                                disabledCloseBtn={true}
                                            />
                                        )}
                                    </div>

                                    {/* Device Update Modal */}
                                    {isDeviceModalOpen && (
                                        <DeviceUpdateModal
                                            open={isDeviceModalOpen}
                                            onClose={() => setIsDeviceModalOpen(false)}
                                            onSave={() => fetchUser()}
                                            user={user}
                                        />
                                    )}

                                    {/* Confirmation Modal for status change */}
                                    {isConfirmationModalOpen && (
                                        <UserStatusConfirmationModal
                                            open={isConfirmationModalOpen}
                                            onClose={() => setIsConfirmationModalOpen(false)}
                                            onConfirm={() => fetchUser()}
                                            user={user}
                                        />
                                    )}
                                </React.Fragment>
                            ) : (
                                <InfoBox
                                    type={'error'}
                                    text={'Aucune information trouvée pour cet utilisateur.'}
                                    disabledCloseBtn={true}
                                />
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </CustomModal>
    );
};

// Prop types for the EditUserModal component
EditUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onSave: PropTypes.func.isRequired,
};

export default EditUserModal;
