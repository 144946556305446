import React, { createContext, useContext, useState, useEffect } from 'react';
import { Preferences } from '@capacitor/preferences';

import UsersService from '../services/UsersService';

// AuthContext: Manages user authentication state
const AuthContext = createContext();

/**
 * AuthProvider component: Provides the authentication state to its children.
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * 
 * This provider manages the authentication state of the user.
 * It checks if the user is authenticated based on the presence of an auth token.
 */
export const AuthProvider = ({ children }) => {
  // State variable for authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * checkAuth: Checks the user's authentication status.
   */
  const checkAuth = async () => {
    try {
      // Set loading state to true
      setIsLoading(true); 

      // Get the session token from preferences
      const { value: token } = await Preferences.get({ key: 'sessionToken' });

      // Check if the token exists
      if (token) {
        // Set the authentication status to true
        setIsAuthenticated(true);

        // Fetch the current user data
        const currentUser = await UsersService.getUserConnected();

        // Set the current user in the state
        setCurrentUser(currentUser);
      } else {
        // Set the authentication status to false
        setIsAuthenticated(false);

        // Reset the current user
        setCurrentUser(null);
      }
    } catch (err) {
      console.error(err);
      setCurrentUser(null); // Reset currentUser on error
      setIsAuthenticated(false); // Reset authentication status on error
    } finally {
      // Set loading state to false
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []); // Run once when the component mounts

  // Provide the authentication state to children components
  return (
    <AuthContext.Provider value={{ isAuthenticated, currentUser, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);