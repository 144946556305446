// src/views/not-found-page.jsx
import React from 'react';
import Logo from '../assets/images/logo.jpg';
import { Link } from 'react-router-dom';

/**
 * NotFoundPage Component
 * This component renders the 404 page when a user tries to access a non-existent page.
 */
const NotFoundPage = () => {

  // Render the 404 page
  return (
    <div className="not-found-page">
      <img src={Logo} alt="Logo" className="logo" />
      <h1>404 - Page non trouvée</h1>
      <p>La page que vous cherchez n'existe pas.</p>
      <Link to="/" className="go-home-btn">Retour à l'accueil</Link>
    </div>
  );
};

export default NotFoundPage; // Export the NotFoundPage component