// deviceModule/hooks/useDevice.jsx

import { useEffect, useState } from 'react'; // Import useEffect and useState hooks from React
import packageJson from '../../../../package.json'; // Import app version from package.json

/**
 * Custom hook for managing app information such as version.
 */
export const useApp = () => {
    // State variables to hold the fetched device data
    const [version, setVersion] = useState(null);// Holds the app version

    /**
     * useEffect hook to fetch device information when the component mounts.
     * It asynchronously calls the utility functions and stores the results in state variables.
     */
    useEffect(() => {
        const fetchAppInfo = async () => {
            try {
                const version = await getVersion(); // Fetch app version

                // Update state with the fetched data
                setVersion(version);
            } catch (err) {
                console.error(err.message); // Log any errors that occur during the fetch
            }
        };

        fetchAppInfo(); // Call the fetch function
    }, []); // Empty dependency array ensures the effect runs only once when the hook is used

    const getVersion = async () => {
        // Get app version from package.json
        return packageJson.version;
    }

    // Return the fetched values and loading state for use in components
    return { version };
};
