import React from 'react';

import PropTypes from 'prop-types';

import ClientSignature from './folder--signatures--client-signature';
import TechnicianSignature from './folder--signatures--technician-signature';

/**
 * SignatureContent component to render signature fields based on active tab.
 * @param {number} activeTab - Index of the currently active tab
 * @param {number} technicianCount - Total number of technicians requiring signatures
 * @param {Array} technicianSignatures - Array of technician signatures
 * @param {function} setTechnicianSignatures - Function to set technician signatures
 * @param {string} clientSignature - Client's signature data
 * @param {function} setClientSignature - Function to set client signature
 * @param {boolean} hasClient - Flag to check if client signature is required
 * @returns {JSX.Element} - Rendered signature content components
 */
const SignatureContent = ({
    activeTab,
    technicianCount,
    technicianSignatures,
    setTechnicianSignatures,
    clientSignature,
    setClientSignature,
    hasClient
}) => {

    // Render the signature content
    return (
        <div className="signature-content">
            {/* Render each TechnicianSignature component based on technician count and active tab */}
            {Array.from({ length: technicianCount }).map((_, index) => (
                activeTab === index && (
                    <TechnicianSignature
                        key={index}
                        index={index}
                        signature={technicianSignatures[index]}
                        setSignature={(signature) => {
                            const newSignatures = [...technicianSignatures];
                            newSignatures[index] = signature;
                            setTechnicianSignatures(newSignatures);
                        }}
                    />
                )
            ))}

            {/* Render ClientSignature component if client signature is required and tab is active */}
            {hasClient && activeTab === technicianCount && (
                <ClientSignature
                    signature={clientSignature}
                    setSignature={(signature) => setClientSignature(signature)}
                />
            )}
        </div>
    );
};

// Prop types for SignatureContent component
SignatureContent.propTypes = {
    activeTab: PropTypes.number.isRequired,
    technicianCount: PropTypes.number.isRequired,
    technicianSignatures: PropTypes.array,
    setTechnicianSignatures: PropTypes.func.isRequired,
    clientSignature: PropTypes.string,
    setClientSignature: PropTypes.func.isRequired,
    hasClient: PropTypes.bool.isRequired
};

export default SignatureContent;